import React from 'react'

const Header = ({title,left,right}) => {
  return (
    <div className='flex flex-col pt-4 pb-4 bg-primary-main mb-14  w-full'>
        <div className="flex flex-row justify-between items-center max-w-screen-md w-full mx-auto">
            {left}
            <span style={{fontStyle:'bold',color:'white',fontSize:30,display:'flex',justifyContent:'center'}}>{title}</span>
            {right}
        </div>
    </div>
     
  )
}

export default Header