import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Paper, Typography,Divider,IconButton } from '@material-ui/core'
import clsx from 'clsx'
import Card from '@material-ui/core/Card';
import AppsIcon from '@material-ui/icons/Apps';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CardContent from '@material-ui/core/CardContent';
import ExtensionIcon from '@material-ui/icons/Extension';
import AccessibleForwardIcon from '@material-ui/icons/AccessibleForward';
import EvolutionProduction from 'pages/stats/EvolutionProduction'
import ProductionParproduit from 'pages/stats/ProductionParproduit'
import {connect} from 'react-redux'
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import ListIcon from '@material-ui/icons/List';
import { getTotProductionAgent } from 'redux/actions/apiActions'
import { useHistory } from 'react-router-dom';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import OperationStat from 'pages/Operations/components/OperationStat';
import PretStat from 'pages/Banking/Prets/components/PretStat';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import AddBoxIcon from '@material-ui/icons/AddBox';
import QueueIcon from '@material-ui/icons/Queue';
const useStyle = makeStyles(theme=>({
    root:{
      width:'85%',
      marginRight:'auto',
      marginLeft:'auto',
      [theme.breakpoints.down('sm')]: {
        width:'100%',
      },
      [theme.breakpoints.up('md')]: {
        width:'75%',
      },
      [theme.breakpoints.up('lg')]: {
        width:'75%',
      },
      marginBottom:20,
    },
    hpaper:{
      padding:20,
      borderRadius:10,
      backgroundColor:theme.palette.primary.main,
      
    },
    marge:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    texte:{
      color:'#fff'
    },
    card:{
      display:'flex',
      flex:1,
      flexDirection:'column',
      justifyContent:'space-around',
      padding:20,
      margin:20,
      alignItems:'center'
    },
    activityCard:{
      padding:20,
      margin:20,
    },
    activityContent:{
      display:'flex',
      justifyContent:'sapce-between'
    },
    activityItem1:{
      flex:2,
      margin:10
    },
    activityItem2:{
      flex:1,
      margin:10
    },
    mainitem:{
        display:'flex',
        flexDirection:'column',
        boxShadow: "-4px 6px 15px 5px rgba(171,171,171,0.3);",
        paddingBottom:20
    },
    iheader:{
        display:'flex',
        marginBottom:10,
        alignItems:'center',
        padding:10
    },
    icorps:{
        marginTop:10,
        padding:20,
        display:'flex'
    },
    icard:{
        display:'flex',
        flex:1,
        "&:hover":{
            boxShadow: "-6px 9px 20px 7px rgba(171,171,171,0.3);"
        },
        cursor:'pointer'
    },
    iStat:{
        display:'flex',
        marginTop:20,
        marginBottom:20
    },
    igraph:{
        display:'flex',
        flex:1,
        justifyContent:'space-around'
    },
    cardLogo:{
        position:'absolute',
        top:-50,
        width:100,
        height:100
    },
    img:{
        objectFit: 'fill',
        width:100
    },
    cardState:{
        display:'flex',
        width:'100%',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center'
    },
    cardpar:{
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        boxShadow: "-4px 6px 15px 5px rgba(171,171,171,0.3)",
        marginBottom:20,
        position:'relative',
        padding:20,
        marginTop:70
    },
    itcard:{
        display:'flex',
        flex:1,
        boxShadow: "-4px 6px 15px 5px rgba(171,171,171,0.3);",
        "&:hover":{
            boxShadow: "-6px 9px 20px 7px rgba(171,171,171,0.3)"
        },
        cursor:'pointer'
    },
    numberState:{
        fontSize:35,
        color:theme.palette.primary.main,
        textAlign:'center'
    },
    labelState:{
        color:'#666',
        fontWeight:'bold',
        textAlign:'center'
    },
    cardadd:{
        marginLeft:20,
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        backgroundColor:theme.palette.primary.main,
        borderRadius:0,
        boxShadow: "-6px 9px 20px 7px rgba(171,171,171,0.3);",
        cursor:'pointer',
        "&:hover":{
            backgroundColor:theme.palette.secondary.main,
            boxShadow: "-6px 9px 20px 7px rgba(171,171,171,0.3);"
        }
    },
    cardrapide:{
        display:'flex',
        flex:1,
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        borderRadius:0,
        padding:5,
        cursor:'pointer',
        "&:hover":{
            backgroundColor:theme.palette.primary.main,
            color:'white'
        }
    },
    contentar:{
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center'
    },
   
  }))

function Welcome(props) {
    const classes = useStyle()
    const {user:{credentials},ui: { layoutRoot }}= props
    const {codepartenaire}=credentials
    const [propState,setPropState] = useState(null)
    const history = useHistory()

    const handlePropoSitionState =()=>{
        getTotProductionAgent(credentials.idmembre).then(res=>{
            if(res) setPropState(res)
        })
    }

    useEffect(()=>{
        handlePropoSitionState()
    },[])
    
    const handleApplication=(ap)=>{
        if(ap){
            switch (ap) {
                case 'souscription':
                    history.push(`/${layoutRoot}/esouscription`)
                    break;
                case 'prestation':
                    history.push(`/${layoutRoot}/eprestation`)
                    break;
                case 'pret':
                    history.push(`/${layoutRoot}/epret`)
                    break;
                case 'sinistre':
                    history.push(`/${layoutRoot}/esinistre`)
                    break;
                default:
                    return
            }
        }
    }
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleAddElement =(el)=>{
        switch (el) {
            case "add-souscription":
                history.push(`/${layoutRoot}/esouscription/simulateurs`)
                break;
            case "search-souscription":
                history.push(`/${layoutRoot}/esouscription/recherche-contrat`)
                break;
            case "liste-souscription":
                history.push(`/${layoutRoot}/esouscription/production`)
                break; 
            case "add-prestation":
                history.push(
                    {
                        pathname: `/${layoutRoot}/eprestation`,
                        state: { mode: 'PRES' }
                    }
                    )
                break; 
            case "add-modification":
                history.push(
                    {
                        pathname: `/${layoutRoot}/eprestation`,
                        state: { mode: 'MOD' }
                    }
                    )
                break;
            case "add-sinistre":
                history.push({
                    pathname: `/${layoutRoot}/esinistre`,
                    state: { mode: 'SIN' }
                })
                break; 
            case "add-pret":
                history.push({
                    pathname: `/${layoutRoot}/epret`,
                    state: { mode: 'SIMUL' }
                })
                break; 
            default:
                break;
        }
        handleClose()
    }
  return (
    <div className={classes.root}>
        <div className={classes.marge} />
        <div>
            <Paper className={classes.hpaper} variant="outlined">
                <div>
                    <Typography className={classes.texte} variant='h4'> Bienvenue sur la plate-forme de gestion  Ynov</Typography>
                    <Typography className={clsx([classes.texte])} variant='caption'> Contrat,adherent,opération,facturation, ....</Typography>
                </div>
            </Paper> 
        </div>
        <div style={{display:'flex',marginTop:30}}>
            <div style={{marginRight:20,width:'75%'}}>
                {/*<Paper className={classes.mainitem}>
                    <div className={classes.iheader}>
                        <AppsIcon color='primary' fontSize='medium' /> <Typography variant='h6'>Applications</Typography>
                    </div>
                    <Divider />
                    <div className={classes.icorps}>
                        <Card className={classes.icard} style={{marginRight:10}} onClick={()=>handleApplication('souscription')}>
                            <CardContent>
                                <AssignmentIcon style={{ color:'#f9b233', fontSize:60 }} />
                                <Typography variant="h5" component="h3">
                                    e-Souscriptions
                                </Typography>
                                <Typography className={classes.pos} color="textSecondary">
                                    Saisie de contrat, transmission, ...
                                </Typography>
                            </CardContent>
                        </Card>
                        <Card className={classes.icard} style={{marginRight:10,marginLeft:10}}  onClick={()=>handleApplication('prestation')}>
                            <CardContent>
                                <ExtensionIcon style={{ color:'#f9b233', fontSize:60 }} />
                                <Typography variant="h5" component="h3">
                                    e-Prestations
                                </Typography>
                                <Typography className={classes.pos} color="textSecondary">
                                    Demande de prestations, modification, suivi,...
                                </Typography>
                            </CardContent>
                        </Card>
                        <Card className={classes.icard} style={{marginRight:10,marginLeft:10}}  onClick={()=>handleApplication('sinistre')}>
                            <CardContent>
                                <AccessibleForwardIcon style={{ color:'#f9b233', fontSize:60 }} />
                                <Typography variant="h5" component="h3">
                                    e-Sinistres
                                </Typography>
                                <Typography className={classes.pos} color="textSecondary">
                                    Déclararer un sinistre, suivi,...
                                </Typography>
                            </CardContent>
                        </Card>
                        <Card className={classes.icard} style={{marginRight:10,marginLeft:10}}  onClick={()=>handleApplication('pret')}>
                            <CardContent>
                                <AccountBalanceIcon style={{ color:'#f9b233', fontSize:60 }} />
                                <Typography variant="h5" component="h3">
                                    e-Prêts
                                </Typography>
                                <Typography className={classes.pos} color="textSecondary">
                                    Cotation de prêts, Mise en place,...
                                </Typography>
                            </CardContent>
                        </Card>
                        
                    </div>
                </Paper> */}
                <div style={{marginTop:20}}>
                    <Typography variant='h6'>Souscriptions</Typography>
                    <Divider />
                </div>
                <div className={classes.iStat} >
                        
                        <Card className={classes.itcard} style={{marginRight:15}}>
                            <CardContent>
                                <div className={classes.cardState}>
                                    <span className={classes.numberState}>{propState&&propState['general']['all']['total']}</span>
                                    <span className={classes.labelState}>
                                        Total propositions
                                    </span>
                                </div>
                            </CardContent>
                        </Card>
                        <Card className={classes.itcard} style={{marginRight:15}}>
                            <CardContent>
                                <div className={classes.cardState}>
                                    <span className={classes.numberState}>{propState&&propState['general']['saisies']['nbSaisie']}</span>
                                    <span className={classes.labelState}>
                                        En attente de transmission
                                    </span>
                                </div>
                            </CardContent>
                        </Card>
                        <Card className={classes.itcard}style={{marginRight:15}}>
                            <CardContent>
                                <div className={classes.cardState}>
                                    <span className={classes.numberState}>{propState&&propState['general']['transmis']['nbTransmis']}</span>
                                    <span className={classes.labelState}>
                                        En attente de validation
                                    </span>
                                </div>
                            </CardContent>
                        </Card>
                        <Card className={classes.itcard} style={{marginRight:15}}>
                            <CardContent>
                                <div className={classes.cardState}>
                                    <span className={classes.numberState}>{propState&&propState['general']['acceptes']['nbAccepter']}</span>
                                    <span className={classes.labelState}>
                                        Propositions acceptées
                                    </span>
                                </div>
                            </CardContent>
                        </Card>
                        <Card className={classes.itcard} style={{marginRight:15}}>
                            <CardContent>
                                <div className={classes.cardState}>
                                    <span className={classes.numberState}>{propState&&propState['general']['rejetes']['nbrejeter']}</span>
                                    <span className={classes.labelState}>
                                        Propositions ajournées
                                    </span>
                                </div>
                            </CardContent>
                        </Card>
                        <Card className={classes.itcard} >
                            <CardContent>
                                <div className={classes.cardState}>
                                    <span className={classes.numberState}>{propState&&propState['general']['annules']['nbreannuler']}</span>
                                    <span className={classes.labelState}>
                                        Propositions annulées
                                    </span>
                                </div>
                            </CardContent>
                        </Card>
                        {/*<Card className={classes.cardadd} onClick={()=>handleAddElement('add-souscription')}><AddIcon style={{color:'white',fontSize:40}} /></Card>*/}
                    </div>
                <div className={classes.igraph}>
                    <EvolutionProduction title="Evolution de la production" />
                    <ProductionParproduit title="Production par produit" />  
                </div>{/*
                <div style={{marginTop:20}}>
                    <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                        <Typography variant='h6'>Prestations</Typography>
                        <IconButton aria-controls="oepration-menu" aria-haspopup="true" onClick={handleClick}>
                            <KeyboardArrowDownIcon style={{color:'gray',fontSize:40}} />
  </IconButton>
                    </div>
                    
                    <Divider />
                    
                </div> 
                <div className={classes.iStat} >
                    <OperationStat />
                </div>
                
                <div style={{marginTop:20}}>
                    <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                        <Typography variant='h6'>Demandes de cotation</Typography>
                        {/*<IconButton onClick={()=>handleAddElement('add-pret')}>
                            <AddIcon style={{color:'gray',fontSize:40}} />
  </IconButton>
                    </div>
                    <Divider />
                </div>
                <div className={classes.iStat} >
                    <PretStat />
                </div>
               */}
                
            </div>
            <div style={{marginLeft:20,width:'25%'}}> 
                <Paper className={classes.cardpar}>
                    <div className={classes.cardLogo}>
                        <img className={classes.img} src={require(`assets/images/${(codepartenaire &&codepartenaire!=='')?codepartenaire+'.jpg':"LLV.jpg"}`).default} />
                    </div>
                    <div style={{marginTop:20,textAlign:'center'}}><Typography variant='h6'>{credentials['partenaire']||""}</Typography></div>
                </Paper>
                <div style={{maginTop:20}}>
                    <div>
                        <Typography variant='h6'>Accès rapide</Typography>
                        <Divider />
                    </div>
                    <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',flexWrap:'wrap',marginTop:10}}>
                        <Card variant='outlined' className={classes.cardrapide} onClick={()=>handleAddElement('add-souscription')}>
                            <CardContent>
                                <div className={classes.contentar}>
                                        <AddIcon />
                                    <small>Nouvelle souscription</small>
                                </div>
                            </CardContent>
                        </Card>
                        <Card variant='outlined' className={classes.cardrapide} onClick={()=>handleAddElement('liste-souscription')}>
                            <CardContent>
                                <div className={classes.contentar}>
                                    <ListIcon />
                                    <small>Liste des propositions</small>
                                </div>
                            </CardContent>
                        </Card>
                        <Card variant='outlined' className={classes.cardrapide} onClick={()=>handleAddElement('search-souscription')}>
                            <CardContent>
                                <div className={classes.contentar}>
                                    <SearchIcon />
                                    <small>Rechercher un contrat</small>
                                </div>
                            </CardContent>
                        </Card>
                    </div>
                    {/* 
                    <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',flexWrap:'wrap',marginTop:10}}>
                        <Card variant='outlined' className={classes.cardrapide} onClick={()=>handleAddElement('add-prestation')}>
                            <CardContent>
                                <div className={classes.contentar}>
                                    <CreateNewFolderIcon />
                                    <small>Nouvelle prestation</small>
                                </div>
                            </CardContent>
                        </Card>
                        <Card variant='outlined' className={classes.cardrapide} onClick={()=>handleAddElement('add-sinistre')}>
                            <CardContent>
                                <div className={classes.contentar}>
                                    <AddBoxIcon />
                                    <small>Déclarer un sinistre</small>
                                </div>
                            </CardContent>
                        </Card>
                      
                        <Card variant='outlined' className={classes.cardrapide} onClick={()=>handleAddElement('add-pret')}>
                            <CardContent>
                                <div className={classes.contentar}>
                                    <QueueIcon />
                                    <small>Nouvelle coatation</small>
                                </div>
                            </CardContent>
                        </Card>
                    </div>*/}
                </div>
            </div>
        </div>
        <Menu
            id="operation-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            <MenuItem onClick={()=>handleAddElement('add-sinistre')}>Declarer un sinsitre</MenuItem>
            <MenuItem onClick={()=>handleAddElement('add-prestation')} >Demander une prestation</MenuItem>
            <MenuItem onClick={()=>handleAddElement('add-modification')}>Demander une modification</MenuItem>
        </Menu>
    </div>
  )
}
const mapActionsToProps = {}
const mapStateProps =(state)=>({
    api:state.api,
    user:state.user,
    ui: state.UI
 })
export default connect(mapStateProps,mapActionsToProps)(Welcome)  
