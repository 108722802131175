import React,{useState,useEffect} from 'react'
import { makeStyles } from '@material-ui/styles'
import { Paper, Typography,Divider } from '@material-ui/core'
import clsx from 'clsx'
import Card from '@material-ui/core/Card';
import Activites from './Components/Activites';
import ProductionParproduit from 'pages/stats/ProductionParproduit'
import EvolutionProduction from 'pages/stats/EvolutionProduction'
import {derniereProposition} from 'redux/actions/apiActions'
import { formatNumber } from 'application';
import MaterialTable from 'material-table';
import { useSelector } from 'react-redux';
import { addZero } from 'application';
import { getStateCourtage } from 'redux/actions/apiActions';

const useStyle = makeStyles(theme=>({
  root:{
    margin:20,
    display:'flex',
    flexDirection:'column',
    flex:1,
  },
  hpaper:{
    padding:20,
    borderRadius:10,
    backgroundColor:theme.palette.primary.main
  },
  texte:{
    color:'#fff'
  },
  card:{
    display:'flex',
    flex:1,
    flexDirection:'column',
    justifyContent:'space-around',
    padding:20,
    margin:20,
    alignItems:'center'
  },
  activityCard:{
    padding:20,
    margin:20,
  },
  activityContent:{
    display:'flex',
    justifyContent:'sapce-between'
  },
  activityItem1:{
    flex:2,
    margin:10
  },
  activityItem2:{
    flex:1,
    margin:10
  }
}))

function AccueilCourtage(props) {

  const [derniereprop,setDerniereprop] = useState([])

  useEffect(()=>{
      derniereProposition(5,3).then(res=>{ if(res){setDerniereprop(res)}})
    },[])

  const classes = useStyle()

  const produits = useSelector(state=>state.api.produits)

  const [state,setState] = useState()
  const handleGetState =()=>{
    getStateCourtage().then(res=>{
      res ? setState(res) : setState(null)
    })
  }
 
  useEffect(()=>{
    handleGetState()
  },[])

  return (
    <div className={classes.root}>
      <Paper className={classes.hpaper} variant="outlined">
          <div>
            <Typography className={classes.texte} variant='h4'> Bienvene sur la plate forme de gestion de vos contrats</Typography>
            <Typography className={clsx([classes.texte])} variant='caption'> Contrat,adherent,opération,facturation, ....</Typography>
          </div>
      </Paper>
      <div style={{display:'flex',justifyContent:'center'}}>
        <Card className='flex flex-col items-center justify-center flex-1 my-4 mx-2 py-2 rounded-md bg-white' variant='outlined'>
          <Typography variant='h4'> {(state && state.conventions) ? addZero(state.conventions) : "00"} / {produits && addZero(produits.length)}</Typography>
          <Typography variant='body1'> Convention(s) / produits</Typography>
        </Card>
        <Card className='flex flex-col items-center justify-center flex-1 my-4 mx-2 py-2 rounded-md bg-white' variant='outlined'>
          <Typography variant='h4'>{(state && state.adherents) ? addZero(state.adherents) : "00"}</Typography>
          <Typography variant='body1'> Adhérent(s)</Typography>
        </Card>
        <Card className='flex flex-col items-center justify-center flex-1 my-4 mx-2 py-2 rounded-md bg-white' variant='outlined'>
          <Typography variant='h4'>{(state && state.prestations) ? addZero(state.prestations) : "00"}</Typography>
          <Typography variant='body1'> Prestation(s)</Typography>
        </Card>
        <Card className='flex flex-col items-center justify-center flex-1 my-4 mx-2 py-2 rounded-md bg-white' variant='outlined'>
          <Typography variant='h4'> {(state && state.sinistre) ? addZero(state.sinistre) : "00"}</Typography>
          <Typography variant='body1'> Sinsitre(s)</Typography>
        </Card>
      </div>

      <div className='flex flex-row flex-wrap gap-3 justify-between'>
          <EvolutionProduction />
          <ProductionParproduit />
      </div>
      <div className='flex flex-row gap-3 my-8'>
          <Paper className='flex-1'  variant='outlined'>
            <div className='flex flex-row p-3 bg-slate-200'>
              <Typography Typography variant='h5'>Dernières souscription</Typography>
            </div>
            <Divider />
            <MaterialTable 
                columns={[
                    { title: '#Ref', field: 'id' },
                    { title: '#ID', field: 'idproposition' },
                    { title: 'Souscripteur', render:rowData=><span>{`${rowData['nom']} ${rowData['prenom']}`}</span> },
                    { title: 'Produit', field: 'libelleproduit'},
                    { title: 'Capital',render:rowData=>formatNumber(rowData.capital)  },
                    { title: 'Prime', render:rowData=>formatNumber(rowData.prime) }
                ]}
                data={derniereprop}
                localization={{
                    body: {emptyDataSourceMessage: 'Aucune donnée trouvée !'},
                }}
                options={{
                    exportButton: false,
                    toolbar:false,
                    actionsColumnIndex: -1,
                    paging:false,
                    sorting:false
                }}
              />
          </Paper>
          <Paper className={`flex-1`} variant='outlined'>
            <div className='flex flex-row p-3 bg-slate-200'>
              <Typography variant='h5'>Dernières activités</Typography>
            </div>
              <Divider />
            <div className={classes.activityContent}>
              <div className={classes.activityItem1}>
                <Activites />
              </div>
              <div className={classes.activityItem2}></div>
            </div>
            </Paper>
      </div>
    </div>
  )
}

export default AccueilCourtage