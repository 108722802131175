const isEmail = (email) => {
    const regEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email.match(regEx)) return true;
    else return false;
  };
const isPhone =(phone) =>{
    const regEx =/(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]‌​)\s*)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)([2-9]1[02-9]‌​|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})\s*(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+)\s*)?$/

}
const isEmpty = (string) => {
    if (string.trim() === '') return true;
    else return false;
};

  export const ValidateSouscripteur=(data)=>{
    let errors = {};
    if (isEmpty(data.emailSous)) {
        errors.email = 'Adresse email ne doit pas êtrevide';
      } else if (!isEmail(data.email)) {
        errors.emailSous = 'Adresse email incorrect';
    }
    if(isEmpty(data.nomSous.trim())) errors.nomSous='Nom souscripteur vide'
    if(isEmpty(data.prenomSous.trim())) errors.prenomSous='Prénom souscripteur vide'
    if(isEmpty(data.dateNaissanceSous.trim())) errors.dateNaissanceSous='Date naissance souscripteur vide'
    if(!data.lieuResidenceSous.MonLibelle || isEmpty(data.lieuResidenceSous.trim)) errors.lieuResidenceSous='Lieu naissance incorrect'
    if(isEmpty(data.pieceIdentiteSous.trim())) errors.pieceIdentiteSous='Veuillez renseinner le numero de la picèce'
   
    return {
        errors,
        valid: Object.keys(errors).length === 0 ? true : false
    };
  }


  export const validatePartenaire=(data)=>{
    let errors = {};
 
    if(isEmpty(data.code)) errors.code='Code paretanirevide'
    if(isEmpty(data.designation)) errors.designation='Raison sociale inccorect'
  

    return {
        errors,
        valid: Object.keys(errors).length === 0 ? true : false
    };
  }

  
  export const validateSettingUtilisateur=(data)=>{
    let errors = {};
    if (isEmpty(data.email)) {
        errors.email = 'Adresse email ne doit pas êtrevide';
      } else if (!isEmail(data.email)) {
        errors.email = 'Adresse email incorrect';
    }
    if(isEmpty(data.nom)) errors.nom='Nom souscripteur vide'
    if(isEmpty(data.prenom)) errors.prenom='Prénom souscripteur vide'
    if(isEmpty(data.login)) errors.login="Nom d''utilisateur incorrect'"
    if(isEmpty(data.datenaissance)) errors.datenaissance='Date naissance souscripteur vide'
    if(isEmpty(data.password)) errors.password='Mot de passe ne doit pas être vide'
    if(isEmpty(data.passwordConfirm)) errors.passwordConfirm='Mot de passe de confirmation ne doit pas être vide'
    if(data.password !== data.passwordConfirm) errors.confirmation='Veuillez confirmer le mot de passe'
    if(!data.sexe || isEmpty(data.sexe)) errors.sexe='Sexe incorrect'

    if(data.codezone==='') errors.codezone='Veuillez renseigner la zone/departement'
    if(data.codeequipe==='') errors.codeequipe='Veuillez renseigner la Equipe/agence'
    if(data.codereseau==='') errors.codereseau='Veuillez renseigner le reseau'

    return {
        errors,
        valid: Object.keys(errors).length === 0 ? true : false
    };
  }