import React from "react";
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
// core components

import Button from "components/CustomButtons/Button.js";
import { logoutUser } from 'redux/actions/userActions'
import styles from "assets/jss/llv/components/headerLinksStyle.js";
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
const useStyles = makeStyles(styles);

function AdminNavbarLinks(props) {
  const classes = useStyles();
  let currentUser = ""
  const history = useHistory()
  const { user: { credentials }, ui: { layoutRoot } } = props
  if (credentials.hasOwnProperty('branche')) {

    currentUser = credentials.nom.replace("\\", '').replace("'\\", '') + " " + credentials.prenom.replace('\\', '').replace("'\\", '')
  }

  const [openNotification, setOpenNotification] = React.useState(null);
  const [openProfile, setOpenProfile] = React.useState(null);
  const handleClickNotification = event => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };
  const handleCloseNotification = () => {
    setOpenNotification(null);
  };
  const handleClickProfile = event => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };

  const handleCloseProfile = () => {
    setOpenProfile(null);
  };
  const retourAccueil = () => {
    history.push("/");
  };
  const handleLogout = (event) => {
    props.logoutUser()
    setOpenProfile(null);
  }
  const changePassword = () => {
    setOpenProfile(null);
    history.push(`/${layoutRoot}/modification-mot-passe`);
  }
  return (
    <div className={classes.manager}>
      <Button
        color={window.innerWidth > 959 ? "transparent" : "white"}
        justIcon={window.innerWidth > 959}
        simple={!(window.innerWidth > 959)}
        aria-owns={openProfile ? "profile-menu-list-grow" : null}
        aria-haspopup="true"
        onClick={handleClickProfile}
        className={classes.buttonLink}
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          color:props.color
        }}
      >
        <Person className={classes.icons} />
        {currentUser}
        <Hidden mdUp implementation="css">
          <p className={classes.linkText}>Profile</p>
        </Hidden>
      </Button>
      <Poppers
        open={Boolean(openProfile)}
        anchorEl={openProfile}
        transition
        disablePortal
        className={
          classNames({ [classes.popperClose]: !openProfile }) +
          " " +
          classes.popperNav
        }
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            id="profile-menu-list-grow"
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom"
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleCloseProfile}>
                <MenuList role="menu">
                  <MenuItem
                    onClick={retourAccueil}
                    className={classes.dropdownItem}
                  >
                    Retour à l'accueil
                  </MenuItem>
                  <Divider light />
                  <MenuItem
                    onClick={changePassword}
                    className={classes.dropdownItem}
                  >
                    Modifier mon mot de passe
                  </MenuItem>
                  <Divider light />
                  <MenuItem
                    onClick={handleLogout}
                    className={classes.dropdownItem}
                  >
                    Deconnexion
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Poppers>
    </div>

  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  ui: state.UI
});

const mapActionsToProps = { logoutUser };

export default connect(
  mapStateToProps,
  mapActionsToProps
)(AdminNavbarLinks);
