import React, { useReducer, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import clsx from 'clsx';
import GridItem from "components/Grid/GridItem.js";
import { reduxForm, change, getFormValues, reset } from 'redux-form';
import { connect, useDispatch } from 'react-redux'
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { green, pink } from '@material-ui/core/colors';
import { Assignment, Add } from "@material-ui/icons/";
import { verifRule } from 'application'
import { Button, Divider, Paper } from "@material-ui/core";
import { totProductionAgent } from 'redux/actions/apiActions'
import ExtensionIcon from '@material-ui/icons/Extension';
import DeleteIcon from '@material-ui/icons/Delete';
import GavelIcon from '@material-ui/icons/Gavel';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import SyncProblemIcon from '@material-ui/icons/SyncProblem';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import SelectAllIcon from '@material-ui/icons/SelectAll';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import StopIcon from '@material-ui/icons/Stop';
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom";

const useStyles = makeStyles((theme) => ({
    root:{
        width:'100%',
        display:'flex',
        flexDirection:'column',
        flex:1
    },

    corps:{
        width:'75%',
        [theme.breakpoints.down('sm')]: {
            width:'100%',
        },
        [theme.breakpoints.up('md')]: {
            width:'75%',
        },
        display:'flex',
        flexWrap:'wrap',
        marginLeft:'auto',
        marginRight:'auto',
        marginTop:75
    },

    btn: {
        display:'flex',
        flex:1,
        flexDirection:'column',
        backgroundColor:'#fff',
        border:'1px solid #f6f6f6',
        margin:20,
        borderRadius:10,
        '&:hover':{
            backgroundColor:theme.palette.primary.main,
            color:'#fff',
        }
    },
    btno: {
        display:'flex',
        flex:1,
        flexDirection:'column',
        backgroundColor:'#fff',
        border:'1px solid #f6f6f6',
        margin:20,
        borderRadius:10,
        '&:hover':{
            backgroundColor:'#f9b233',
            color:'#fff',
        }
    },
    avatar: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center'
    },
    pink: {
        color: theme.palette.getContrastText(pink[500]),
        backgroundColor: pink[500],
    },
    green: {
        color: '#fff',
        backgroundColor: green[500],
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
        marginRight:20
    },
    paper: {
        display:'flex',
        flexWrap:'wrap',
        justifyContent:'center',
        padding: 20,
        marginBottom:20
    },
    line: {
        marginBottom: 20
    },
    Button: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        textTransform: 'none',
    },
    actionContainer:{
        display:'flex',
        justifyContent:'flex-start',
        marginTop:20,
        flexWrap:'wrap',
        marginBottom:20
    },
    btnadd:{
        display:'flex',
        paddingLeft:20,
        paddingRight:20,
        paddingTop:10,
        paddingBottom:10,
        justifyContent:'space-between',
        backgroundColor:'#fff',
        border:'1px solid #f6f6f6',
        alignItems:'center',
        margin:20,
        borderRadius:10,
        '&:hover':{
            backgroundColor:theme.palette.primary.main,
            color:'#fff',
        }

    },
    btnItemContainer:{
        display:'flex',
        flexDirection:'column',
        justifyContent:'space-between',
        alignItems:'center'
    },
    cardLogo:{
        display:'flex',
        flexDirection:'column',
        margin:20,
        justifyContent:'center',
        alignItems:'center'
    },
    img:{
        objectFit: 'fill',
        width:50,
        borderRadius:100,
        
    },
    eheader:{
        display:'flex',
        width:'100%',
        paddingTop:20,
        paddingBottom:20,
        backgroundColor:theme.palette.primary.main
    },
    boxheader:{
        display:'flex',
        width:'60%',
        [theme.breakpoints.down('sm')]: {
            width:'100%',
        },
        [theme.breakpoints.up('md')]: {
            width:'60%',
        },
        justifyContent:'space-between',
        alignItems:'center',
        marginLeft:'auto',
        marginRight:'auto'
    },
    btnAdd:{
        background:'transparent !important',
        paddingLeft:20,
        paddingRight:20,
        color:'white',
        border:'1px solid white',
        borderRadius:0,
        marginLeft:20,
        marginTop:40,
        "&:hover":{
            backgroundColor:theme.palette.secondary.main,
        }
    },
}));


function PretFonctions(props) {
    const classes = useStyles();
    const { handleCliqueFonction, user: { privileges }, user: { credentials } } = props
    const history = useHistory()
    const {path} =useRouteMatch()
    const dispatch = useDispatch()
    const handleNew = () => {
        dispatch(reset('addPret'))
        history.push(`${path}/add-pret`, {});
    }

    const {codepartenaire}=credentials
    const [pretData, setPretData] = useReducer((state, newState) => ({ ...state, ...newState }),
        {
            pretEnAttente: "",
            pretEnCotation: "",
            pretValide: "",
            pretMisEnPlace: "",
            pretEchu: "",
            pretRejeter: "",
            pretRacheter: "",
            pretAnnuler: '',
            total: 0
        });

        useEffect(() => {
            totProductionAgent(credentials.idmembre).then(res => {
                if (res) {
                    const { general,totaux} = res
                    setPretData({
                        ['pretEnAttente']: general.enAttente?.nbCotation,
                        ['pretEnCotation']: general.cotations?.nbCotation,
                        ['pretValide']: general.valides?.nbValide,
                        ['pretMisEnPlace']: general.enplaces?.nbMisEnPlace,
                        ['pretEchu']: general.echus?.nbEchu,
                        ['pretRacheter']: general.rachetes?.nbreracheter,
                        ['pretRejeter']: general.rejetes?.nbrejeter,
                        ['pretAnnuler']: general.annules?.nbreannuler,
                        ['total']: general.all?.total,
                    })
                }
            })
        }, [])

    return (
        <div className={classes.root}>
            
            <div className={classes.eheader}>
                <div className={classes.boxheader}>
                    <div className={classes.cardLogo}>
                        <img className={classes.img} src={require(`assets/images/${(codepartenaire &&codepartenaire!=='')?codepartenaire+'.jpg':"LLV.jpg"}`).default} />
                        <span style={{fontStyle:'bold',color:'white',fontSize:26}}>{credentials['partenaire']||""}</span>
                    </div>
                    <span style={{fontStyle:'bold',color:'white',fontSize:30,display:'flex',justifyContent:'center'}}>Bienvenue sur e-Prêts</span>
                    <div></div>
                </div>
            </div>
     
            <div className={classes.corps}>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <Paper style={{padding:20,marginBottom:20}}>
                        <Typography variant='h5'>NOUVELLE DEMANDE DE COTATION</Typography>
                        <Divider light className={classes.line} />
                        <div className={classes.actionContainer}>
                            <Button className={classes.btnadd} onClick={()=>handleCliqueFonction('SIMUL')}>
                                <Avatar aria-label="recipe" className={clsx(classes.large, classes.pink)}><ExtensionIcon /></Avatar>
                                <Typography align='center' gutterBottom variant="h5" component="h5">Je fais une simulation</Typography>
                            </Button>
                            {verifRule('prop', 'add', privileges) && (
                                <Button onClick={handleNew} className={classes.btnadd}>
                                    <Avatar aria-label="recipe" className={clsx(classes.large, classes.green)}><Add /></Avatar>
                                    <Typography align='center' gutterBottom variant="h5" component="h5">je démarre une nouvelle cotation</Typography>
                                </Button>
                            )}
                        </div>
                    </Paper>

                    <Paper style={{padding:20,marginBottom:20}}>
                        <Typography variant='h5'>TABLEAU DE BORD  DES DEMANDES DE COTATION DE PRETS</Typography>
                        <Divider light className={classes.line} />
                        <div className={classes.actionContainer}>

                                <Button className={classes.btn} onClick={event => handleCliqueFonction('ALL')}>
                                    <div className={classes.btnItemContainer}>
                                    {(pretData.total && pretData.total !== "") ? (
                                        <div className="p-4 text-secondary-main">
                                            <Typography variant="h4" className="text-secondary-main">{pretData.total}</Typography>
                                        </div>) : (
                                            <Avatar aria-label="recipe" className={clsx(classes.large)}><SelectAllIcon /></Avatar>)}
                                        Toutes les demandes
                                    </div>
                                </Button>
                                <Button className={classes.btn} onClick={event => handleCliqueFonction('ENATTENTE')}>
                                    <div className={classes.btnItemContainer}>
                                        {(pretData.pretEnAttente && pretData.pretEnAttente !== "") ? (
                                            <div className="p-4 text-secondary-main">
                                                <Typography variant="h4" className="text-secondary-main">{pretData.pretEnAttente}</Typography>
                                                
                                            </div>) : (<Avatar aria-label="recipe" className={clsx(classes.large)}><HourglassEmptyIcon /></Avatar>)}
                                            Cotations en attente
                                    </div>
                                </Button>
                                <Button className={classes.btn} onClick={event => handleCliqueFonction('ENTRAITEMENT')}>
                                    <div className={classes.btnItemContainer}>
                                        {(pretData.pretEnCotation && pretData.pretEnCotation !== "") ? (
                                            <div className="p-4 text-secondary-main">
                                                <Typography variant="h4" className="text-secondary-main">{pretData.pretEnCotation}</Typography>
                                                
                                            </div>) : <Avatar aria-label="recipe" className={clsx(classes.large)}><GavelIcon /></Avatar>}
                                        Cotations en traitement
                                    </div>
                                </Button>
                                <Button className={classes.btn} onClick={event => handleCliqueFonction('VALIDER')}>
                                    <div className={classes.btnItemContainer}>
                                        {(pretData.pretValide && pretData.pretValide !== "") ? (
                                            <div className="p-4 text-secondary-main">
                                                <Typography variant="h4" className="text-secondary-main">{pretData.pretValide}</Typography>
                                                
                                            </div>) : <Avatar aria-label="recipe" className={clsx(classes.large)}><AssignmentTurnedInIcon /></Avatar>}
                                        Cotations validées
                                    </div>
                                </Button>
                                <Button className={classes.btn} onClick={event => handleCliqueFonction('MISENPLACE')}>
                                    <div className={classes.btnItemContainer}>
                                    {(pretData.pretMisEnPlace && pretData.pretMisEnPlace !== "") ? (
                                        <div className="p-4 text-secondary-main">
                                            <Typography variant="h4" className="text-primary-main">{pretData.pretMisEnPlace}</Typography>
                                           
                                        </div>) : <Avatar aria-label="recipe" className={clsx(classes.large)}><Assignment /></Avatar>}
                                        Cotations avec prêt mis en place
                                    </div>
                                </Button>
                            </div>  
                    </Paper>
                    <Paper style={{padding:20,marginBottom:20}}>
                        <Typography variant='h5'>ETAT DES DEMANDES SORTIES</Typography>
                        <Divider light className={classes.line} />
                        <div className={classes.actionContainer}>

                            <Button className={classes.btno} onClick={event => handleCliqueFonction('REJETES')}>
                                <div className={classes.btnItemContainer}>
                                    {(pretData.pretRejeter && pretData.pretRejeter !== "") ? (
                                        <div className="p-4 text-secondary-main">
                                            <Typography variant="h4" className="text-red-500 font-bold">{pretData.pretRejeter}</Typography>
                                            
                                        </div>) : <Avatar aria-label="recipe" className={clsx(classes.large)}><SyncProblemIcon /></Avatar>}
                                    Cotations Réjétées
                                </div>
                            </Button>
                            
                            <Button className={classes.btno} onClick={event => handleCliqueFonction('RACHETER')}>
                                <div className={classes.btnItemContainer}>
                                    {(pretData.pretRacheter && pretData.pretRacheter !== "") ? (
                                       <div className="p-4 text-secondary-main">
                                            <Typography variant="h4" className="text-red-500 font-bold">{pretData.pretRacheter}</Typography>
                                           
                                            </div>) : <Avatar aria-label="recipe" className={clsx(classes.large)}><StopIcon /></Avatar>}
                                    Cotations avec prêt racheté
                                </div>
                            </Button>
                  
                            <Button className={classes.btno} onClick={event => handleCliqueFonction('FERMER')}>
                                <div className={classes.btnItemContainer}>
                                    {(pretData.pretEchu && pretData.pretEchu !== "") ? (
                                       <div className="p-4 text-secondary-main">
                                            <Typography variant="h4" className="text-red-500 font-bold">{pretData.pretEchu}</Typography>
                                           
                                        </div>) : <Avatar aria-label="recipe" className={clsx(classes.large)}><CancelPresentationIcon /></Avatar>}
                                    Cotations avec prêt clôturé
                                </div>
                            </Button>
                           
                            
                            <Button className={classes.btno} onClick={event => handleCliqueFonction('ANNULER')}>
                                <div className={classes.btnItemContainer}>
                                    {(pretData.pretAnnuler && pretData.pretAnnuler !== "") ? (
                                        <div className="p-4 text-secondary-main">
                                            <Typography variant="h4" className="text-red-500 font-bold">{pretData.pretAnnuler}</Typography>
                                            
                                        </div>) : <Avatar aria-label="recipe" className={clsx(classes.large)}><DeleteIcon /></Avatar>}
                                    Cotations annulées
                                </div>
                            </Button>
                            
                        </div>
                    </Paper>
                </GridItem>
           
        </div>
    </div>
    )
}


const updateField = (field, data) => (dispatch) => dispatch(change('addPret', field, data))
const mapPropsActions = {
    updateField,
}
const mapStateToProps = (state) => ({
    api: state.api,
    user: state.user,
    ui: state.UI,
    data: getFormValues('addPret')(state)
});

export default connect(mapStateToProps, mapPropsActions)(reduxForm({form:'addPret',destroyOnUnmount:false, })(PretFonctions))