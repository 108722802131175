import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
// @material-ui/icons
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";
// core components
import styles from "assets/jss/llv//components/customInputStyle.js";
import TextField from '@material-ui/core/TextField'

const useStyles = makeStyles(styles);

export default function CustomInput(props) {
  const classes = useStyles();
  const {
    formControlProps,
    label,
    id,
    name,
    labelProps,
    inputProps,
    error,
    success,
    type,
    value,
    input,
    ...custom
  } = props;
  //const [value, setValue] = React.useState(value);
  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error
  });
  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true
  });
  const marginTop = classNames({
    [classes.marginTop]: label === undefined
  });

  return (
    <FormControl
      {...formControlProps}
      className={formControlProps.hasOwnProperty('className') ? (formControlProps.className + " " + classes.formControl) : classes.formControl}
    >
      <TextField
        autoComplete='off'
        id={id}
        name={name}
        label={label}
        type={type}
        className={classes.labelClasses}
        InputLabelProps={{
          shrink: true,
        }}
        value={input ? input.value : value}
        onChange={input ? input.onChange : props.onChange}
        {...custom}
      />
      {error ? (
        <Clear className={classes.feedback + " " + classes.labelRootError} />
      ) : success ? (
        <Check className={classes.feedback + " " + classes.labelRootSuccess} />
      ) : null}
    </FormControl>
  );
}

CustomInput.propTypes = {
  label: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  success: PropTypes.bool
};
