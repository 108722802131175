import React, { useState, useReducer, Fragment } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { connect, useDispatch } from 'react-redux'
import { reduxForm, change, getFormValues } from 'redux-form';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from '@material-ui/core/Card';
import 'jspdf-autotable'
import { getSearchContratsAgent, EtatCotisation } from 'redux/actions/apiActions'
import MaterialTable from 'material-table'
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import { green, pink } from '@material-ui/core/colors';
import CircularProgress from '@material-ui/core/CircularProgress';
import LabelledOutline from 'components/LabelledOutline/LabelledOutline'
import CustomInput from "components/CustomInput/CustomInput.js";
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Alert from '@material-ui/lab/Alert';
import { verifRule } from 'application'
import CustomDate from 'components/CustomDate/CustomDate'
import AlertPage from 'components/AlertPage'
import { etatEncaissement } from 'application'
import MiniHeader from 'components/MiniHeader';
import MinToolsBar from 'components/ESMinToolsBar';
import { ListAlt } from '@material-ui/icons';
import { IconButton,Tooltip } from '@material-ui/core'
function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}
//style
const useStyles = makeStyles(theme => ({
  root: {
    display:'flex',
    flex:0.5,
    justifyContent:'space-between',
    marginBottom: 20,
    marginLeft:20,
    '& :hover': {
      background: theme.palette.primary.main,
      border: 0,
      padding: '10 30px',
      textDecoration: 'none'
    }
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  loading: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
    justifyContent: 'center',
    alignItems: 'center'
  },
  buttonAdd: {
    width: '100%',
    marginBottom: 20,
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: theme.palette.secondary.main,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 30,
    color: theme.palette.secondary.main,
    '&:hover': {
      borderColor: theme.palette.primary.main,
    }
  },

  buttonDetails: {
    width: '100%',
    borderWidth: 1,
    borderColor: theme.palette.secondary.main,
    backgroundColor: '#fff',
    color: theme.palette.secondary.main,
    '&:hover': {
      borderColor: theme.palette.primary.main,
    }
  },

  buttonAddChild: {
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.primary.main,
    }
  },
  buttonSearch: {
    width: '100%',
    marginBottom: 20,
    marginTop: 20,
    padding: 10,
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.main,

    color: '#FFF',
    '&:hover': {
      borderColor: theme.palette.secondary.main,
    }
  },
  buttonReset: {
    width: '100%',
    marginBottom: 20,
    marginTop: 20,
    padding: 10,
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: theme.palette.secondary.main,
    backgroundColor: theme.palette.secondary.main,
    color: '#FFF',
    '&:hover': {
      borderColor: theme.palette.secondary.main,
    }
  },
  buttonNewSearch: {
    width: '100%',
    marginBottom: 20,
    marginTop: 20,
    padding: 10,
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: theme.palette.primary.main,
    backgroundColor: "#FFF",
    color: theme.palette.primary.main,
    '&:hover': {
      borderColor: theme.palette.secondary.main,
      color: theme.palette.primary.main,
    }
  },
  media: {
    height: 140,
  },
  avatar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center'
  },
  pink: {
    color: theme.palette.getContrastText(pink[500]),
    backgroundColor: pink[500],
  },
  green: {
    color: '#fff',
    backgroundColor: green[500],
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  dcard: {
    marginBottom: 10,
    paddingBottom: 10
  },
  rcontainer:{
   marginTop:40,
   width:'75%', 
   marginLeft:'auto',
   marginRight:'auto',
   [theme.breakpoints.down('sm')]:{
      width:'100',
      marginLeft:20,
      marginRight:20
    },
  },

  resultContainer:{
    display:'flex',
    flexDirection:'column',
    maxWidth:'100%',
    flexWrap:'wrap',
  },
  resultHeader:{
    display:'flex',
    flexWrap:'wrap',
    marginRight:20
  },
  tablibOper: {
    display: 'flex',
    flexDirection: 'column'
  },
  pCot:{
    display:'flex',
    justifyContent:'space-between',
    padding:5,
    marginTop:5
  }
}));

function RechercheContrat(props) {
  const classes = useStyles();
  const { user: {credentials }, api: { produits } } = props
  const [loading, setLoading] = useState(false)
  const [details, setDetails] = useState([])
  const [titleDetails, setTitleDetails] = useState('')
  const handleDetails = (dt, title) => {
    setDetails(dt)
    setTitleDetails(title)
  }
  const [showResult, setShowResult] = useState(false)
  const [contrat, setContrat] = useReducer((state, newState) => ({ ...state, ...newState }),
    {
      encours: "",
      suspendu: "",
      arrete: "",
      total: 0

    });
  const [detailContrat, setDetailContrat] = useReducer((state, newState) => ({ ...state, ...newState }),
    {
      detailEncours: "",
      detailSuspendu: "",
      detailArrete: "",
      detailAll: ""

    });
  const [inputField, setInputField] = useReducer((state, newState) => ({ ...state, ...newState }),
    {
      idcontrat: '',
      numeroproposition: '',
      numeropolice: '',
      numerobulletin: '',
      dateeffet: '',
      dateecheance: '',
      capital: '',
      prime: '',
      adherent: '',
      datenaissance: '',
      codeconseiller: '',
      nomconseiller: '',
      etatcontrat: '',
      codeproduit: '',
      codeguichet: '',
      numerocompte: ''
    });
  const handleInputChange = (event) => {
    const { name, value } = event.target
    setInputField({
      [name]: value
    })
  }
  const dispatch = useDispatch()
  const handleReset = () => {
    setInputField({
      ['idcontrat']: '',
      ['numeroproposition']: '',
      ['numeropolice']: '',
      ['numerobulletin']: '',
      ['dateeffet']: '',
      ['dateecheance']: '',
      ['capital']: '',
      ['prime']: '',
      ['adherent']: '',
      ['datenaissance']: '',
      ['codeconseiller']: '',
      ['nomconseiller']: '',
      ['etatcontrat']: '',
      ['codeproduit']: '',
      ['codeguichet']: '',
      ['numerocompte']: ''
    })
  }
  const [alertInfo, setAlertInfo] = useState({})
  const handleAlertClose = (action = null) => {
    setAlertInfo({ open: false, message: "", succes: false })
    action && action()
  }
  const handleAlertOpen = (message, succes = false, action) => {
    setAlertInfo({ open: true, message: message, succes: succes, action: action })
  }

  const handleSearch = () => {
    setLoading(true)
    if (inputField.idcontrat === '' && inputField.numeroproposition === '' && inputField.numeropolice === '' && inputField.dateeffet === '' && inputField.dateEcheance === '' && inputField.capital === '' && inputField.prime === '' && inputField.adherent === '' && inputField.codeconseiller === '' && inputField.nomconseiller === '' && inputField.numerocompte === '') {
      return
    }
    getSearchContratsAgent(inputField).then(resData => {
      if (resData) {
        const detailsAll = resData.details

        const productionsAll = resData.production
        let mytotal = 0
        if (productionsAll && productionsAll.lenght !== 0) {
          productionsAll.map(prod => {
            mytotal = mytotal + parseInt(prod.Nombre)
          })
          setContrat({
            ['encours']: productionsAll.filter(item => item.Etat == 1)[0],
            ['suspendu']: productionsAll.filter(item => item.Etat == 2)[0],
            ['arrete']: productionsAll.filter(item => item.Etat == 3)[0],
            ['total']: mytotal
          })
        }

        if (detailsAll && detailsAll.lenght !== 0) {
          setDetailContrat({
            ['detailEncours']: detailsAll.filter(item => item.Etat === 'En cours'),
            ['detailSuspendu']: detailsAll.filter(item => item.Etat === 'Suspendu'),
            ['detailArrete']: detailsAll.filter(item => item.Etat === 'Arrêté'),
            ['detailsTout']: detailsAll
          })
          handleDetails(detailsAll, "Liste des contrats")
        }
        setShowResult(true)
        setLoading(false)
      } else {
        setLoading(false)
        handleAlertOpen("Aucune données trouvées,Veuillez verifier les critères", false)
      }
    }).catch(err => {
      setLoading(false)
      handleAlertOpen("Erreur lors de la récupération des donneé", false)
    })

  }
  const handleEtatCotisation = (dataEtat) => {

    if (!dataEtat) return false
    EtatCotisation({ idContrat: dataEtat.id }).then(res => {
      if (res) {
        etatEncaissement(res)
      }
    })

  }

  return (
    <div style={{display:'flex',flexDirection:'column'}}>
      <AlertPage open={alertInfo['open'] || false} action={alertInfo['action'] || null} message={alertInfo['message'] || ""} succes={alertInfo['succes'] || false} handleAlertOpen={handleAlertOpen} handleAlertClose={handleAlertClose} />
      <MiniHeader titre="Rechercher un contrat" showbtn={false} plateforme='souscription'/>
      <MinToolsBar />
      {!showResult && (<div className={classes.rcontainer}>
        <LabelledOutline label="RECHERCHER UN UN CONTRAT">
          <Paper elevation={3} className={classes.dcard}>
            <GridContainer>
              <GridItem xs={12} sm={4} md={2} lg={2}>
                <CustomInput
                  label="Code guichet"
                  id="codeguichet"
                  name="codeguichet"
                  formControlProps={{
                    fullWidth: true
                  }}
                  value={inputField['codeguichet'] || ""}
                  type='texte'
                  variant="outlined"
                  onChange={handleInputChange}
                />
              </GridItem>
              <GridItem xs={12} sm={4} md={4} lg={4}>
                <CustomInput
                  label="N° compte/client"
                  id="numerocompte"
                  name="numerocompte"
                  formControlProps={{
                    fullWidth: true
                  }}
                  value={inputField['numerocompte'] || ""}
                  type='texte'
                  variant="outlined"
                  onChange={handleInputChange}
                />
              </GridItem>
            </GridContainer>
          </Paper>
          
            <Paper elevation={3} className={classes.dcard}>
              <GridContainer>
                <GridItem xs={12} sm={5} md={5} lg={5}>
                  <CustomInput
                    label="Adherent (Nom & Prenoms)"
                    id="adherent"
                    name="adherent"
                    value={inputField.adherent}
                    formControlProps={{
                      fullWidth: true
                    }}
                    type='texte'
                    variant="outlined"
                    onChange={handleInputChange}
                  />
                </GridItem>
                <GridItem xs={12} sm={4} md={2} lg={2}>
                  <CustomDate
                    label="Date de naissance"
                    id="datenaissance"
                    name="datenaissance"
                    formControlProps={{
                      fullWidth: true
                    }}
                    InputProps={{
                      type: 'date',
                    }}
                    value={inputField.datenaissance}
                    variant="outlined"
                    onChange={handleInputChange}
                  />
                </GridItem>
              </GridContainer>
            </Paper>
            {credentials.codepartenaire==='092'&& <Paper elevation={3} className={classes.dcard}>
              <GridContainer>
              {(produits && produits.length !== 0) && (
                  <GridItem xs={12} sm={4} md={4} lg={4}>
                    <TextField
                      variant="outlined"
                      id="codeproduit"
                      name='codeproduit'
                      select
                      label="Produit"
                      value={inputField.codeproduit}
                      onChange={handleInputChange}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{ marginTop: 20, marginBottom: 10 }}
                    >
                      <MenuItem key={0} value={0} />
                      {(produits.length !== 0) && produits.map((option) => (
                        <MenuItem key={option.CodeProduit} value={option.CodeProduit}>
                          {option.MonLibelle.toUpperCase()}
                        </MenuItem>
                      ))}
                    </TextField>
                  </GridItem>
                )}
                </GridContainer>
            </Paper>}
           
            {credentials.codepartenaire !== '092' && <Fragment>
            <Paper elevation={3} className={classes.dcard}>
              <GridContainer>
                <GridItem xs={12} sm={4} md={3} lg={3}>
                  <CustomInput
                    label="ID"
                    id="idcontrat"
                    name="idcontrat"
                    formControlProps={{
                      fullWidth: true
                    }}
                    value={inputField.idcontrat}
                    type='texte'
                    variant="outlined"
                    onChange={handleInputChange}
                  />
                </GridItem>
                <GridItem xs={12} sm={4} md={3} lg={3}>
                  <CustomInput
                    label="N° Proposition"
                    id="numeroproposition"
                    name="numeroproposition"
                    formControlProps={{
                      fullWidth: true
                    }}
                    value={inputField.numeroproposition}
                    type='texte'
                    variant="outlined"
                    onChange={handleInputChange}
                  />
                </GridItem>
                <GridItem xs={12} sm={4} md={3} lg={3}>
                  <CustomInput
                    label="N° Police"
                    id="numeropolice"
                    name="numeropolice"
                    formControlProps={{
                      fullWidth: true
                    }}
                    value={inputField.numeropolice}
                    type='texte'
                    variant="outlined"
                    onChange={handleInputChange}
                  />
                </GridItem>
                <GridItem xs={12} sm={4} md={3} lg={3}>
                  <CustomInput
                    label="N° Bulletin"
                    id="numerobulletin"
                    name="numerobulletin"
                    formControlProps={{
                      fullWidth: true
                    }}
                    value={inputField.numerobulletin}
                    type='texte'
                    variant="outlined"
                    onChange={handleInputChange}
                  />
                </GridItem>
              </GridContainer>
            </Paper>
            <Paper elevation={3} className={classes.dcard}>
              <GridContainer>
                <GridItem xs={12} sm={4} md={2} lg={2}>
                  <CustomDate
                    label="Date d'effet"
                    id="dateeffet"
                    name="dateeffet"
                    formControlProps={{
                      fullWidth: true
                    }}
                    InputProps={{
                      type: 'date',
                    }}
                    value={inputField.dateeffet}
                    variant="outlined"
                    onChange={handleInputChange}
                  />
                </GridItem>
                <GridItem xs={12} sm={4} md={2} lg={2}>
                  <CustomDate
                    label="Date fin cotisation"
                    id="dateecheance"
                    name="dateecheance"
                    formControlProps={{
                      fullWidth: true
                    }}
                    InputProps={{
                      type: 'date',
                    }}
                    value={inputField.dateecheance}
                    variant="outlined"
                    onChange={handleInputChange}
                  />
                </GridItem>
                <GridItem xs={12} sm={4} md={2} lg={2}>
                  <CustomInput
                    label="Capital"
                    id="capital"
                    name="capital"
                    formControlProps={{
                      fullWidth: true
                    }}
                    value={inputField.capital}
                    type='number'
                    variant="outlined"
                    onChange={handleInputChange}
                  />
                </GridItem>
                <GridItem xs={12} sm={4} md={2} lg={2}>
                  <CustomInput
                    label="Prime"
                    id="prime"
                    name="prime"
                    formControlProps={{
                      fullWidth: true
                    }}
                    value={inputField.prime}
                    type='number'
                    variant="outlined"
                    onChange={handleInputChange}
                  />
                </GridItem>
                {(produits && produits.length !== 0) && (
                  <GridItem xs={12} sm={4} md={4} lg={4}>
                    <TextField
                      variant="outlined"
                      id="codeproduit"
                      name='codeproduit'
                      select
                      label="Produit"
                      value={inputField.codeproduit}
                      onChange={handleInputChange}
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{ marginTop: 20, marginBottom: 10 }}
                    >
                      <MenuItem key={0} value={0} />
                      {(produits.length !== 0) && produits.map((option) => (
                        <MenuItem key={option.CodeProduit} value={option.CodeProduit}>
                          {option.MonLibelle.toUpperCase()}
                        </MenuItem>
                      ))}
                    </TextField>
                  </GridItem>
                )}
              </GridContainer>
            </Paper>

            <Paper elevation={3} className={classes.dcard}>
              <GridContainer>
                <GridItem xs={12} sm={2} md={2} lg={2}>
                  <CustomInput
                    label="Code conseiller"
                    id="codeconseiller"
                    name="codeconseiller"
                    value={inputField.codeconseiller}
                    formControlProps={{
                      fullWidth: true
                    }}
                    type='texte'
                    variant="outlined"
                    onChange={handleInputChange}
                  />
                </GridItem>
                <GridItem xs={12} sm={4} md={4} lg={4}>
                  <CustomInput
                    label="Nom conseiller"
                    id="nomconseiller"
                    name="nomconseiller"
                    value={inputField.nomConseiller}
                    formControlProps={{
                      fullWidth: true
                    }}
                    type='texte'
                    variant="outlined"
                    onChange={handleInputChange}
                  />
                </GridItem>
              </GridContainer>
            </Paper>
          </Fragment>}

          <Paper elevation={3} className={classes.dcard}>
            <GridContainer>
              <GridItem xs={12} sm={4} md={4} lg={4}>
                <Button variant='outlined' color='secondary' className={classes.buttonReset} onClick={handleReset} >
                  REINITIALISER
                </Button>
              </GridItem>
              <GridItem xs={12} sm={8} md={8} lg={8}>
                <Button variant='outlined' color='primary' className={classes.buttonSearch} onClick={handleSearch} >
                  RECHERCHER
                </Button>
              </GridItem>
            </GridContainer>
          </Paper>
        </LabelledOutline>
        </div>)}
      {showResult && (
        <div className={classes.rcontainer}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Button variant='outlined' color='primary' className={classes.buttonNewSearch} onClick={() => setShowResult(false)} >
                Edition des critères de recherche
              </Button>
            </GridItem>

            {(contrat.total !== 0) && (
              <GridItem xs={12} sm={4} md={3} lg={3}>
                <Card className={classes.root}>
                  <CardActionArea onClick={(e) => handleDetails(detailContrat.detailsTout, "Liste de mes contrats")}>
                    <CardContent>
                      <div className={classes.avatar}>
                        <Typography align='center' gutterBottom variant="caption" component="span">
                          Total
                        </Typography>
                      </div>

                      <Typography align='center' variant="h4" color="textPrimary" component="h4">
                        {(contrat.total !== 0) && formatNumber(contrat.total)}
                      </Typography>
                      <Button onClick={(e) => handleDetails(detailContrat.detailsTout, "Liste des contrats")} className={classes.buttonDetails}><Typography align='center' variant="caption" color="textPrimary" component='body2'>Voir le detail</Typography></Button>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </GridItem>
            )}

            {(contrat.encours && contrat.encours !== '') && (
              <GridItem xs={12} sm={4} md={3} lg={3}>
                <Card className={classes.root}>
                  <CardActionArea onClick={(e) => handleDetails(detailContrat.detailEncours, "Liste des contrats actifs")} >
                    <CardContent>
                      <div className={classes.avatar}>
                        <Typography align='center' gutterBottom variant="caption" component="span">
                          Contrats actifs
                        </Typography>
                      </div>
                      <Typography align='center' variant="h4" color="textPrimary" component="h4">
                        {formatNumber(contrat.encours.Nombre)}
                      </Typography>
                      <Button onClick={(e) => handleDetails(detailContrat.detailEncours, "Liste des contrats actifs")} className={classes.buttonDetails}><Typography align='center' variant="caption" color="textPrimary" component='body2'>Voir le detail</Typography></Button>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </GridItem>
            )}
            {(contrat.suspendu && contrat.suspendu !== '') && (
              <GridItem xs={12} sm={4} md={3} lg={3}>
                <Card className={classes.root}>
                  <CardActionArea onClick={(e) => handleDetails(detailContrat.detailSuspendu, "Liste des contrats suspendus")}>
                    <CardContent>
                      <div className={classes.avatar}><Typography align='center' gutterBottom variant="caption" component="span">
                        Contrats suspendus
                      </Typography></div>

                      <Typography align='center' variant="h4" color="textPrimary" component="h4">
                        {formatNumber(contrat.suspendu.Nombre)}
                      </Typography>
                      <Button onClick={(e) => handleDetails(detailContrat.detailSuspendu, "Liste des contrats suspendus")} className={classes.buttonDetails}><Typography align='center' variant="caption" color="textPrimary" component='body2'>Voir le detail</Typography></Button>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </GridItem>
            )}
            {(contrat.arrete && contrat.arrete !== '') && (
              <GridItem xs={12} sm={4} md={3} lg={3}>
                <Card className={classes.root}>
                  <CardActionArea onClick={(e) => handleDetails(detailContrat.detailArrete, "Liste des contrats arrêtés")}>

                    <CardContent>
                      <div className={classes.avatar}>
                        <Typography align='center' gutterBottom variant="caption" component="span">
                          Contrats arrêtés
                        </Typography></div>
                      <Typography align='center' variant="h4" color="textPrimary" component="h4">
                        {formatNumber(contrat.arrete.Nombre)}
                      </Typography>
                      <Button onClick={(e) => handleDetails(detailContrat.detailArrete, "Liste des contrats arrêtés")} className={classes.buttonDetails}><Typography align='center' variant="caption" color="textPrimary" component='body2'>Voir le detail</Typography></Button>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </GridItem>
            )}

          </GridContainer>
          {(details && details.length !== 0 && showResult) ? (
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <MaterialTable
                  columns={[
                    {title:'Contrat', field:'Idcontrat',render:rowData=>(<div className={classes.tablibOper}>
                        <Typography variant='h5'>#ID : {rowData.id}</Typography>
                        <Typography variant='h5'>{rowData.Produit}</Typography>
                        <Typography variant='caption'>{`Date d\'effet :${rowData.DateEffet?rowData.DateEffet:""}`}</Typography>
                        <Typography variant='caption'>{`Date d\'échéance : ${rowData.DateEcheance?rowData.DateEcheance:""}`}</Typography>
                        <div style={{display:'flex',justifyContent:'space-between'}}>
                        <Typography variant='caption'>{`Prime :${parseInt(rowData.Prime) ? formatNumber(parseInt(parseInt(rowData.Prime))) : ""}`}</Typography>
                        <Typography variant='caption'>{`Capital :${parseInt(rowData.Capital) ? formatNumber(parseInt(parseInt(rowData.Capital))) : ""}`}</Typography>
                        </div>
                        <Typography variant='caption'>{`Etat : ${rowData.Etat} \n`}</Typography>
                    </div>)},
                    { title: 'Adhérent', field: 'Adherent',render: rowData=>(<div className={classes.tablibOper}>
                         <Typography variant='h5'> {rowData.Adherent}</Typography>
                         <Typography variant='caption'>{`Date de naissance :${rowData.DateNaissance?rowData.DateNaissance:""}`}</Typography>
                    </div>)},
                    { title:"Cotisation", render:rowData=>(<div className={classes.tablibOper}>
                        <Paper className={classes.pCot} >
                          <Typography variant='caption'>{`Nb d\'émission :${parseInt(rowData.NombreEmission) ? formatNumber(parseInt(rowData.NombreEmission)) : ""}`}</Typography>
                          <Typography variant='caption'>{`Mt d\'émission :${parseInt(rowData.MontantEmission) ? formatNumber(parseInt(rowData.MontantEmission)) : ""}`}</Typography>
                        </Paper>
                        <Paper className={classes.pCot}>
                          <Typography variant='caption'>{`Nb d\'enc. :${parseInt(rowData.NombreEncaissement) ? formatNumber(parseInt(rowData.NombreEncaissement)) : ""}`}</Typography>
                          <Typography variant='caption'>{`Mt d\'enc. :${parseInt(rowData.MontantEncaissement) ? formatNumber(parseInt(parseInt(rowData.MontantEncaissement))) : ""}`}</Typography>
                        </Paper>
                        <Paper className={classes.pCot}>
                          <Typography variant='caption'>{`Nb d\'impayé :${parseInt(rowData.NombreImpayes) ? formatNumber(parseInt(parseInt(rowData.NombreImpayes))) : ""}`}</Typography>
                          <Typography variant='caption'>{`Mt d\'impayé :${parseInt(rowData.MontantImpayes) ? formatNumber(parseInt(parseInt(rowData.MontantImpayes))) : ""}`}</Typography>
                        </Paper>
                    </div>),
                    headerStyle: {
                      textAlign: 'center'
                    }    
                  },
                  {title:'Compte',filed:'Compte',render: rowData=>(<div>
                    <div style={{display:'flex',flexDirection:'column',padding:10,justifyContent:'center'}}>
                          <Typography variant='caption'>{`Code guichet :${rowData['CodeGuichet'] || ""} \n`}</Typography><br />
                          <Typography variant='caption'>{`N° Compte :${rowData['NumCompte'] || ""}\n`}</Typography><br />
                          <Typography variant='caption'>{`Rib :${rowData['Rib'] || ""}\n`}</Typography><br />
                    </div>
                  </div>),
                   headerStyle: {
                    textAlign: 'center'
                  }      
                },
                    { title:"Actions", render:rowData=>(
                    <div style={{display:'flex',justifyContent:'flex-end'}}>
                      <Tooltip title="Etat de cotisation">
                        <IconButton onClick={() => handleEtatCotisation(rowData)}>
                          <ListAlt />
                        </IconButton>
                      </Tooltip>
                     
                      
                    </div>),
                    headerStyle: {
                      textAlign: 'right'
                    }  
                  }
                  ]}
                  data={details}
                  title={titleDetails}
                  localization={{
                    toolbar: {
                      searchPlaceholder: 'Rechercher',
                      searchTooltip: 'Rechercher'
                    },
                    body: {
                      emptyDataSourceMessage: 'Aucune donnée trouvée !'
                    },
                    pagination: {
                      labelRowsSelect: 'Ligne(s)',
                      labelDisplayedRows: '{count} sur {from}-{to}',
                      firstTooltip: 'Prémière page',
                      previousTooltip: 'Précédent',
                      nextTooltip: 'Suivant',
                      lastTooltip: 'Dernière page'
                    }
                  }}

                  options={{
                    exportButton: true,
                    searchFieldStyle: {
                      fontSize: 18,
                      width: '100%',
                      height: 50,
                    },
                    actionsColumnIndex: -1
                  }}

                />
              </GridItem>
            </GridContainer>
          ) : (

            (!loading && (details && details.length === 0)) ? (<GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <Alert variant="filled" severity="error">
                  Aucune données trouvées ou l'opération à rencontrer une erreur !
                </Alert>
              </GridItem>
            </GridContainer>) : (<div className={classes.root}><CircularProgress color="primary" /></div>)

          )}
        </div>
      )}



      {loading && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress color="secondary" />
        </div>
      )}
      {/* Etat de cotisation */}

    </div>
  )
}



const updateField = (field, data) => (dispatch) => dispatch(change('adForm', field, data))
const mapPropsActions = {
  updateField,
}
const mapStateToProps = (state) => ({
  user: state.user,
  api: state.api,
  data: getFormValues('adForm')(state),
  ui: state.UI
});

export default connect(mapStateToProps, mapPropsActions)(reduxForm({ destroyOnUnmount: false, })(RechercheContrat))

