import React, { Fragment,useState,useEffect } from 'react'
import {withStyles } from '@material-ui/core/styles';
import {reduxForm,formValueSelector,change,registerField,getFormValues } from 'redux-form'
import {connect,useDispatch } from 'react-redux';
import {Grid,Box } from '@material-ui/core'
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Button from "components/CustomButtons/Button.js";
import NavigateNextOutlinedIcon from '@material-ui/icons/NavigateNextOutlined';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import 'jspdf-autotable'
import dayjs from 'dayjs'
import MuiAlert from '@material-ui/lab/Alert';
import {ageAssure} from 'redux/actions/userActions'
import {addSouscription,updateProposition,uploadDocuments,getPrimeGarantie,getPrimeAccessoireProduit,getCapitalReference,getPrimeReference} from 'redux/actions/apiActions'
import {generateBulletinSouscription} from 'pages/Commercial/Propositions/EditionBulletin' 
import Snackbar from '@material-ui/core/Snackbar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Slide from '@material-ui/core/Slide';
import red from '@material-ui/core/colors/red'
import green from '@material-ui/core/colors/green'
import AlertSucces from '@material-ui/lab/Alert';
import CheckIcon from '@material-ui/icons/Check';
import {formatNumber} from 'application'
import ModalLoading from 'components/ModalLoading'
import Chip from '@material-ui/core/Chip';
import FormLabel from '@material-ui/core/FormLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';

const styles =(theme)=>({
  root:{
    marginBottom:10,
    padding:10
  },
  btnback:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    backgroundColor:"#f9b233",
    paddingRight:20,
    paddingLeft:20,
    paddingTop:10,
    paddingBottom:10,
    color:'#ffffff',
    "&:hover":{
      backgroundColor:"#3c3c3b",
    },
    borderRadius:10,
  },
  btnnext:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    backgroundColor:theme.palette.primary.main,
    paddingRight:20,
    paddingLeft:20,
    paddingTop:10,
    paddingBottom:10,
    color:'#ffffff',
    "&:hover":{
      backgroundColor:"#f9b233",
    },
    borderRadius:10,
  },
  assureInfoRow:{
    display:'flex',
    flexDirection:'row'
  },
  chipRoot: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(1),
    marginBottom: 10,
  },
  chip: {
    margin: theme.spacing(1),
  },
  hTrait:{
    marginTop:20,
    marginBottom:20
  },
})
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
//button succès
const SuccesButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
}))(Button);
//bouton error
const ErrorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
}))(Button);

function SouscriptionFicheresume (props){
    const {classes,
      data:{civiliteSous,nomSous,prenomSous,sexeSous,dateNaissanceSous,lieuNaissanceSous,pieceIdentiteSous,naturePiece,
      lieuResidenceSous,professionSous,employeurSous,paysSous,emailSous,adressePostaleSous,telephoneSous,
      mobileSous,mobile2Sous,produit,assures,beneficiaires,modePaiement,organismePayeur,dateEffet,duree,periodicite,
      agence,numCompte,fraisadhesion,capitalSouscrit,primePrincipale,totalPrime,periodiciterente,montantrente,dureerente,
      codeagent,nomagent,codeguichet,beneficiaireauterme,beneficiaireaudeces,taille, poids,
      fumezVous, nbCigaretteJour, buvezVous, distraction, estInfirme, natureInfirmite, estEnArretTravail, fraisaccessoires,
      dateArretTravail,motifArret, dateRepriseTravail, causeArretTravail, dateCauseTravail},
      questionaires,
    user,
       handleSubmit,
       UI:{loading},
       initResume 
    } = props
    useEffect(() => {
      if(props.data.hasOwnProperty('civiliteSous')){
        const valueurSexe = (['Madame','Mademoiselle'].includes(civiliteSous))?"F":"M"
        props.dispatch(registerField("adForm","sexeSous",'Field'));
        props.updateField("sexeSous",valueurSexe);
      }
    });

    const [succes, setSucces] = useState(false)
    const [newContrat,setNewContrat] = useState(null)
    const dispatch = useDispatch()
    const [loadingui,setLoading] = React.useState(false)

    useEffect(()=>{
      dispatch({type:"STOP_LOADING_UI"})
      if(initResume){
        setLoading(false)
      }
    })
   
    const saveSouscription =(values)=>{
      setLoading(true)
      let mesDonnees
      mesDonnees =JSON.stringify(values,null,2)
      mesDonnees =JSON.parse(mesDonnees)
      mesDonnees.etat = 1
      if(mesDonnees.hasOwnProperty('beneficiaireauterme')){
        mesDonnees.beneficiaireauterme =mesDonnees.beneficiaireauterme.join(";");
      }
      if(mesDonnees.hasOwnProperty('beneficiaireaudeces')){
        mesDonnees.beneficiaireaudeces =mesDonnees.beneficiaireaudeces.join(";");
      }
   
      /*const newAssure =[]
      {assures.map((assure)=>{
        let garanties = assure.garantiesFacultatives?(assure.garantiesSouscrits.concat(assure.garantiesFacultatives.filter(item=>(item.checked===true&&item.CodeProduitGarantie!=='SENIOR')))):(assure.garantiesSouscrits)
        assure.garantiesSouscrits = garanties
        newAssure.push(assure)
         
        })
      }*/
      const newAssure =[]
      {assures.map((assure)=>{
        newAssure.push(assure)
        })
      }

      mesDonnees.assures = newAssure
      if(mesDonnees.hasOwnProperty('mode') && mesDonnees.mode==='UPDATE'){
        mesDonnees.typeop='UP'
        props.updateProposition(mesDonnees).then(async (response)=>{
          if(response){
            let newdata =JSON.stringify(response,null,2)
            newdata =JSON.parse(newdata)  
            setNewContrat(newdata.contrat)
            setSucces(true)
            props.setSvData(newdata.contrat)
            setLoading(false)
            handleClickOpen()
          }else{
            setSucces(false)
            handleClickOpen()
            setLoading(false)
          }
        })
      }else{
        props.addSouscription(mesDonnees,user.authenticated).then(async (response)=>{
          if(response){
            //upload de documents
            let newdata =JSON.stringify(response,null,2)
            newdata =JSON.parse(newdata)
            const mesDocs=props.data.documents
            if(mesDocs && mesDocs.length!==0){
              let formData = new FormData();
              formData.append('id',newdata.contrat.id)
              mesDocs.forEach(element => {
                formData.append('documents[]',element)
              });
              const docUploaded = await uploadDocuments(formData)
            } 
            //id
            if(!props.data || !props.data.hasOwnProperty('id')){
              props.dispatch(registerField("adForm",'id','Field'));
            }
            if(newdata.contrat&& newdata.contrat.length!==0) props.updateField('id',`${newdata.contrat[0].id}`) 
            setNewContrat(newdata.contrat)
            setSucces(true)
            props.setSvData(newdata.contrat[0])
            setLoading(false)
            handleClickOpen()
          }else{
            setSucces(false)
            handleClickOpen()
            setLoading(false)
          }
        })
      }
      
    }    
    const [myErrors,setMyErrors]= useState(null)
    const [openAlert, setOpenAlert] = React.useState(false);
   
    const handleOpenAlert = () => {
      setOpenAlert(true);
    };
    const handleCloseAlert = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpenAlert(false);
      setMyErrors(null)
    };  
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
      if(succes){
        const propData = newContrat.length!==0?newContrat[0]:{}
        generateBulletinSouscription(propData,user.credentials)
        props.handleNext()
      }
    };
    const AlerteForSave =()=>{
      return(
        <div>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="xs"
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">{
            succes?(<AlertSucces icon={<CheckIcon fontSize="inherit" />} severity="success">
              <Typography variant='h4' color={green[400]}>Enregistrement effectué avec succès.</Typography> </AlertSucces>):(
              <AlertSucces severity="error"><Typography variant='h4' color={red[400]}>Le système a rencontré une ereur lors de l'enrégistrement.</Typography></AlertSucces>
            )
          }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {succes?(
            <SuccesButton onClick={handleClose} variant="contained"  className={classes.margin}>
            OK
          </SuccesButton>
          ):(
            <ErrorButton onClick={handleClose} variant="contained"  className={classes.margin}>
            OK
          </ErrorButton>
          )}
          
        </DialogActions>
      </Dialog>
    </div>
  

      )
    }
    const evaluationContrat = async (values) =>{
      setLoading(true)
      const {produit,assures,periodicite,duree,capitalSouscrit,dateEffet,primePrincipale}=values
      let garantieCalcul =[]
      let mesGaranties = []
      let totalPrime =0
      let surPrime =0
      let primeAss =0
      if(assures && assures.length===0){
        setLoading(false)
        setMyErrors({type:'warning',message:'Aucun assuré fourni'})
        handleOpenAlert()
        return false
      }
      if(!periodicite || periodicite==='') {
        setLoading(false)
        setMyErrors({type:'warning',message:'Veuillez renseigner la périodicité'})
        handleOpenAlert()
        return false
      }
      if(!duree || duree==='') {
        setLoading(false)
        setMyErrors({type:'warning',message:'Veuillez renseigner la durée'})
        handleOpenAlert()
        return false
      }
      

        switch (produit.CodeProduit) {
          case 'YKE_2008': case 'YKS_2008': case 'YKF_2008': case 'YKE_2018': case 'YKL_2004': case 'YKV_2004': case 'YKF_2004': case 'DOIHOO': case 'CAD_EDUCPLUS': case 'YKR_2021':case 'YKS_2018':
              //traitement des produit YAKO
             // assures.garantiesSouscrits =garantieCalcul
              if(!capitalSouscrit || capitalSouscrit==='') {
                setLoading(false)
                setMyErrors({type:'warning',message:'Veuillez renseigner le capital'})
                handleOpenAlert()
                return false
              }   
           
                      
              await Promise.all(assures.map(async (assure)=>{
                //création des paramètres de selection de la prime 
                const setParams = maGarantie=>{return new Promise((resolve,reject)=>{
                    const capitalRef = (maGarantie.estobligatoire===1)?capitalSouscrit:props.data[`capital-${maGarantie.CodeProduitGarantie}`] 
                    resolve({
                        CodeProduit:produit.CodeProduit,
                        CodeProduitFormule:produit.CodeProduitFormule,
                        codePeriodicite:periodicite,
                        duree:duree,
                        capitalSouscrit:capitalSouscrit,
                        dateEffet:dateEffet,
                        age:ageAssure(assure.dateNaissanceAssure),
                        codeGarantie:maGarantie.CodeProduitGarantie
                    })
                })}

              // création paramètre de selection de la prime de référence par produit
              const setParamsRef = maGarantie=>{return new Promise((resolve,reject)=>{
                const capitalRef2 = (maGarantie.estobligatoire===1)?capitalSouscrit:props.data[`capital-${maGarantie.CodeProduitGarantie}`] 
                resolve({
                    CodeProduit:produit.CodeProduit,
                    CodeProduitFormule:produit.CodeProduitFormule,
                    periodicite:periodicite,
                    duree:duree,
                    primePrincipale:primePrincipale,
                    capitalgarantie:parseInt(capitalRef2),
                    age:ageAssure(assure.dateNaissanceAssure),
                    codeGarantie:maGarantie.CodeProduitGarantie
                })
              })}
                if(assure.garantiesFacultatives && assure.garantiesFacultatives.length!==0){
                  garantieCalcul = assure.garantiesSouscrits.concat(assure.garantiesFacultatives.filter(item=>item.checked===true&&item.CodeProduitGarantie!=='SENIOR'))
                }else{
                  garantieCalcul = assure.garantiesSouscrits
                }
                
                return await Promise.all(garantieCalcul.map(async (garantie) => {
                    return getPrimeGarantie(await setParams(garantie)).then(async(data)=>{
                      if(data){
                        /*if(garantie.estprincipal===1){
                          primeAss = await getPrimeAccessoireProduit(produit.CodeProduit,periodicite)
                          primeAss = (primeAss && primeAss[0])? primeAss[0].montant :0   
                        } else{
                          primeAss=0
                        } */   
                        primeAss=0                    
                        garantie.prime = data[0].Prime
                        garantie.capitalGarantie = data[0].Capital
                        garantie.primeAccesoire =  primeAss
                        garantie.primeTotal = (parseInt(data[0].Prime)+parseInt(primeAss))
                        totalPrime+=(parseInt(data[0].Prime)+parseInt(primeAss))
                        mesGaranties.push(garantie)
                      }else{
                        const capitalRef3 = (garantie.estobligatoire===1)?capitalSouscrit:props.data[`capital-${garantie.CodeProduitGarantie}`] 
                        const autreGarantie = await getPrimeReference({
                          CodeProduit:produit.CodeProduit,
                          CodeProduitFormule:produit.CodeProduitFormule,
                          periodicite:periodicite,
                          duree:duree,
                          capitalgarantie:parseInt(capitalRef3),
                          age:ageAssure(assure.dateNaissanceAssure),
                          codeGarantie:garantie.CodeProduitGarantie
                      })
                      
                      if(autreGarantie && autreGarantie!==undefined && autreGarantie.length!==0 ){
                        garantie.prime = autreGarantie[0].Prime
                        garantie.capitalGarantie = autreGarantie[0].Capital
                        garantie.primeAccesoire =  0
                        garantie.primeTotal = (parseInt(autreGarantie[0].Prime))
                        totalPrime+=(parseInt(autreGarantie[0].Prime))
                        mesGaranties.push(garantie)
                      }else{
                        //affectation des prime pour la garantie senio YAKO Eternite
                         
                        if(garantie.CodeProduitGarantie ==='SENIOR' && props.data.hasOwnProperty(`prime-${garantie.CodeProduitGarantie}`)){
                         
                          garantie.prime = props.data[`prime-${garantie.CodeProduitGarantie}`]
                          garantie.capitalGarantie =""
                          garantie.primeAccesoire =  0
                          garantie.primeTotal = (parseInt(props.data[`prime-${garantie.CodeProduitGarantie}`]))    
                          totalPrime+=(parseInt(props.data[`prime-${garantie.CodeProduitGarantie}`]))
                          mesGaranties.push(garantie)
                        }
                      }
                        
                      }
                        return garantie
                    })

                })).then(mesgaranties=>{
                     assure.garantiesAssures = mesgaranties
                     return assure
                })
              })).then(async (mesAssures)=>{
                  //calcul de la primeprincipale
                  surPrime = await getPrimeAccessoireProduit(produit.CodeProduit,periodicite)
                  surPrime =(surPrime && surPrime[0])?surPrime[0].montant:0
                  if(!props.hasOwnProperty('primePrincipale')){
                    props.dispatch(registerField("adForm",'primePrincipale','Field'));
                   }
                  props.updateField('primePrincipale',totalPrime)
                  //assuré(e)s
                  if(!props.hasOwnProperty('assures')){
                    props.dispatch(registerField("adForm",'assures','Field'));
                   }
                  props.updateField('assures',mesAssures)
                 //surprime
                 if(!props.hasOwnProperty('surPrime')){
                  props.dispatch(registerField("adForm",'surPrime','Field'));
                 }
                 props.updateField('surPrime',surPrime)
                 //totalPrime
                 if(!props.hasOwnProperty('totalPrime')){
                  props.dispatch(registerField("adForm",'totalPrime','Field'));
                 }
                 props.updateField('totalPrime',(totalPrime))
                 //props.onSubmit()
                 setLoading(false)
              })
            break;
          case 'CADENCE':
            
            let partPrime
            if(!primePrincipale || primePrincipale==='') {
              setLoading(false)
              setMyErrors({type:'warning',message:'Veuillez renseigner la prime principale'})
              handleOpenAlert()
              return false
            }
            totalPrime=0
           
            //get capital référence
            
            await Promise.all(assures.map(async (assure)=>{
              //récupération de la garantie
              const setParamsCapital ={
                CodeProduit:produit.CodeProduit,
                CodeProduitFormule:produit.CodeProduitFormule,
                periodicite:periodicite,
                duree:duree,
                primePrincipale:primePrincipale,
                age:ageAssure(assure.dateNaissanceAssure)
              }
              getCapitalReference(setParamsCapital).then(data=>{
                
                if(!props.hasOwnProperty('capitalSouscrit')){
                  props.dispatch(registerField("adForm",'capitalSouscrit','Field'));
                }
                props.updateField('capitalSouscrit',data.capital3)
                if(!props.hasOwnProperty('capitalSouscrit5')){
                  props.dispatch(registerField("adForm",'capitalSouscrit5','Field'));
                }
                props.updateField('capitalSouscrit',data.capital5)

                if(!props.hasOwnProperty('cumulCotisation')){
                  props.dispatch(registerField("adForm",'cumulCotisation','Field'));
                }
                props.updateField('cumulCotisation',data.cumulcotisation)
              })

              const setParams = maGarantie=>{return new Promise((resolve,reject)=>{
                  resolve({
                      CodeProduit:produit.CodeProduit,
                      CodeProduitFormule:produit.CodeProduitFormule,
                      codePeriodicite:periodicite,
                      duree:duree,
                      dateEffet:dateEffet,
                      capitalSouscrit:props.data[`capital-${maGarantie.CodeProduitGarantie}`],
                      age:ageAssure(assure.dateNaissanceAssure ),
                      codeGarantie:maGarantie.CodeProduitGarantie
                  })
              })}
              
              if(assure.garantiesFacultatives && assure.garantiesFacultatives.length!==0){
                garantieCalcul = assure.garantiesSouscrits.concat(assure.garantiesFacultatives.filter(item=>item.checked===true&&item.CodeProduitGarantie!=='SENIOR'))
              }else{
                garantieCalcul = assure.garantiesSouscrits
              }
              
             
              partPrime =(parseInt(primePrincipale)/2)
              
              return await Promise.all(garantieCalcul.map(async (garantie) => {
                if(garantie.CodeProduitGarantie==='SUR'){
                  return getPrimeGarantie(await setParams(garantie)).then(async (data)=>{
                    if(data){
                      garantie.prime = data[0].Prime
                      garantie.primeAccesoire =  0
                      garantie.primeTotal = parseInt(data[0].Prime)
                      garantie.capitalGarantie = props.data[`capital-${garantie.CodeProduitGarantie}`]

                      totalPrime+=parseInt(data[0].Prime)
                      mesGaranties.push(garantie)
                      if(!props.hasOwnProperty('capitalSouscrit')){
                        props.dispatch(registerField("adForm",'capitalSouscrit','Field'));
                      }
                      props.updateField('capitalSouscrit',props.data[`capital-${garantie.CodeProduitGarantie}`])
                    }
                    return garantie
                  })
                }else if(garantie.CodeProduitGarantie==='DECESACC'){
                  const maprimeDecess =   parseInt(primePrincipale)-(parseInt(primePrincipale)*0.95)
                  garantie.prime =parseInt(maprimeDecess)
                  garantie.primeAccesoire =  0
                  garantie.primeTotal =parseInt(maprimeDecess)
                  totalPrime+=parseInt(maprimeDecess)
                  mesGaranties.push(garantie)
                  return garantie
                }else{
                  /*if(garantie.estprincipal===1){
                    primeAss =await getPrimeAccessoireProduit(produit.CodeProduit,periodicite)   
                    primeAss = (primeAss && primeAss.length!==0)? primeAss[0].montant :0
                  }else{
                    primeAss=0
                  }*/
                  primeAss=0  
                  garantie.prime = partPrime
                  garantie.primeAccesoire =0
                  garantie.primeTotal = (parseInt(partPrime))
                  partPrime =parseInt(partPrime)

                  totalPrime+=partPrime
                  mesGaranties.push(garantie)
                  return garantie
                } 
              })).then(mesgaranties=>{
                   assure.garantiesAssures = mesgaranties
                   return assure
              })

            })).then(async (mesAssures)=>{
                //calcul de la primeprincipale
                surPrime = await getPrimeAccessoireProduit(produit.CodeProduit,periodicite)
                surPrime =surPrime?surPrime[0].montant:0 
                //assuré(e)s
                if(!props.hasOwnProperty('assures')){
                  props.dispatch(registerField("adForm",'assures','Field'));
                 }
                props.updateField('assures',mesAssures)
               //surprime
               if(!props.hasOwnProperty('surPrime')){
                props.dispatch(registerField("adForm",'surPrime','Field'));
               }
               props.updateField('surPrime',surPrime)
               //totalPrime
               if(!props.hasOwnProperty('totalPrime')){
                props.dispatch(registerField("adForm",'totalPrime','Field'));
               }
               setLoading(false)
               props.updateField('totalPrime',(parseInt(totalPrime)+parseInt(surPrime)))
               //props.onSubmit()
            })
            break;
          case 'PFA_IND': case 'PFA_COL': case 'PFA_BNI':
          
            if(!primePrincipale || primePrincipale==='') {
              setLoading(false)
              setMyErrors({type:'warning',message:'Veuillez renseigner la prime principale'})
              handleOpenAlert()
              return false
            }

            await Promise.all(assures.map(async (assure)=>{
              
              const setParams = maGarantie=>{return new Promise((resolve,reject)=>{
                  resolve({
                      CodeProduit:produit.CodeProduit,
                      CodeProduitFormule:produit.CodeProduitFormule,
                      codePeriodicite:periodicite,
                      duree:duree,
                      dateEffet:dateEffet,
                      capitalSouscrit:props.data[`capital-${maGarantie.CodeProduitGarantie}`],
                      age:ageAssure(assure.dateNaissanceAssure ),
                      codeGarantie:maGarantie.CodeProduitGarantie
                  })
              })}
              
               
              if(assure.garantiesFacultatives && assure.garantiesFacultatives.length!==0){
                garantieCalcul = assure.garantiesSouscrits.concat(assure.garantiesFacultatives.filter(item=>item.checked===true&&item.CodeProduitGarantie!=='SENIOR'))
              }else{
                garantieCalcul = assure.garantiesSouscrits
              }
              
              return await Promise.all(garantieCalcul.map(async (garantie) => {
                if(garantie.CodeProduitGarantie==='SUR'){
                  return getPrimeGarantie(await setParams(garantie)).then(data=>{
                    if(data){
                      garantie.prime = data[0].Prime
                      garantie.primeAccesoire =  0
                      garantie.primeTotal = parseInt(data[0].Prime)
                      garantie.capitalGarantie = props.data[`capital-${garantie.CodeProduitGarantie}`]

                      totalPrime+=parseInt(data[0].Prime)
                      mesGaranties.push(garantie)
                      if(!props.hasOwnProperty('capitalSouscrit')){
                        props.dispatch(registerField("adForm",'capitalSouscrit','Field'));
                      }
                      props.updateField('capitalSouscrit',props.data[`capital-${garantie.CodeProduitGarantie}`])
                    
                    }
                    return garantie
                })
                }else if (garantie.CodeProduitGarantie==='PERF'){
                  /*if(garantie.estprincipal===1){
                    primeAss =await getPrimeAccessoireProduit(produit.CodeProduit,periodicite)   
                    primeAss = (primeAss && primeAss.length!==0)? primeAss[0].montant :0
                  } else{
                    primeAss=0
                  }*/
                  primeAss=0  
                  const maprimePerf =  (parseInt(primePrincipale)*(40/100))  
                  garantie.prime =maprimePerf
                  garantie.primeAccesoire =  0
                  garantie.primeTotal =maprimePerf
                  totalPrime+=parseInt(maprimePerf)
                  mesGaranties.push(garantie)  
                  return garantie
                }else{
                    /*if(garantie.estprincipal===1){
                      primeAss =await getPrimeAccessoireProduit(produit.CodeProduit,periodicite)   
                      primeAss = (primeAss && primeAss.length!==0)? primeAss[0].montant :0
                    } else{
                      primeAss=0
                    }*/
                    primeAss=0
                    const maprimeSecu = (parseInt(primePrincipale)*60/100)  
                    garantie.prime =maprimeSecu
                    garantie.primeAccesoire =  0
                    garantie.primeTotal =maprimeSecu
                    totalPrime+=maprimeSecu
                    mesGaranties.push(garantie)  
                }  
              })).then(mesgaranties=>{
                   assure.garantiesAssures = mesgaranties
                   return assure
              })

            })).then(async (mesAssures)=>{
                //calcul de la primeprincipale
                surPrime =0 //await getPrimeAccessoireProduit(produit.CodeProduit,periodicite) 
                //surPrime = surPrime?surPrime[0].montant:0
                //assuré(e)s
                if(!props.hasOwnProperty('assures')){
                  props.dispatch(registerField("adForm",'assures','Field'));
                 }
                props.updateField('assures',mesAssures)
               //surprime
               if(!props.hasOwnProperty('surPrime')){
                props.dispatch(registerField("adForm",'surPrime','Field'));
               }
               props.updateField('surPrime',surPrime)
               //totalPrime
               if(!props.hasOwnProperty('totalPrime')){
                props.dispatch(registerField("adForm",'totalPrime','Field'));
               }
               setLoading(true)
               props.updateField('totalPrime',(parseInt(totalPrime)))
              // props.onSubmit()
            })
            break;
          case 'PVRBNI':
                const {dureerente,montantrente,periodiciterente} =values
                if(!dureerente || dureerente==='') {
                    setLoading(false)
                    setMyErrors({type:'warning',message:'Veuillez renseigner la durée de la rente'})
                    handleOpenAlert()
                    return false
                }
                if(!montantrente || montantrente==='') {
                    setLoading(false)
                    setMyErrors({type:'warning',message:'Veuillez renseigner le montant de la rente'})
                    handleOpenAlert()
                    return false
                }
                if(!periodiciterente || periodiciterente==='') {
                    setLoading(false)
                    setMyErrors({type:'warning',message:'Veuillez renseigner la périodicite de la rente'})
                    handleOpenAlert()
                    return false
                }
                //determination de la prime net 1
                const dureeCotisation = duree
                const i_c = 0.035; const fg_c =0.05; const i_net_c = i_c*(1-fg_c)
                let per_c =12
                switch (periodicite) {
                    case 'M':
                        per_c=12
                        break;
                    case 'T':
                        per_c=4
                        break;
                    case 'S':
                        per_c=2
                        break;
                    default:
                        per_c=1
                        break;
                };
                const i_per =(Math.pow((1+i_net_c),(1/per_c)))-1
                let v_c = 1/(1+i_per)
                let d_c = i_per/(1+i_per)
                let valeur_a_c=(1-Math.pow(v_c,(dureeCotisation*per_c)))/d_c

                const tx_fg =0.04
                const tx_fa =0.05
                const tx_investi = 1-tx_fg-tx_fa
                const prime_net1 = (valeur_a_c*tx_investi)

                // determination prime rente
                const i_r = 0.035
                const fg_r =0.05
                const i_net_r = i_r*(1-fg_r)
                let per_r =12
                switch (periodiciterente) {
                    case 'M':
                        per_r=12
                        break;
                    case 'T':
                        per_r=4
                        break;
                    case 'S':
                        per_r=2
                        break;
                    default:
                        per_r=1
                        break;
                };
                const i_per_r =(Math.pow((1+i_net_r),(1/per_r)))-1
                const v_r = 1/(1+i_per_r)
                const d_r = i_per_r/(1+i_per_r)
                const dureeRentea = parseInt(dureerente)/12
                const dureeTotal = dureeRentea+parseInt(dureeCotisation)
                const valeur_a_r =(1-Math.pow(v_r,((dureeTotal-dureeCotisation)*per_r)))/d_r
                const vn = Math.pow(v_r,dureeCotisation*per_r)

                const tx_fg_r = (1+0.03)
                const primeRelle = Math.round(parseInt((parseInt(montantrente)*tx_fg_r*valeur_a_r*vn)/prime_net1))
                await Promise.all(assures.map(async (assure)=>{

                    if(assure.garantiesFacultatives && assure.garantiesFacultatives.length!==0){
                        garantieCalcul = assure.garantiesSouscrits.concat(assure.garantiesFacultatives.filter(item=>item.checked===true&&item.CodeProduitGarantie!=='SENIOR'))
                    }else{
                        garantieCalcul = assure.garantiesSouscrits
                    }

                    return await Promise.all(garantieCalcul.map(async (garantie) => {
                        garantie.prime = primeRelle
                        garantie.primeAccesoire =  0
                        garantie.primeTotal = parseInt(primeRelle)
                        garantie.capitalGarantie = 0

                        totalPrime+=primeRelle
                        return garantie

                    })).then(mesgaranties=>{
                        assure.garantiesAssures = mesgaranties
                        return assure
                    })

                })).then(async (mesAssures)=>{
                    //calcul de la primeprincipale

                    if(!props.hasOwnProperty('assures')){
                        props.dispatch(registerField("adForm",'primePrincipale','Field'));
                    }
                    props.updateField('primePrincipale',primeRelle)

                    surPrime = await getPrimeAccessoireProduit(produit.CodeProduit,periodicite)
                    surPrime =0; //surPrime?surPrime[0].montant:0
                    //assuré(e)s

                    if(!props.hasOwnProperty('assures')){
                        props.dispatch(registerField("adForm",'assures','Field'));
                    }
                    props.updateField('assures',mesAssures)
                    //surprime
                    if(!props.hasOwnProperty('surPrime')){
                        props.dispatch(registerField("adForm",'surPrime','Field'));
                    }
                    props.updateField('surPrime',surPrime)
                    //totalPrime
                    if(!props.hasOwnProperty('totalPrime')){
                        props.dispatch(registerField("adForm",'totalPrime','Field'));
                    }
                    setLoading(true)
                    props.updateField('totalPrime',(parseInt(totalPrime)+parseInt(surPrime)))
                    //props.onSubmit()
                })
            break;
          default:
            break;
        }
       dispatch({type:'STOP_LOADING_UI'})
    }
 
    useEffect(() => {
      if(initResume){
        dispatch({type:'LOADING_UI'})
        evaluationContrat(props.data)
      }
    }, [initResume])
    //rendu de la fenêtre
    return (
      <Fragment>
           {AlerteForSave()}
        {myErrors&&(
          <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={openAlert}
          autoHideDuration={6000}
          onClose={handleCloseAlert}
        >
          <Alert onClose={handleCloseAlert} severity={myErrors.type}>
            {myErrors.message}
        </Alert>
        </Snackbar>
        )}
        {!loading? (
          <>
            <Card className={classes.root} variant="outlined">

              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  ADHERENT
                </Typography>

                <Grid container spacing={2}>
                    <Grid item sm={6} xs={12}>
                    <Box m={1} className={classes.assureInfoRow}>
                      <Typography className={classes.title} color="textSecondary" gutterBottom>
                        Titre :
                      </Typography>
                      <Typography variant="subtitle1">
                          {civiliteSous}
                      </Typography>
                    </Box>
                    <Box m={1} className={classes.assureInfoRow}>
                      <Typography className={classes.title} color="textSecondary" gutterBottom>
                        Nom :
                      </Typography>
                      <Typography variant="subtitle1">
                          {nomSous}
                      </Typography>
                    </Box>
                    <Box m={1} className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                            Prénoms :
                        </Typography>
                        <Typography variant="subtitle1"> {prenomSous}</Typography>
                    </Box>
                    <Box m={1} className={classes.assureInfoRow}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Sexe :
                    </Typography>
                        <Typography variant="subtitle1">
                            {sexeSous?sexeSous:""}
                        </Typography>
                    </Box>
                    <Box m={1} className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                            Date de naissance :
                        </Typography>
                        <Typography variant="subtitle1"> {dateNaissanceSous?dayjs(dateNaissanceSous).format('DD/MM/YYYY'):""}</Typography>
                    </Box>
               
                    <Box m={1} className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                          Lieu de naissance:
                        </Typography>
                        <Typography variant="subtitle1"> {lieuNaissanceSous?lieuNaissanceSous:""}</Typography>
                    </Box>
                    <Box m={1} className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                          Lieu de résidence:
                        </Typography>
                        <Typography variant="subtitle1"> {lieuResidenceSous?lieuResidenceSous:""}</Typography>
                    </Box>
                    <Box m={1} className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                          N° {naturePiece} :
                        </Typography>
                        <Typography variant="subtitle1"> {pieceIdentiteSous?pieceIdentiteSous:""}</Typography>
                    </Box>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                    <Box m={1} className={classes.assureInfoRow}>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Profession :
                </Typography>
                    <Typography variant="subtitle1">
                        {professionSous?professionSous:""}
                    </Typography>
                </Box>
                <Box m={1} className={classes.assureInfoRow}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                       Secteur d'activité:
                    </Typography>
                    <Typography variant="subtitle1"> {employeurSous?employeurSous:""}</Typography>
                </Box>
                {/*<Box m={1} className={classes.assureInfoRow}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Pays :
                    </Typography>
                    <Typography variant="subtitle1"> {paysSous?paysSous:""}</Typography>
        </Box>*/}
                <Box m={1} className={classes.assureInfoRow}>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Email :
                </Typography>
                    <Typography variant="subtitle1">
                        {emailSous?emailSous:""}
                    </Typography>
                </Box>
                
               
                <Box m={1} className={classes.assureInfoRow}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                       Téléphone:
                    </Typography>
                    <Typography variant="subtitle1"> {telephoneSous?telephoneSous:''}</Typography>
                </Box>
                <Box m={1} className={classes.assureInfoRow}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                       Mobile :
                    </Typography>
                    <Typography variant="subtitle1"> {mobileSous?mobileSous:""} / {mobile2Sous?` / ${mobile2Sous}`:""}</Typography>
                </Box>
                <Box m={1} className={classes.assureInfoRow}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                       Adresse postale :
                    </Typography>
                    <Typography variant="subtitle1"> {adressePostaleSous?adressePostaleSous:""} </Typography>
                </Box>
                    </Grid>
                </Grid>
                
              </CardContent>
              
            </Card> 
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  CONDITIONS DE PAIEMENT DE LA PRIME & PERIODICITE
                </Typography>
                <Grid container>
                    <Grid item sm={6} xs={12}>
                    <Box m={1} className={classes.assureInfoRow}>
                      <Typography className={classes.title} color="textSecondary" gutterBottom>
                        Produit :
                      </Typography>
                          <Typography variant="subtitle1">
                              {produit&& produit.MonLibelle}
                          </Typography>
                    </Box>
                    <Box m={1} className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                            Date Effet:
                        </Typography>
                        <Typography variant="subtitle1"> {dayjs(dateEffet).format('DD/MM/YYYY')}</Typography>
                    </Box>
               
                    <Box m={1} className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                          Prime principale:
                        </Typography>
                        <Typography variant="subtitle1"> {
                        (user.credentials.devis==="" || user.credentials.devis==="XOF")?(primePrincipale?formatNumber(primePrincipale):""):(user.credentials.devis==='EURO'?`${primePrincipale&&(parseFloat(Number(primePrincipale)/655.77).toFixed(2)).replace(".",",")} €`:(primePrincipale?formatNumber(primePrincipale):""))
                        }</Typography>
                    </Box>
                    {(user && user.credentials["codepartenaire"]==='LLV')&&<Box m={1} className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                          Frais d'adhésion:
                        </Typography>
                        <Typography variant="subtitle1"> {primePrincipale?formatNumber(fraisadhesion):""}</Typography>
                    </Box>}
                    
                    <Box m={1} className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                          Echéance paiement :
                        </Typography>
                        <Typography variant="subtitle1"> {dayjs(dateEffet).add(duree,'year').format('DD/MM/YYYY')}</Typography>
                    </Box>
                    <Box m={1} className={classes.assureInfoRow}>
                      <Typography className={classes.title} color="textSecondary" gutterBottom>
                        Capital désiré :
                      </Typography>
                          <Typography variant="subtitle1">
                              {
                              (user.credentials.devis==="" || user.credentials.devis==="XOF")?(capitalSouscrit?formatNumber(parseInt(capitalSouscrit)):""):(user.credentials.devis==='EURO'?`${capitalSouscrit&&(parseFloat(Number(capitalSouscrit)/655.77).toFixed(2)).replace(".",",")} €`:(capitalSouscrit?formatNumber(parseInt(capitalSouscrit)):""))
                              }
                          </Typography>
                      </Box>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    
                    <Box m={1} className={classes.assureInfoRow}>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                          Mode paiement:
                        </Typography>
                        <Typography variant="subtitle1"> {modePaiement}</Typography>
                    </Box>
                    <Box m={1} className={classes.assureInfoRow}>
                      <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Organisme payeur :
                      </Typography>
                      <Typography variant="subtitle1"> {organismePayeur}</Typography>
                    </Box>
                    <Box m={1} className={classes.assureInfoRow}>
                      <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Agence :
                      </Typography>
                      <Typography variant="subtitle1"> {codeguichet}</Typography>
                    </Box>
                    <Box m={1} className={classes.assureInfoRow}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      N° Compte :
                    </Typography>
                    <Typography variant="subtitle1">
                        {numCompte}
                    </Typography>
                </Box>
                
                    </Grid>
                </Grid>
                {produit.CodeProduit==='PVRBNI'&&(<Grid container>
                      <Grid item sm={12} xs={12}><Typography variant='h4' color="textSecondary" >Rente</Typography></Grid>
                        <Grid item sm={4} xs={12}>
                          <Box m={1} className={classes.assureInfoRow}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                              Montant de la rente :
                            </Typography>
                            <Typography variant="subtitle1">
                                {(user.credentials.devis==="" || user.credentials.devis==="XOF")?(montantrente?formatNumber(montantrente):""):(user.credentials.devis==='EURO'?`${montantrente&&(parseFloat(Number(montantrente)/655.77).toFixed(2)).replace(".",",")} €`:(montantrente?formatNumber(montantrente):''))}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                          <Box m={1} className={classes.assureInfoRow}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                              Périodicité de la rente :
                            </Typography>
                            <Typography variant="subtitle1">
                                {periodiciterente==='M'?'Mensuelle':periodiciterente==='T'?'Trimestrielle':periodiciterente==='S'?'Semestrielle':periodiciterente==='A'?'Annuelle':'Inconnu'}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item sm={4} xs={12}>
                          <Box m={1} className={classes.assureInfoRow}>
                            <Typography className={classes.title} color="textSecondary" gutterBottom>
                              Durée de la rente en mois :
                            </Typography>
                            <Typography variant="subtitle1">
                                {dureerente}
                            </Typography>
                          </Box>
                        </Grid>
                   
                </Grid>)}
                
              </CardContent>
              
            </Card> 
            <Card className={classes.root} variant="outlined">

              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  ASSURE(E)S
                </Typography>
                <Grid container>
                    <Grid item sm={12} xs={12}>
                      {assures?(<TableContainer component={Paper}>
                          <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                              <TableRow>

                                <TableCell>Nom</TableCell>
                                <TableCell>Prénoms</TableCell>
                                <TableCell align="center">Né(e) le</TableCell>
                                <TableCell align="center">Lieu de naissance</TableCell>
                                <TableCell align="center">Lieu de residence</TableCell>
                                <TableCell align="right">Filiation</TableCell>
                                <TableCell align="right">Garanties</TableCell>
                                <TableCell align="center">Téléphone</TableCell>
                                <TableCell align="right">Email</TableCell>
                                <TableCell>N° CNI</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                            {assures.map(assure => (
                                <TableRow key={assure.nomAssure}>

                                  <TableCell component="th" scope="row">
                                    {assure.nomAssure}
                                  </TableCell>
                                  <TableCell align="right">{assure.prenomAssure}</TableCell>
                                  <TableCell align="right">{dayjs(assure.dateNaissanceAssure).format('DD/MM/YYYY')}</TableCell>
                                  <TableCell align="right">{assure.lieuNaissanceAssure}</TableCell>
                                  <TableCell align="right">{assure.lieuResidenceAssure}</TableCell>
                                  <TableCell align="right">{assure.filiationAssure}</TableCell>
                                  <TableCell align="right"><>
                                    {
                                      assure.garantiesSouscrits.length!==0 && assure.garantiesSouscrits.map((garantie)=>(<Typography key={garantie.CodeProduitGarantie}> - {garantie.MonLibelle +'\n'}</Typography> ))
                                    }
                                    {(assure.garantiesFacultatives && assure.garantiesFacultatives.length!==0) && assure.garantiesFacultatives.map((garantie)=>{
                                        if(garantie.checked===true && garantie.CodeProduitGarantie!=='SENIOR'){
                                          return(<Typography key={garantie.CodeProduitGarantie}> - {garantie.MonLibelle +'\n'}</Typography>)
                                        }else{
                                          return null
                                        }
                                      })}
                                    </>
                                    </TableCell>
                                  <TableCell align="right">{assure.telephoneAssure}</TableCell>
                                  <TableCell align="right">{assure.emailAssure}</TableCell>
                                  <TableCell component="th" scope="row">
                                    {assure.numeroCniAssure}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                      </TableContainer>):<Typography>Aucun aucun Assuré </Typography>}
                
                    </Grid>
                </Grid>
                
              </CardContent>
              
            </Card>   
            <Card className={classes.root} variant="outlined">
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  BENEFICIAIRE(S)
                </Typography>
                <Grid container>
                  <Grid container>
                        {(beneficiaireauterme && beneficiaireauterme.length!==0)&&<Grid item sm={12} xs={12} md={12} lg={12}>
                            <Grid item xs={12} sm={12} md={6} lg={16}>
                              <FormLabel component="h5">Au terme du contrat</FormLabel>
                              <Paper component="ul" className={classes.chipRoot}>
                                {beneficiaireauterme.map((option) => {
                                    let libelle =""
                                      switch (option) {
                                        case 'adherent':
                                          libelle="L'adherent"
                                          break;
                                        case 'conjoint':
                                          libelle="Le conjoint non divorcé, ni séparé de corps"
                                          break;
                                        case 'enfants':
                                          libelle="Les enfants nés et à naître"
                                          break;
                                        default:
                                          libelle="Autres"
                                          break;
                                      }
                                    return <li key={option}>
                                      <Chip
                                        icon={null}
                                        label={libelle}
                                        className={classes.chip}
                                      />
                                    </li>
                                })}
                              </Paper>
                            </Grid>
                          </Grid>           
                        }
                        {(beneficiaireaudeces && beneficiaireaudeces.length!==0)&&<Grid item sm={12} xs={12} md={12} lg={12}>
                            <Grid item xs={12} sm={12} md={6} lg={16}>
                              <FormLabel component="h6">En cas de décès avant le terme</FormLabel>
                              <Paper component="ul" className={classes.chipRoot}>
                                {beneficiaireaudeces.map((option) => {
                                    let libelle =""
                                    switch (option) {
                                      case 'conjoint':
                                        libelle="Le conjoint non divorcé, ni séparé de corps"
                                        break;
                                      case 'enfants':
                                        libelle="Les enfants nés et à naître"
                                        break;
                                      default:
                                        libelle="Autres"
                                        break;
                                    }
                                    return <li key={option}>
                                      <Chip
                                        icon={null}
                                        label={libelle}
                                        className={classes.chip}
                                      />
                                    </li>
                                  })}
                              </Paper>
                            </Grid>
                          </Grid>           
                        }
                    </Grid>              
                    <Grid item sm={12} xs={12} md={12} lg={12}>
                      {beneficiaires?(<TableContainer component={Paper}>
                          <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell>Nom</TableCell>
                                <TableCell>Prénoms</TableCell>
                                <TableCell align="center">Né(e) le</TableCell>
                                <TableCell align="center">Lieu de naissance</TableCell>
                                <TableCell align="center">Lieu de residence</TableCell>
                                <TableCell align="center">Filiation</TableCell>
                                <TableCell align="center">Téléphone</TableCell>
                                <TableCell align="center">Email</TableCell>
                                <TableCell align="center">Taux (%)</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                            {beneficiaires.map(beneficiaire => (
                                <TableRow key={beneficiaire.nomBeneficiaire}>
                                  <TableCell component="th" scope="row">
                                    {beneficiaire.nomBeneficiaire}
                                  </TableCell>
                                  <TableCell align="center">{beneficiaire.prenomBeneficiaire}</TableCell>
                                  <TableCell align="center">{dayjs(beneficiaire.dateNaissanceBeneficiaire).format('DD/MM/YYYY')}</TableCell>
                                  <TableCell align="center">{beneficiaire.lieuNaissanceBeneficiaire}</TableCell>
                                  <TableCell align="center">{beneficiaire.lieuResidenceBeneficiaire}</TableCell>
                                  <TableCell align="center">{beneficiaire.filiationBeneficiaire}</TableCell>
                                  <TableCell align="center">{beneficiaire.telephoneBeneficiaire}</TableCell>
                                  <TableCell align="center">{beneficiaire.emailBeneficiaire}</TableCell>
                                  <TableCell align="center">{beneficiaire.taux}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                      </TableContainer>):<Typography>Aucun aucun bénéficiaire </Typography>}
                
                    </Grid>
                </Grid>
                
              </CardContent>
              
            </Card>
            <Card className={classes.root} variant="outlined">

              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  GARANTIES & PRIMES
                </Typography>
                <Grid container>
                    <Grid item sm={12} xs={12}>
                      {assures?(<TableContainer component={Paper}>
                          <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                {(props.data.produit.CodeProduit!=='PVRBNI')&&  <TableCell>Nom</TableCell>}
                                <TableCell align="center">Garantie</TableCell>
                                {(props.data.produit.CodeProduit!=='PVRBNI')&&  <TableCell align="center">Capital</TableCell>}
                                <TableCell align="center">Prime</TableCell>
                                <TableCell align="center">Accessoire</TableCell>
                                <TableCell align="center">Périodicite</TableCell>
                                <TableCell align="right">Total prime</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                            {assures.map((assure)=>{
                              let garanties = assure.garantiesFacultatives?(assure.garantiesSouscrits.concat(assure.garantiesFacultatives.filter(item=>(item.checked===true&&item.CodeProduitGarantie!=='SENIOR')))):(assure.garantiesSouscrits)
                              let rows =null
                              
                               
                                if(garanties){
                                 return  garanties.map((garantie)=>(

                                     <TableRow key={garantie.CodeProduitGarantie}>
                                     {(props.data.produit.CodeProduit!=='PVRBNI')&&  <TableCell component="th" scope="row" align='left'>{`${assure.nomAssure}  ${assure.prenomAssure}`}</TableCell>}
                                      
                                      <TableCell align="center">{garantie.MonLibelle}</TableCell>
                                      {(props.data.produit.CodeProduit!=='PVRBNI')&&  <TableCell align="center">
                                        {
                                        (user.credentials.devis==="" || user.credentials.devis==="XOF")?(garantie.capitalGarantie? formatNumber(parseInt(garantie.capitalGarantie)):''):(user.credentials.devis==='EURO'?`${garantie.capitalGarantie&&(parseFloat(Number(garantie.capitalGarantie)/655.77).toFixed(2)).replace(".",",")} €`:(garantie.capitalGarantie? formatNumber(parseInt(garantie.capitalGarantie)):''))
                                      }</TableCell>}
                                      
                                      <TableCell align="center">{
                                      
                                      (user.credentials.devis==="" || user.credentials.devis==="XOF")?(garantie.prime?formatNumber(parseInt(garantie.prime)):''):(user.credentials.devis==='EURO'?`${garantie.prime&&(parseFloat(Number(garantie.prime)/655.77).toFixed(2)).replace(".",",")} €`:(garantie.prime?formatNumber(parseInt(garantie.prime)):''))
                                      }</TableCell>
                                      <TableCell align="center">{
                                      
                                      (user.credentials.devis==="" || user.credentials.devis==="XOF")?(garantie.primeAccesoire?formatNumber(parseInt(garantie.primeAccesoire)):''):(user.credentials.devis==='EURO'?`${garantie.primeAccesoire&&(parseFloat(Number(garantie.primeAccesoire)/655.77).toFixed(2)).replace(".",",")} €`:(garantie.primeAccesoire?formatNumber(parseInt(garantie.primeAccesoire)):''))
                                      }</TableCell>
                                      <TableCell align="center">{props.data.periodicite? (props.data.periodicite==='M'?'Mensuelle':(props.data.periodicite==='T'?'Trimestrielle':(props.data.periodicite==='S'?'Smestrielle':'Annuelle'))):''}</TableCell>
                                      <TableCell align="right">{
                                      
                                      (user.credentials.devis==="" || user.credentials.devis==="XOF")?(garantie.primeTotal? formatNumber(garantie.primeTotal):''):(user.credentials.devis==='EURO'?`${garantie.primeTotal&&(parseFloat(Number(garantie.primeTotal)/655.77).toFixed(2)).replace(".",",")} €`:(garantie.primeTotal? formatNumber(garantie.primeTotal):''))
                                      }</TableCell>
                                    </TableRow>
                    
                                  ))
                                }
                               
                              })
                            }
                             <TableRow>
                               <TableCell colSpan={5} align='center'><Typography variant='h5'>TOTAL</Typography></TableCell>
                               <TableCell colSpan={2} align='right'><Typography variant='h5'>{
                               
                               (user.credentials.devis==="" || user.credentials.devis==="XOF")?(totalPrime? formatNumber(totalPrime):''):(user.credentials.devis==='EURO'?`${totalPrime&&(parseFloat(Number(totalPrime)/655.77).toFixed(2)).replace(".",",")} €`:(totalPrime? formatNumber(totalPrime):''))
                               }</Typography></TableCell></TableRow>
                            </TableBody>
                          </Table>
                      </TableContainer>):<Typography>Aucun aucun garantie </Typography>}
                
                    </Grid>
                </Grid>
                
              </CardContent>
              
            </Card>
            <Card className={classes.root} variant="outlined">
            <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  ETAT DE SANTE DE L'ASSURE(E)
                </Typography>
                {['CADENCE','PFA_IND','PFA_COL','CAD_EDUCPLUS','YKR_2021'].includes(props.data.produit.CodeProduit)&&(<>
                  <GridContainer>
              <GridItem sm={4} xs={4}>
                <Box m={1} className={classes.assureInfoRow}>
                  <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Taille (cm) :
                  </Typography>
                  <Typography variant="subtitle1">
                    {`\xa0 ${taille && taille}`}
                  </Typography>
                </Box>
              </GridItem>
              <GridItem sm={4} xs={4}>
                <Box m={1} className={classes.assureInfoRow}>
                  <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Poids(Kg) :
                  </Typography>
                  <Typography variant="subtitle1">
                    {`\xa0 ${poids && poids}`}
                  </Typography>
                </Box>
              </GridItem>

            </GridContainer>
            <GridContainer>
              <GridItem sm={4} xs={4}>
                <Box m={1} className={classes.assureInfoRow}>
                  <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Fumeur ?
                  </Typography>
                  <Typography variant="subtitle1">
                    {`\xa0 ${fumezVous && fumezVous}`}
                  </Typography>
                </Box>
              </GridItem>
              {(fumezVous === 'Oui') && (
                <GridItem sm={4} xs={4}>
                  <Box m={1} className={classes.assureInfoRow}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Nombre cirgarette/jour ?
                    </Typography>
                    <Typography variant="subtitle1">
                      {`\xa0 ${nbCigaretteJour ? nbCigaretteJour : ""}`}

                    </Typography>
                  </Box>
                </GridItem>
              )}
            </GridContainer>
            <GridContainer>
              <GridItem sm={6} xs={6}>
                <Box m={1} className={classes.assureInfoRow}>
                  <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Buvez vous l'alcool ?
                  </Typography>
                  <Typography variant="subtitle1">
                    {buvezVous && (`\xa0 ${buvezVous && (buvezVous==="Non"?"Pas du tout":(buvezVous==="Partiel"?"A l'ocassion":"Régulièrement (au moins une fois par semaine)"))}`)}

                  </Typography>
                </Box>
              </GridItem>
              <GridItem sm={6} xs={6}>
                <Box m={1} className={classes.assureInfoRow}>
                  <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Vos distractions :
                  </Typography>
                  <Typography variant="subtitle1">
                    {distraction && (`\xa0 ${distraction}`)}

                  </Typography>
                </Box>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem sm={6} xs={6}>
                <Box m={1} className={classes.assureInfoRow}>
                  <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Etes-vous atteint d'une infirmité ?
                  </Typography>
                  <Typography variant="subtitle1">
                    {estInfirme && (`\xa0 ${estInfirme}`)}

                  </Typography>
                </Box>
              </GridItem>

              {estInfirme === 'Oui' && (
                <GridItem sm={6} xs={6}>
                  <Box m={1} className={classes.assureInfoRow}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Nature infirmité
                    </Typography>
                    <Typography variant="subtitle1">
                      {natureInfirmite && (`\xa0 ${natureInfirmite}`)}

                    </Typography>
                  </Box>
                </GridItem>
              )}
            </GridContainer>
            <GridContainer>
              <GridItem sm={6} xs={6}>
                <Box m={1} className={classes.assureInfoRow}>
                  <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Etes-vous en arrêt de travail?
                  </Typography>
                  <Typography variant="subtitle1">
                    {estEnArretTravail && (`\xa0 ${estEnArretTravail}`)}

                  </Typography>
                </Box>
              </GridItem>
              {estEnArretTravail === 'Oui' && (
                <>
                  <GridItem sm={3} xs={3}>
                    <Box m={1} className={classes.assureInfoRow}>
                      <Typography className={classes.title} color="textSecondary" gutterBottom>
                        Date d'arrêt
                      </Typography>
                      <Typography variant="subtitle1">
                        {dateArretTravail && (`\xa0 ${dayjs(dateArretTravail).add(duree, 'year').format('DD/MM/YYYY')}`)}

                      </Typography>
                    </Box>
                  </GridItem>
                  <GridItem sm={3} xs={3}>
                    <Box m={1} className={classes.assureInfoRow}>
                      <Typography className={classes.title} color="textSecondary" gutterBottom>
                        Motif de l'arrêt
                      </Typography>
                      <Typography variant="subtitle1">
                        {motifArret && (`\xa0 ${motifArret}`)}
                      </Typography>
                    </Box>
                  </GridItem>
                  <GridItem sm={3} xs={3}>
                    <Box m={1} className={classes.assureInfoRow}>
                      <Typography className={classes.title} color="textSecondary" gutterBottom>
                        Date réprise prévu
                      </Typography>
                      <Typography variant="subtitle1">
                        {dateRepriseTravail && (`\xa0 ${dayjs(dateRepriseTravail).add(duree, 'year').format('DD/MM/YYYY')}`)}
                      </Typography>
                    </Box>
                  </GridItem>
                  <GridItem sm={4} xs={4}>
                    <Box m={1} className={classes.assureInfoRow}>
                      <Typography className={classes.title} color="textSecondary" gutterBottom>
                        Cause de l'arrêt
                      </Typography>
                      <Typography variant="subtitle1">
                        {causeArretTravail && (`\xa0 ${causeArretTravail}`)}
                      </Typography>
                    </Box>
                  </GridItem>
                  <GridItem sm={3} xs={3}>
                    <Box m={1} className={classes.assureInfoRow}>
                      <Typography className={classes.title} color="textSecondary" gutterBottom>
                        Date de la cause
                      </Typography>
                      <Typography variant="subtitle1">
                        {dateCauseTravail && (`\xa0 ${dayjs(dateCauseTravail).add(duree, 'year').format('DD/MM/YYYY')}`)}
                      </Typography>
                    </Box>
                  </GridItem>
                </>
              )}
            </GridContainer>
            <Divider />
                </>)}
            
            {questionaires && (
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">#</TableCell>
                    <TableCell align="center">Questions</TableCell>
                    <TableCell align="center">Reponse</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {questionaires.map((question) => (
                    <TableRow key={question.id}>
                      <TableCell align="center" component="td" scope="row">{question.id}</TableCell>
                      <TableCell align="left" component="td" scope="row">{question.nature}</TableCell>
                      <TableCell align="left" component="td" scope="row">{props.data[`nature-${question.id}`]}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </CardContent>

        </Card>
            <Divider component="hr" className={classes.hTrait}/>
            
            <Grid container direction="row" justify="space-between" alignItems="center">        
             
             <Button type="button" className={classes.btnback}  onClick={props.handleBack}>
               Retour
             </Button>
             <Button className={classes.btnnext} type="submit" disabled ={loadingui} onClick={handleSubmit(saveSouscription)}>
              {loadingui?<CircularProgress size={30} color="secondary" />:<div>Valider
              <NavigateNextOutlinedIcon /></div>}
            </Button>
         </Grid>
          </>
        ):(
          <ModalLoading myopen={loading} />
        )}
       
            
      </Fragment>
      
    )

}
const selector = formValueSelector('adForm')

const mapStateToProps = (state) => ({
  user:state.user,
  api: state.api,
  UI:state.UI,
  data:getFormValues('adForm')(state)
})  
  const updateField =(field, data)=>(dispatch)=>dispatch(change('adForm', field, data))
  const mapActionsToProps = {
    updateField,
    addSouscription,
    updateProposition
  }
  SouscriptionFicheresume = withStyles(styles)(SouscriptionFicheresume)


  export default connect(mapStateToProps,mapActionsToProps)(reduxForm({form: 'adForm',destroyOnUnmount: false,})(SouscriptionFicheresume))
