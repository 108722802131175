import React, { Fragment, useState, useCallback, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles';
import { reduxForm, formValueSelector, change, registerField, getFormValues } from 'redux-form'
import { connect, useDispatch } from 'react-redux';
import { Grid, Box } from '@material-ui/core'
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Button from "components/CustomButtons/Button.js";
import NavigateNextOutlinedIcon from '@material-ui/icons/NavigateNextOutlined';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import 'jspdf-autotable'
import dayjs from 'dayjs'
import MuiAlert from '@material-ui/lab/Alert';
import { ageAssure } from 'redux/actions/userActions'
import { addSouscription, updateProposition, uploadDocuments, getPrimeGarantie, getPrimeAccessoireProduit, getCapitalReference, getPrimeReference } from 'redux/actions/apiActions'
import { generateBulletinSouscription } from 'pages/Banking/Propositions/EditionBulletin'
import Snackbar from '@material-ui/core/Snackbar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Slide from '@material-ui/core/Slide';
import red from '@material-ui/core/colors/red'
import green from '@material-ui/core/colors/green'
import AlertSucces from '@material-ui/lab/Alert';
import CheckIcon from '@material-ui/icons/Check';
import { formatNumber } from 'application'
import ModalLoading from 'components/ModalLoading'
import Chip from '@material-ui/core/Chip';
import FormLabel from '@material-ui/core/FormLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import { primePvrPremium } from 'application';
const styles = (theme) => ({
  root: {
    marginBottom: 10,
    padding: 10
  },
 
  assureInfoRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  chipRoot: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(1),
    marginBottom: 10,
  },
  chip: {
    margin: theme.spacing(1),
  },
  btnback:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    backgroundColor:"#f9b233",
    paddingRight:20,
    paddingLeft:20,
    paddingTop:10,
    paddingBottom:10,
    color:'#ffffff',
    "&:hover":{
      backgroundColor:"#3c3c3b",
    },
    borderRadius:10,
  },
  btnnext:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    backgroundColor:theme.palette.primary.main,
    paddingRight:20,
    paddingLeft:20,
    paddingTop:10,
    paddingBottom:10,
    color:'#ffffff',
    "&:hover":{
      backgroundColor:"#f9b233",
    },
    borderRadius:10,
  },
  hTrait:{
    marginTop:20,
    marginBottom:20
},
})
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
//button succès
const SuccesButton = withStyles((theme) => ({
  root: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
}))(Button);
//bouton error
const ErrorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
}))(Button);

function SouscriptionFicheresume(props) {
  //props
  const { classes,
    data: { civiliteSous, nomSous, prenomSous, sexeSous, dateNaissanceSous, lieuNaissanceSous, pieceIdentiteSous, naturePiece,
      lieuResidenceSous, professionSous, employeurSous, paysSous, emailSous, adressePostaleSous, telephoneSous, telephone2Sous,
      mobileSous, mobile2Sous, produit, assures, beneficiaires, modePaiement, organismePayeur, dateEffet, duree, periodicite,
      agence, numCompte, capitalSouscrit, primePrincipale, totalPrime, periodiciterente, montantrente, dureerente, codeagent, nomagent, beneficiaireauterme, beneficiaireaudeces },
    user,
    handleSubmit,
    UI: { loading },
    initResume
  } = props

  useEffect(() => {
    if (props.data.hasOwnProperty('civiliteSous')) {
      const valueurSexe = (['Madame', 'Mademoiselle'].includes(civiliteSous)) ? "F" : "M"
      props.dispatch(registerField("adForm", "sexeSous", 'Field'));
      props.updateField("sexeSous", valueurSexe);
    }
  });

  const [succes, setSucces] = useState(false)
  const [newContrat, setNewContrat] = useState(null)
  const dispatch = useDispatch()
  const [loadingui, setLoading] = React.useState(false)

  //savegade de la souscription
  const saveSouscription = (values) => {
    setLoading(true)
    let mesDonnees
    mesDonnees = JSON.stringify(values, null, 2)
    mesDonnees = JSON.parse(mesDonnees)
    mesDonnees.etat = 1
    if (mesDonnees.hasOwnProperty('beneficiaireauterme')) {
      mesDonnees.beneficiaireauterme = mesDonnees.beneficiaireauterme.join(";");
    }
    if (mesDonnees.hasOwnProperty('beneficiaireaudeces')) {
      mesDonnees.beneficiaireaudeces = mesDonnees.beneficiaireaudeces.join(";");
    }
 
    const newAssure = []
    {
      assures.map((assure) => {
        newAssure.push(assure)
      })
    }
    mesDonnees.assures = newAssure
    if (mesDonnees.hasOwnProperty('mode') && mesDonnees.mode === 'UPDATE') {
      mesDonnees.typeop = 'UP'
      props.updateProposition(mesDonnees).then(async (response) => {
        if (response) {
          let newdata = JSON.stringify(response, null, 2)
          newdata = JSON.parse(newdata)
          setNewContrat(newdata.contrat)
          setSucces(true)
          props.setSvData(newdata.contrat)
          setLoading(false)
          handleClickOpen()
        } else {
          setSucces(false)
          handleClickOpen()
          setLoading(false)
        }
      })

    } else {

      props.addSouscription(mesDonnees, user.authenticated).then(async (response) => {
        if (response) {
          //upload de documents
          let newdata = JSON.stringify(response, null, 2)
          newdata = JSON.parse(newdata)

          if (!props.data || !props.data.hasOwnProperty('id')) {
            props.dispatch(registerField("adForm", 'id', 'Field'));
          }
          if (newdata.contrat && newdata.contrat.length !== 0) props.updateField('id', `${newdata.contrat[0].id}`)

          setNewContrat(newdata.contrat)
          setSucces(true)
          props.setSvData(newdata.contrat[0])
          setLoading(false)
          handleClickOpen()
        } else {
          setSucces(false)
          handleClickOpen()
          setLoading(false)
        }
      })
    }

  }

  const [myErrors, setMyErrors] = useState(null)
  const [openAlert, setOpenAlert] = React.useState(false);
  const handleOpenAlert = () => {
    setOpenAlert(true);
  };
  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
    setMyErrors(null)
  };

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    if (succes) {
      const propData = Array.isArray(newContrat) ? newContrat.length !== 0 ? newContrat[0] : {} : newContrat
      generateBulletinSouscription(propData, user.credentials)
      props.handleNext()
    }
  };
  const AlerteForSave = () => {
    return (
      <div>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          disableEscapeKeyDown
          maxWidth="xs"
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">{
              succes ? (<AlertSucces icon={<CheckIcon fontSize="inherit" />} severity="success">
                <Typography variant='h4' color={green[400]}>Enregistrement effectué avec succès.</Typography> </AlertSucces>) : (
                <AlertSucces severity="error"><Typography variant='h4' color={red[400]}>Le système a rencontré une ereur lors de l'enrégistrement.</Typography></AlertSucces>
              )
            }
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {succes ? (
              <SuccesButton onClick={handleClose} variant="contained" color="primary" className={classes.margin}>
                OK
              </SuccesButton>
            ) : (
              <ErrorButton onClick={handleClose} variant="contained" color="primary" className={classes.margin}>
                OK
              </ErrorButton>
            )}

          </DialogActions>
        </Dialog>
      </div>
    )
  }

  //évaluation de la prime
  const evaluationContrat = async (values) => {
    setLoading(true)
  
    const { produit, assures, periodicite, duree, capitalSouscrit, dateEffet, primePrincipale } = values
    let garantieCalcul = []
    let mesGaranties = []
    let totalPrime = 0
    let surPrime = 0
    let primeAss = 0

    if (assures && assures.length === 0) {
      setLoading(false)
      setMyErrors({ type: 'warning', message: 'Aucun assuré fourni' })
      handleOpenAlert()
      return false
    }
    if (!periodicite || periodicite === '') {
      setLoading(false)
      setMyErrors({ type: 'warning', message: 'Veuillez renseigner la périodicité' })
      handleOpenAlert()
      return false
    }
    if (!duree || duree === '') {
      setLoading(false)
      setMyErrors({ type: 'warning', message: 'Veuillez renseigner la durée' })
      handleOpenAlert()
      return false
    }


    var nbEnfant = 1

    async function nombreEnfant() {
      return Promise.resolve(nbEnfant++);
    }
   
    switch (produit.CodeProduit) {
      case 'YKL_2004': case 'YKV_2004': case 'YKF_2004': case 'YKE_2018': 
        //traitement des produit YAKO BANQUE ASSURANCE
        // assures.garantiesSouscrits =garantieCalcul
        if (!capitalSouscrit || capitalSouscrit === '') {
          setLoading(false)
          setMyErrors({ type: 'warning', message: 'Veuillez renseigner le capital' })
          handleOpenAlert()
          return false
        }

        totalPrime = 0
       
        await Promise.all(assures.map(async (assure) => {
          //création des paramètres de selection de la prime 
          const setParams = maGarantie => {
            return new Promise((resolve, reject) => {
              const capitalRef = (maGarantie.estobligatoire === 1) ? capitalSouscrit : props.data[`capital-${maGarantie.CodeProduitGarantie}`]
              resolve({
                CodeProduit: produit.CodeProduit,
                codePeriodicite: periodicite,
                CodeProduitFormule:!['YKV_2004','YKL_2004','YKF_2004'].includes(produit.CodeProduit) ? produit.CodeProduitFormule:null,
                duree: duree,
                capitalSouscrit: capitalSouscrit,
                dateEffet: dateEffet,
                age: ageAssure(assure.dateNaissanceAssure),
                codeGarantie: maGarantie.CodeProduitGarantie
              })
            })
          }
          let primeCap = 0

          if (assure.garantiesFacultatives && assure.garantiesFacultatives.length !== 0) {
            let qalifGarantie = []
            assure.garantiesSouscrits.forEach(cg => {
              if(cg){
                if (assure.garantiesFacultatives.findIndex(item => (item?.CodeProduitGarantie === cg.CodeProduitGarantie && cg.checked===true)) === -1) {
                  qalifGarantie.push(cg)
                }
              }
            })
            garantieCalcul = qalifGarantie.concat(assure.garantiesFacultatives.filter(item => item.checked === true))
          } else {
            garantieCalcul = assure.garantiesSouscrits
          }
         
          return await Promise.all(garantieCalcul.map(async (garantie) => {

            var localGaranties = { ...garantie }
          
            return getPrimeGarantie(await setParams(localGaranties)).then(async (data) => {
              
              if (data) {
                if (localGaranties.estprincipal == 1) {
                  primeAss = await getPrimeAccessoireProduit(produit.CodeProduit, periodicite)
                  primeAss = (primeAss && primeAss[0]) ? primeAss[0].montant : 0
                } else {
                  primeAss = 0
                }
                localGaranties.prime = data[0].Prime
                localGaranties.capitalGarantie = data[0].Capital
                localGaranties.primeAccesoire = primeAss
                localGaranties.primeTotal = (parseInt(data[0].Prime) + parseInt(primeAss))
                totalPrime += (parseInt(data[0].Prime) + parseInt(primeAss))
                mesGaranties.push(localGaranties)
                return localGaranties
              } else {
                const capitalRef3 = (localGaranties.estobligatoire === 1) ? capitalSouscrit : props.data[`capital-${localGaranties.CodeProduitGarantie}`]
                const autreGarantie = await getPrimeReference({
                  CodeProduit: produit.CodeProduit,
                  periodicite: periodicite,
                  duree: duree,
                  capitalgarantie: parseInt(capitalRef3),
                  age: ageAssure(assure.dateNaissanceAssure),
                  codeGarantie: localGaranties.CodeProduitGarantie
                })
                if (produit.CodeProduit === 'YKF_2004' && localGaranties.CodeProduitGarantie === 'OBSQCONJT') {
                  localGaranties.prime = 0
                  localGaranties.capitalGarantie = 0
                  localGaranties.primeAccesoire = 0
                  localGaranties.primeTotal = 0

                  mesGaranties.push(localGaranties)
                  return localGaranties
                }
                if (autreGarantie && autreGarantie !== undefined && autreGarantie.length !== 0) {
                  localGaranties.prime = autreGarantie[0].Prime
                  localGaranties.capitalGarantie = autreGarantie[0].Capital
                  localGaranties.primeAccesoire = 0
                  localGaranties.primeTotal = (parseInt(autreGarantie[0].Prime))
                  totalPrime += (parseInt(autreGarantie[0].Prime))
                  mesGaranties.push(localGaranties)
                  return localGaranties
                } else if (produit.CodeProduit === 'YKF_2004' && localGaranties.CodeProduitGarantie === 'OBSQENFT') {
                  let r = await nombreEnfant(nbEnfant)
                  if (user.credentials.codepartenaire === 'AFC') {
                    if (r > 5) {
                      primeCap = (parseInt(capitalSouscrit) === 430000 ? 1200 : (parseInt(capitalSouscrit) === 750000 ? 1800 : 2400))
                    } else {
                      primeCap = 0
                    }
                  }
                  if (user.credentials.codepartenaire === '092') {
                    if (r > 4) {
                      primeCap = (parseInt(capitalSouscrit) === 430000 ? 100 : (parseInt(capitalSouscrit) === 750000 ? 150 : 200))
                    } else {
                      primeCap = 0
                    }
                  }

                  localGaranties.prime = primeCap
                  localGaranties.capitalGarantie = ""
                  localGaranties.primeAccesoire = 0
                  localGaranties.primeTotal = primeCap
                  totalPrime += (parseInt(primeCap))
                  mesGaranties.push(localGaranties)
                  return localGaranties
                } else {
                
                  if (localGaranties.CodeProduitGarantie === 'SENIOR' && props.data.hasOwnProperty(`prime-${localGaranties.CodeProduitGarantie}`)) {
                    localGaranties.prime = props.data[`prime-${localGaranties.CodeProduitGarantie}`]
                    localGaranties.capitalGarantie = 0
                    localGaranties.primeAccesoire = 0
                    localGaranties.primeTotal = (parseInt(props.data[`prime-${localGaranties.CodeProduitGarantie}`]))
                    totalPrime += (parseInt(props.data[`prime-${localGaranties.CodeProduitGarantie}`]))
                    mesGaranties.push(localGaranties)
                    return localGaranties
                  }
                }
              }
            })
          })).then(mesgaranties => {
            
            assure.garantiesAssures = [...mesgaranties]
            assure.garantiesSouscrits = mesgaranties
            return assure
          }).catch(err=>{
            console.log(err);
          })
        })).then(async (mesAssures) => {
          //calcul de la primeprincipale
          surPrime = await getPrimeAccessoireProduit(produit.CodeProduit, periodicite)
          surPrime = (surPrime && surPrime[0]) ? surPrime[0].montant : 0
          if (!props.hasOwnProperty('primePrincipale')) {
            props.dispatch(registerField("adForm", 'primePrincipale', 'Field'));
          }
          props.updateField('primePrincipale', parseInt(Math.round(totalPrime)))
          //assuré(e)s
          if (!props.hasOwnProperty('assures')) {
            props.dispatch(registerField("adForm", 'assures', 'Field'));
          }
          props.updateField('assures', mesAssures)
          //surprime
          if (!props.hasOwnProperty('surPrime')) {
            props.dispatch(registerField("adForm", 'surPrime', 'Field'));
          }
          props.updateField('surPrime', parseInt(Math.round(surPrime)))
          //totalPrime
          if (!props.hasOwnProperty('totalPrime')) {
            props.dispatch(registerField("adForm", 'totalPrime', 'Field'));
          }
          props.updateField('totalPrime', parseInt(Math.round(totalPrime)))
          //props.onSubmit()
          setLoading(false)
        })
        break;
      case 'CADENCE':

        let partPrime
        let nbGarantieObligatoire
        if (!primePrincipale || primePrincipale === '') {
          setLoading(false)
          setMyErrors({ type: 'warning', message: 'Veuillez renseigner la prime principale' })
          handleOpenAlert()
          return false
        }


        //get capital référence

        await Promise.all(assures.map(async (assure) => {
          //récupération de la garantie
          const setParamsCapital = {
            CodeProduit: produit.CodeProduit,
            periodicite: periodicite,
            duree: duree,
            primePrincipale: primePrincipale,
            age: ageAssure(assure.dateNaissanceAssure)
          }
          getCapitalReference(setParamsCapital).then(data => {

            if (!props.hasOwnProperty('capitalSouscrit')) {
              props.dispatch(registerField("adForm", 'capitalSouscrit', 'Field'));
            }
            props.updateField('capitalSouscrit', data.capital3)
            if (!props.hasOwnProperty('capitalSouscrit5')) {
              props.dispatch(registerField("adForm", 'capitalSouscrit5', 'Field'));
            }
            props.updateField('capitalSouscrit', data.capital5)

            if (!props.hasOwnProperty('cumulCotisation')) {
              props.dispatch(registerField("adForm", 'cumulCotisation', 'Field'));
            }
            props.updateField('cumulCotisation', data.cumulcotisation)
          })

          const setParams = maGarantie => {
            return new Promise((resolve, reject) => {
              resolve({
                CodeProduit: produit.CodeProduit,
                codePeriodicite: periodicite,
                duree: duree,
                dateEffet: dateEffet,
                capitalSouscrit: props.data[`capital-${maGarantie.CodeProduitGarantie}`],
                age: ageAssure(assure.dateNaissanceAssure),
                codeGarantie: maGarantie.CodeProduitGarantie
              })
            })
          }

          if (assure.garantiesFacultatives && assure.garantiesFacultatives.length !== 0) {
            garantieCalcul = assure.garantiesSouscrits.concat(assure.garantiesFacultatives.filter(item => item.checked === true && item.CodeProduitGarantie !== 'SENIOR'))
          } else {
            garantieCalcul = assure.garantiesSouscrits
          }

          nbGarantieObligatoire = garantieCalcul.filter(item => item.estobligatoire === 1).length
          partPrime = (parseInt(primePrincipale) / nbGarantieObligatoire)
          return await Promise.all(garantieCalcul.map(async (garantie) => {
            if (garantie.CodeProduitGarantie === 'SUR') {
              return getPrimeGarantie(await setParams(garantie)).then(async (data) => {
                if (data) {
                  garantie.prime = data[0].Prime
                  garantie.primeAccesoire = 0
                  garantie.primeTotal = parseInt(data[0].Prime)
                  garantie.capitalGarantie = props.data[`capital-${garantie.CodeProduitGarantie}`]

                  totalPrime += parseInt(data[0].Prime)
                  mesGaranties.push(garantie)
                  if (!props.hasOwnProperty('capitalSouscrit')) {
                    props.dispatch(registerField("adForm", 'capitalSouscrit', 'Field'));
                  }
                  props.updateField('capitalSouscrit', props.data[`capital-${garantie.CodeProduitGarantie}`])
                }
                return garantie
              })
            } else if (garantie.CodeProduitGarantie === 'DECESACC') {
              const maprimeDecess = parseInt(primePrincipale) - (parseInt(primePrincipale) * 0.95)
              garantie.prime = maprimeDecess
              garantie.primeAccesoire = 0
              garantie.primeTotal = maprimeDecess
              totalPrime += maprimeDecess
              mesGaranties.push(garantie)
              return garantie
            } else {
              if (garantie.estprincipal === 1) {
                primeAss = await getPrimeAccessoireProduit(produit.CodeProduit, periodicite)
                primeAss = (primeAss && primeAss.length !== 0) ? primeAss[0].montant : 0
              } else {
                primeAss = 0
              }
              garantie.prime = partPrime
              garantie.primeAccesoire = primeAss
              garantie.primeTotal = (parseInt(partPrime) + parseInt(primeAss))

              totalPrime += parseInt(partPrime) + primeAss
              mesGaranties.push(garantie)
              return garantie
            }
          })).then(mesgaranties => {
            assure.garantiesAssures = mesgaranties
            return assure
          })

        })).then(async (mesAssures) => {
          //calcul de la primeprincipale
          surPrime = await getPrimeAccessoireProduit(produit.CodeProduit, periodicite)
          surPrime = surPrime ? surPrime[0].montant : 0
          //assuré(e)s
          if (!props.hasOwnProperty('assures')) {
            props.dispatch(registerField("adForm", 'assures', 'Field'));
          }
          props.updateField('assures', mesAssures)
          //surprime
          if (!props.hasOwnProperty('surPrime')) {
            props.dispatch(registerField("adForm", 'surPrime', 'Field'));
          }
          props.updateField('surPrime', Math.round(surPrime))
          //totalPrime
          if (!props.hasOwnProperty('totalPrime')) {
            props.dispatch(registerField("adForm", 'totalPrime', 'Field'));
          }
          setLoading(false)
          props.updateField('totalPrime', Math.round(totalPrime + surPrime))
          props.onSubmit()
        })
        break;
      case 'PFA_IND': case 'PFA_COL': case 'PFA_BNI':

        if (!primePrincipale || primePrincipale === '') {
          setLoading(false)
          setMyErrors({ type: 'warning', message: 'Veuillez renseigner la prime principale' })
          handleOpenAlert()
          return false
        }

        await Promise.all(assures.map(async (assure) => {

          const setParams = maGarantie => {
            return new Promise((resolve, reject) => {
              resolve({
                CodeProduit: produit.CodeProduit,
                codePeriodicite: periodicite,
                duree: duree,
                dateEffet: dateEffet,
                capitalSouscrit: props.data[`capital-${maGarantie.CodeProduitGarantie}`],
                age: ageAssure(assure.dateNaissanceAssure),
                codeGarantie: maGarantie.CodeProduitGarantie
              })
            })
          }


          if (assure.garantiesFacultatives && assure.garantiesFacultatives.length !== 0) {
            garantieCalcul = assure.garantiesSouscrits.concat(assure.garantiesFacultatives.filter(item => item.checked === true && item.CodeProduitGarantie !== 'SENIOR'))
          } else {
            garantieCalcul = assure.garantiesSouscrits
          }

          return await Promise.all(garantieCalcul.map(async (garantie) => {
            if (garantie.CodeProduitGarantie === 'SUR') {
              return getPrimeGarantie(await setParams(garantie)).then(data => {
                if (data) {
                  garantie.prime = data[0].Prime
                  garantie.primeAccesoire = 0
                  garantie.primeTotal = parseInt(data[0].Prime)
                  garantie.capitalGarantie = props.data[`capital-${garantie.CodeProduitGarantie}`]

                  totalPrime += parseInt(data[0].Prime)
                  mesGaranties.push(garantie)
                  if (!props.hasOwnProperty('capitalSouscrit')) {
                    props.dispatch(registerField("adForm", 'capitalSouscrit', 'Field'));
                  }
                  props.updateField('capitalSouscrit', props.data[`capital-${garantie.CodeProduitGarantie}`])

                }
                return garantie
              })
            } else if (garantie.CodeProduitGarantie === 'PERF') {
              if (garantie.estprincipal === 1) {
                primeAss = await getPrimeAccessoireProduit(produit.CodeProduit, periodicite)
                primeAss = (primeAss && primeAss.length !== 0) ? primeAss[0].montant : 0
              } else {
                primeAss = 0
              }
              const maprimePerf = (parseInt(primePrincipale) * (40 / 100))
              garantie.prime = maprimePerf
              garantie.primeAccesoire = primeAss
              garantie.primeTotal = maprimePerf
              totalPrime += maprimePerf
              mesGaranties.push(garantie)
              return garantie
            } else {
              if (garantie.estprincipal === 1) {
                primeAss = await getPrimeAccessoireProduit(produit.CodeProduit, periodicite)
                primeAss = (primeAss && primeAss.length !== 0) ? primeAss[0].montant : 0
              } else {
                primeAss = 0
              }
              const maprimeSecu = (parseInt(primePrincipale) * 60 / 100)
              garantie.prime = maprimeSecu
              garantie.primeAccesoire = primeAss
              garantie.primeTotal = maprimeSecu
              totalPrime += maprimeSecu
              mesGaranties.push(garantie)
            }
          })).then(mesgaranties => {
            assure.garantiesAssures = mesgaranties
            return assure
          })

        })).then(async (mesAssures) => {
          //calcul de la primeprincipale
          surPrime = await getPrimeAccessoireProduit(produit.CodeProduit, periodicite)
          surPrime = surPrime ? surPrime[0].montant : 0
          //assuré(e)s
          if (!props.hasOwnProperty('assures')) {
            props.dispatch(registerField("adForm", 'assures', 'Field'));
          }
          props.updateField('assures', mesAssures)
          //surprime
          if (!props.hasOwnProperty('surPrime')) {
            props.dispatch(registerField("adForm", 'surPrime', 'Field'));
          }
          props.updateField('surPrime', Math.round(surPrime))
          //totalPrime
          if (!props.hasOwnProperty('totalPrime')) {
            props.dispatch(registerField("adForm", 'totalPrime', 'Field'));
          }
          setLoading(false)
          props.updateField('totalPrime', Math.round(totalPrime + surPrime))
          // props.onSubmit()

        })
        break;
      case 'PVRBNI':
        const { dureerente, montantrente, periodiciterente } = values
        if (!dureerente || dureerente === '') {
          setLoading(false)
          setMyErrors({ type: 'warning', message: 'Veuillez renseigner la durée de la rente' })
          handleOpenAlert()
          return false
        }
        if (!montantrente || montantrente === '') {
          setLoading(false)
          setMyErrors({ type: 'warning', message: 'Veuillez renseigner le montant de la rente' })
          handleOpenAlert()
          return false
        }
        if (!periodiciterente || periodiciterente === '') {
          setLoading(false)
          setMyErrors({ type: 'warning', message: 'Veuillez renseigner la périodicite de la rente' })
          handleOpenAlert()
          return false
        }
        //determination de la prime net 1
        const dureeCotisation = duree
        const i_c = 0.035; const fg_c = 0.05; const i_net_c = i_c * (1 - fg_c)
        let per_c = 12
        switch (periodicite) {
          case 'M':
            per_c = 12
            break;
          case 'T':
            per_c = 4
            break;
          case 'S':
            per_c = 2
            break;
          default:
            per_c = 1
            break;
        };
        const i_per = (Math.pow((1 + i_net_c), (1 / per_c))) - 1
        let v_c = 1 / (1 + i_per)
        let d_c = i_per / (1 + i_per)
        let valeur_a_c = (1 - Math.pow(v_c, (dureeCotisation * per_c))) / d_c

        const tx_fg = 0.04
        const tx_fa = 0.05
        const tx_investi = 1 - tx_fg - tx_fa
        const prime_net1 = (valeur_a_c * tx_investi)

        // determination prime rente
        const i_r = 0.035
        const fg_r = 0.05
        const i_net_r = i_r * (1 - fg_r)
        let per_r = 12
        switch (periodiciterente) {
          case 'M':
            per_r = 12
            break;
          case 'T':
            per_r = 4
            break;
          case 'S':
            per_r = 2
            break;
          default:
            per_r = 1
            break;
        };
        const i_per_r = (Math.pow((1 + i_net_r), (1 / per_r))) - 1
        const v_r = 1 / (1 + i_per_r)
        const d_r = i_per_r / (1 + i_per_r)
        const dureeRentea = parseInt(dureerente) / 12
        const dureeTotal = dureeRentea + parseInt(dureeCotisation)
        const valeur_a_r = (1 - Math.pow(v_r, ((dureeTotal - dureeCotisation) * per_r))) / d_r
        const vn = Math.pow(v_r, dureeCotisation * per_r)

        const tx_fg_r = (1 + 0.03)
        let primeRelle = (parseInt(montantrente) * tx_fg_r * valeur_a_r * vn) / prime_net1
        primeRelle = Math.round(primeRelle)

        await Promise.all(assures.map(async (assure) => {
          if (assure.garantiesFacultatives && assure.garantiesFacultatives.length !== 0) {
            garantieCalcul = assure.garantiesSouscrits.concat(assure.garantiesFacultatives.filter(item => item.checked === true && item.CodeProduitGarantie !== 'SENIOR'))
          } else {
            garantieCalcul = assure.garantiesSouscrits
          }

          return await Promise.all(garantieCalcul.map(async (garantie) => {
            garantie.prime = primeRelle
            garantie.primeAccesoire = 0
            garantie.primeTotal = parseInt(primeRelle)
            garantie.capitalGarantie = 0

            totalPrime += primeRelle
            return garantie

          })).then(mesgaranties => {
            assure.garantiesAssures = mesgaranties
            return assure
          })

        })).then(async (mesAssures) => {
          //calcul de la primeprincipale

          if (!props.hasOwnProperty('assures')) {
            props.dispatch(registerField("adForm", 'primePrincipale', 'Field'));
          }

          props.updateField('primePrincipale', primeRelle)

          surPrime = await getPrimeAccessoireProduit(produit.CodeProduit, periodicite)
          surPrime = 0; //surPrime?surPrime[0].montant:0
          //assuré(e)s

          if (!props.hasOwnProperty('assures')) {
            props.dispatch(registerField("adForm", 'assures', 'Field'));
          }
          props.updateField('assures', mesAssures)
          //surprime
          if (!props.hasOwnProperty('surPrime')) {
            props.dispatch(registerField("adForm", 'surPrime', 'Field'));
          }
          props.updateField('surPrime', Math.round(surPrime))
          //totalPrime
          if (!props.hasOwnProperty('totalPrime')) {
            props.dispatch(registerField("adForm", 'totalPrime', 'Field'));
          }
          setLoading(false)
          props.updateField('totalPrime', Math.round(totalPrime + surPrime))
          //props.onSubmit()
        })
        break;
      case 'PVRPRE':
          await Promise.all(assures.map(async (assure) => {
            if (assure.garantiesFacultatives && assure.garantiesFacultatives.length !== 0) {
              garantieCalcul = assure.garantiesSouscrits.concat(assure.garantiesFacultatives.filter(item => item.checked === true && item.CodeProduitGarantie !== 'SENIOR'))
            } else {
              garantieCalcul = assure.garantiesSouscrits
            }
  
            return await Promise.all(garantieCalcul.map(async (garantie) => {
              switch (garantie.CodeProduitGarantie) {
                case 'EPGPLUS':
                    garantie.prime = parseInt(values.details['primeepargne10'])
                    garantie.primeAccesoire = 0
                    garantie.primeTotal = parseInt(values.details['primeepargne10'])
                    garantie.capitalGarantie = 0
                    totalPrime += parseInt(values.details['primeepargne10'])
                  break;
                case 'PTYS':
                    garantie.prime = parseInt(values.details['primeprotectys'])
                    garantie.primeAccesoire = 0
                    garantie.primeTotal = parseInt(values.details['primeprotectys'])
                    garantie.capitalGarantie = parseInt(values['capital-deces'])
                    totalPrime += parseInt(values.details['primeprotectys'])
                break;
                case 'PERSIT':
                    garantie.prime = parseInt(values.details['primeperistance'])
                    garantie.primeAccesoire = 0
                    garantie.primeTotal = parseInt(values.details['primeperistance'])
                    garantie.capitalGarantie = 0
                    totalPrime += parseInt(values.details['primeperistance'])
                break;
                default:
                  break;
              }
              return garantie
  
            })).then(mesgaranties => {
              assure.garantiesAssures = mesgaranties
              return assure
            })
  
          })).then(async (mesAssures) => {
            //calcul de la primeprincipale
            if (!props.hasOwnProperty('assures')) {
              props.dispatch(registerField("adForm", 'primePrincipale', 'Field'));
            }
  
            props.updateField('primePrincipale', values.details['prime'])
  
            surPrime = await getPrimeAccessoireProduit(produit.CodeProduit, periodicite)
            surPrime = 0; //surPrime?surPrime[0].montant:0
            //assuré(e)s

            if (!props.hasOwnProperty('assures')) {
              props.dispatch(registerField("adForm", 'assures', 'Field'));
            }
            props.updateField('assures', mesAssures)
            //surprime
            if (!props.hasOwnProperty('surPrime')) {
              props.dispatch(registerField("adForm", 'surPrime', 'Field'));
            }
            props.updateField('surPrime', Math.round(surPrime))
            //totalPrime
            if (!props.hasOwnProperty('totalPrime')) {
              props.dispatch(registerField("adForm", 'totalPrime', 'Field'));
            }
            setLoading(false)
            props.updateField('totalPrime', Math.round(totalPrime + surPrime))
            //props.onSubmit()
          })
        
        break;
      default:
        break;
    }
    dispatch({ type: 'STOP_LOADING_UI' })
  }

  useEffect(() => {
    dispatch({ type: 'LOADING_UI' })
    if (initResume) {
      evaluationContrat(props.data)
    }
  }, [initResume])
  //rendu de la fenêtre

  return (
    <Fragment>
      {AlerteForSave()}
      {myErrors && (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={openAlert}
          autoHideDuration={6000}
          onClose={handleCloseAlert}

        >
          <Alert onClose={handleCloseAlert} severity={myErrors.type}>
            {myErrors.message}
          </Alert>
        </Snackbar>
      )}
      {!loading ? (
        <>
          <Card className={classes.root} variant="outlined">

            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                ADHERENT
              </Typography>

              <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                  <Box m={1} className={classes.assureInfoRow}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Titre :
                    </Typography>
                    <Typography variant="subtitle1">
                      {civiliteSous}
                    </Typography>
                  </Box>
                  <Box m={1} className={classes.assureInfoRow}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Nom :
                    </Typography>
                    <Typography variant="subtitle1">
                      {nomSous}
                    </Typography>
                  </Box>
                  <Box m={1} className={classes.assureInfoRow}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Prénoms :
                    </Typography>
                    <Typography variant="subtitle1"> {prenomSous}</Typography>
                  </Box>
                  <Box m={1} className={classes.assureInfoRow}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Sexe :
                    </Typography>
                    <Typography variant="subtitle1">
                      {sexeSous ? sexeSous : ""}
                    </Typography>
                  </Box>
                  <Box m={1} className={classes.assureInfoRow}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Date de naissance :
                    </Typography>
                    <Typography variant="subtitle1"> {dateNaissanceSous ? dayjs(dateNaissanceSous).format('DD/MM/YYYY') : ""}</Typography>
                  </Box>

                  <Box m={1} className={classes.assureInfoRow}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Lieu de naissance:
                    </Typography>
                    <Typography variant="subtitle1"> {lieuNaissanceSous ? lieuNaissanceSous : ""}</Typography>
                  </Box>
                  <Box m={1} className={classes.assureInfoRow}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Lieu de résidence:
                    </Typography>
                    <Typography variant="subtitle1"> {lieuResidenceSous ? lieuResidenceSous : ""}</Typography>
                  </Box>
                  <Box m={1} className={classes.assureInfoRow}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      N° {naturePiece} :
                    </Typography>
                    <Typography variant="subtitle1"> {pieceIdentiteSous ? pieceIdentiteSous : ""}</Typography>
                  </Box>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Box m={1} className={classes.assureInfoRow}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Profession :
                    </Typography>
                    <Typography variant="subtitle1">
                      {professionSous ? professionSous : ""}
                    </Typography>
                  </Box>
                  <Box m={1} className={classes.assureInfoRow}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Secteur d'activité:
                    </Typography>
                    <Typography variant="subtitle1"> {employeurSous ? employeurSous : ""}</Typography>
                  </Box>
                  {/*<Box m={1} className={classes.assureInfoRow}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                    Pays :
                    </Typography>
                    <Typography variant="subtitle1"> {paysSous?paysSous:""}</Typography>
                </Box>*/}
                  <Box m={1} className={classes.assureInfoRow}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Email :
                    </Typography>
                    <Typography variant="subtitle1">
                      {emailSous ? emailSous : ""}
                    </Typography>
                  </Box>


                  <Box m={1} className={classes.assureInfoRow}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Téléphone:
                    </Typography>
                    <Typography variant="subtitle1"> {telephoneSous ? telephoneSous : ''}</Typography>
                  </Box>
                  <Box m={1} className={classes.assureInfoRow}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Mobile :
                    </Typography>
                    <Typography variant="subtitle1"> {mobileSous ? mobileSous : ""} / {mobile2Sous ? ` / ${mobile2Sous}` : ""}</Typography>
                  </Box>
                  <Box m={1} className={classes.assureInfoRow}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Adresse postale :
                    </Typography>
                    <Typography variant="subtitle1"> {adressePostaleSous ? adressePostaleSous : ""} </Typography>
                  </Box>
                </Grid>
              </Grid>

            </CardContent>

          </Card>
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                CONDITIONS DE PAIEMENT DE LA PRIME & PERIODICITE
              </Typography>
              <Grid container>
                <Grid item sm={6} xs={12}>
                  <Box m={1} className={classes.assureInfoRow}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Produit :
                    </Typography>
                    <Typography variant="subtitle1">
                      {produit && produit.MonLibelle}
                    </Typography>
                  </Box>
                  <Box m={1} className={classes.assureInfoRow}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Date Effet:
                    </Typography>
                    <Typography variant="subtitle1"> {dayjs(dateEffet).format('DD/MM/YYYY')}</Typography>
                  </Box>

                  <Box m={1} className={classes.assureInfoRow}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Prime principale:
                    </Typography>
                    <Typography variant="subtitle1"> {primePrincipale ? formatNumber(primePrincipale) : ""}</Typography>
                  </Box>
                  <Box m={1} className={classes.assureInfoRow}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Echéance paiement :
                    </Typography>
                    <Typography variant="subtitle1"> {dayjs(dateEffet).add(duree, 'year').format('DD/MM/YYYY')}</Typography>
                  </Box>
                  <Box m={1} className={classes.assureInfoRow}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      {`${['YKE_2018','PVRPRE'].includes(produit.CodeProduit)? "Capital Décès ":"Capital désiré"}`} :
                    </Typography>
                    <Typography variant="subtitle1">
                      {capitalSouscrit ? formatNumber(parseInt(capitalSouscrit)) : ""}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item sm={6} xs={12}>

                  <Box m={1} className={classes.assureInfoRow}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Mode paiement:
                    </Typography>
                    <Typography variant="subtitle1"> {modePaiement}</Typography>
                  </Box>
                  <Box m={1} className={classes.assureInfoRow}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Organisme payeur :
                    </Typography>
                    <Typography variant="subtitle1"> {organismePayeur}</Typography>
                  </Box>
                  <Box m={1} className={classes.assureInfoRow}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Agence :
                    </Typography>
                    <Typography variant="subtitle1"> {agence}</Typography>
                  </Box>
                  <Box m={1} className={classes.assureInfoRow}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      N° Compte :
                    </Typography>
                    <Typography variant="subtitle1">
                      {numCompte}
                    </Typography>
                  </Box>

                </Grid>
              </Grid>
              {produit.CodeProduit === 'PVRBNI' && (<Grid container>
                <Grid item sm={12} xs={12}><Typography variant='h4' color="textSecondary" >Rente</Typography></Grid>
                <Grid item sm={4} xs={12}>
                  <Box m={1} className={classes.assureInfoRow}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Montant de la rente :
                    </Typography>
                    <Typography variant="subtitle1">
                      {montantrente ? formatNumber(montantrente) : ''}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item sm={4} xs={12}>
                  <Box m={1} className={classes.assureInfoRow}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Périodicité de la rente :
                    </Typography>
                    <Typography variant="subtitle1">
                      {periodiciterente === 'M' ? 'Mensuelle' : periodiciterente === 'T' ? 'Trimestrielle' : periodiciterente === 'S' ? 'Semestrielle' : periodiciterente === 'A' ? 'Annuelle' : 'Inconnu'}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item sm={4} xs={12}>
                  <Box m={1} className={classes.assureInfoRow}>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      Durée de la rente en mois :
                    </Typography>
                    <Typography variant="subtitle1">
                      {dureerente}
                    </Typography>
                  </Box>
                </Grid>

              </Grid>)}

            </CardContent>

          </Card>
          <Card className={classes.root} variant="outlined">

            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                ASSURE(E)S
              </Typography>
              <Grid container>
                <Grid item sm={12} xs={12}>
                  {assures ? (<TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow>

                          <TableCell>Nom</TableCell>
                          <TableCell>Prénoms</TableCell>
                          <TableCell align="center">Né(e) le</TableCell>
                          <TableCell align="center">Lieu de naissance</TableCell>
                          <TableCell align="center">Lieu de residence</TableCell>
                          <TableCell align="right">Filiation</TableCell>
                          <TableCell align="right">Garanties</TableCell>
                          <TableCell align="center">Téléphone</TableCell>
                          <TableCell align="right">Email</TableCell>
                          <TableCell>N° CNI</TableCell>
                          <TableCell align="right">Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {assures.map(assure => (
                          <TableRow key={assure.nomAssure}>

                            <TableCell component="th" scope="row">
                              {assure.nomAssure}
                            </TableCell>
                            <TableCell align="right">{assure.prenomAssure}</TableCell>
                            <TableCell align="right">{dayjs(assure.dateNaissanceAssure).format('DD/MM/YYYY')}</TableCell>
                            <TableCell align="right">{assure['lieuNaissanceAssure'] || ""}</TableCell>
                            <TableCell align="right">{assure['lieuResidenceAssure'] || ""}</TableCell>
                            <TableCell align="right">{assure['filiationAssure'] || ""}</TableCell>
                            <TableCell align="right"><>
                              {

                                assure.garantiesSouscrits.length !== 0 && assure.garantiesSouscrits.map((garantie) => garantie && ((garantie.CodeProduitGarantie!=='PERSIT')&&<Typography key={garantie.CodeProduitGarantie || garantie.codeproduitgarantie}> - {garantie['MonLibelle'] || garantie['libelle'] + '\n'}</Typography>))
                              }

                            </>
                            </TableCell>
                            <TableCell align="right">{assure.telephoneAssure}</TableCell>
                            <TableCell align="right">{assure.emailAssure}</TableCell>
                            <TableCell component="th" scope="row">
                              {assure.numeroCniAssure}
                            </TableCell>
                            <TableCell align="right"></TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>) : <Typography>Aucun aucun Assuré </Typography>}

                </Grid>
              </Grid>

            </CardContent>

          </Card>
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                BENEFICIAIRE(S)
              </Typography>
              <Grid container>
                <Grid container>
                  {(beneficiaireauterme && beneficiaireauterme?.length !== 0) && <Grid item sm={12} xs={12} md={12} lg={12}>
                    <Grid item xs={12} sm={12} md={6} lg={16}>
                      <FormLabel component="h5">Au terme du contrat</FormLabel>
                      <Paper component="ul" className={classes.chipRoot}>
                        {beneficiaireauterme?.map((option) => {
                          let libelle = ""
                          switch (option) {
                            case 'adherent':
                              libelle = "L'adherent"
                              break;
                            case 'conjoint':
                              libelle = "Le conjoint non divorcé, ni séparé de corps"
                              break;
                            case 'enfants':
                              libelle = "Les enfants nés et à naître"
                              break;
                            default:
                              libelle = "Autres"
                              break;
                          }
                          return <li key={option}>
                            <Chip
                              icon={null}
                              label={libelle}
                              className={classes.chip}
                            />
                          </li>
                        })}
                      </Paper>
                    </Grid>
                  </Grid>
                  }
                  {(beneficiaireaudeces && beneficiaireaudeces.length !== 0) && <Grid item sm={12} xs={12} md={12} lg={12}>
                    <Grid item xs={12} sm={12} md={6} lg={16}>
                      <FormLabel component="h6">En cas de décès avant le terme</FormLabel>
                      <Paper component="ul" className={classes.chipRoot}>
                        {beneficiaireaudeces.map((option) => {
                          let libelle = ""
                          switch (option) {
                            case 'conjoint':
                              libelle = "Le conjoint non divorcé, ni séparé de corps"
                              break;
                            case 'enfants':
                              libelle = "Les enfants nés et à naître"
                              break;
                            default:
                              libelle = "Autres"
                              break;
                          }
                          return <li key={option}>
                            <Chip
                              icon={null}
                              label={libelle}
                              className={classes.chip}
                            />
                          </li>
                        })}
                      </Paper>
                    </Grid>
                  </Grid>
                  }
                </Grid>
                <Grid item sm={12} xs={12} md={12} lg={12}>
                  {beneficiaires ? (<TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Nom</TableCell>
                          <TableCell>Prénoms</TableCell>
                          <TableCell align="center">Né(e) le</TableCell>
                          <TableCell align="center">Lieu de naissance</TableCell>
                          <TableCell align="center">Lieu de residence</TableCell>
                          <TableCell align="center">Filiation</TableCell>
                          <TableCell align="center">Téléphone</TableCell>
                          <TableCell align="center">Email</TableCell>
                          <TableCell align="center">Taux (%)</TableCell>
                          <TableCell align="center">Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {beneficiaires.map(beneficiaire => (
                          <TableRow key={beneficiaire.nomBeneficiaire}>
                            <TableCell component="th" scope="row">
                              {beneficiaire.nomBeneficiaire}
                            </TableCell>
                            <TableCell align="center">{beneficiaire.prenomBeneficiaire}</TableCell>
                            <TableCell align="center">{dayjs(beneficiaire.dateNaissanceBeneficiaire).format('DD/MM/YYYY')}</TableCell>
                            <TableCell align="center">{beneficiaire.lieuNaissanceBeneficiaire}</TableCell>
                            <TableCell align="center">{beneficiaire.lieuResidenceBeneficiaire}</TableCell>
                            <TableCell align="center">{beneficiaire.filiationBeneficiaire}</TableCell>
                            <TableCell align="center">{beneficiaire.telephoneBeneficiaire}</TableCell>
                            <TableCell align="center">{beneficiaire.emailBeneficiaire}</TableCell>
                            <TableCell align="center">{beneficiaire.taux}</TableCell>
                            <TableCell align="center"></TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>) : <Typography>Aucun aucun bénéficiaire </Typography>}

                </Grid>
              </Grid>

            </CardContent>

          </Card>
          <Card className={classes.root} variant="outlined">

            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                GARANTIES & PRIMES
              </Typography>
              <Grid container>
                <Grid item sm={12} xs={12}>
                  {assures ? (<TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          {(props.data.produit.CodeProduit !== 'PVRBNI') && <TableCell>Nom</TableCell>}

                          <TableCell align="center">Garantie</TableCell>
                          {(props.data.produit.CodeProduit !== 'PVRBNI') && <TableCell align="center">Capital</TableCell>}

                          <TableCell align="center">Prime</TableCell>


                          <TableCell align="center">Périodicite</TableCell>
                          <TableCell align="right">Total prime</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {assures.map((assure) => {
                          let garanties = [].concat(assure.garantiesSouscrits || []);
                          let rows = null
                          let cumulprime = 0
                          let cumulprimeTotal = 0
                          if (garanties && garanties.length!==0) {
                          
                            return garanties.map((garantie) => {
                              if(garantie){

                                if(garantie?.CodeProduitGarantie === 'PTYS'){
                                  const garPers =garanties.filter(item=>item.CodeProduitGarantie ==='PERSIT')[0]
                                  cumulprimeTotal =garPers ? (parseInt(garPers.primeTotal) + parseInt(garantie.primeTotal)) : parseInt(garantie.primeTotal)
                                  cumulprime =garPers ? (parseInt(garPers.prime) + parseInt(garantie.prime)) : parseInt(garantie.prime)
                                }else{
                                  cumulprimeTotal =parseInt(garantie?.primeTotal)
                                  cumulprime = parseInt(garantie?.prime)
                                }
  
                              return ((garantie.CodeProduitGarantie!=='PERSIT')&&<TableRow key={`${garantie?.CodeProduitGarantie}-${assure.id}`}>
                                        {(props.data.produit.CodeProduit !== 'PVRBNI') && <TableCell component="th" scope="row" align='left'>{`${assure.nomAssure}  ${assure.prenomAssure}`}</TableCell>}
                                  <TableCell align="center">{garantie?.MonLibelle}</TableCell>
                                  {(props.data.produit.CodeProduit !== 'PVRBNI') && <TableCell align="center">{garantie?.capitalGarantie ? formatNumber(parseInt(garantie?.capitalGarantie)) : ''}</TableCell>}
                                  <TableCell align="center">{cumulprime ? formatNumber(parseInt(cumulprime)) : ''}</TableCell>
                                  <TableCell align="center">{props.data.periodicite ? (props.data.periodicite === 'M' ? 'Mensuelle' : (props.data.periodicite === 'T' ? 'Trimestrielle' : (props.data.periodicite === 'S' ? 'Smestrielle' : 'Annuelle'))) : ''}</TableCell>
                                  <TableCell align="right">{cumulprimeTotal ? formatNumber(cumulprimeTotal) : ''}</TableCell>
                                </TableRow>)
                              }else{
                                return null
                              }
                              

                            })
                          }

                        })
                        }
                        <TableRow>
                          <TableCell colSpan={(props.data.produit.CodeProduit === 'PVRBNI') ? 3 : 4} align='center'><Typography variant='h5'>TOTAL</Typography></TableCell>
                          <TableCell colSpan={(props.data.produit.CodeProduit === 'PVRBNI') ? 1 : 2} align='right'><Typography variant='h5'>{totalPrime ? formatNumber(totalPrime) : ''}</Typography></TableCell></TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>) : <Typography>Aucun aucun garantie </Typography>}

                </Grid>
              </Grid>

            </CardContent>

          </Card>
          <Divider component="hr" className={classes.hTrait} />

          <Grid container direction="row" justify="space-between" alignItems="center">

            <Button type="button" className={classes.btnback} onClick={props.handleBack}>
              Retour
            </Button>
            <Button className={classes.btnnext} type="submit" disabled={loadingui} onClick={handleSubmit(saveSouscription)}>
              {loadingui ? <CircularProgress size={30} color="secondary" /> : <div>Valider
                <NavigateNextOutlinedIcon /></div>}

            </Button>
          </Grid>
        </>
      ) : (
        <ModalLoading myopen={loading} />
      )}


    </Fragment>

  )

}
const selector = formValueSelector('adForm')

const mapStateToProps = (state) => ({
  user: state.user,
  api: state.api,
  UI: state.UI,
  data: getFormValues('adForm')(state)
})
const updateField = (field, data) => (dispatch) => dispatch(change('adForm', field, data))
const mapActionsToProps = {
  updateField,
  addSouscription,
  updateProposition
}
SouscriptionFicheresume = withStyles(styles)(SouscriptionFicheresume)


export default connect(mapStateToProps, mapActionsToProps)(reduxForm({ form: 'adForm', destroyOnUnmount: false, })(SouscriptionFicheresume))
