import React, { useEffect } from "react";
import Card from "components/Card/Card.js";
import DashBordSlider from 'pages/components/DashBordSlider'
import Typography from "@material-ui/core/Typography";
import SmallStats from "components/SmallStats/SmallStats";
import EvolutionProduction from 'pages/stats/EvolutionProduction'
import ProductionParproduit from 'pages/stats/ProductionParproduit'
import "assets/styles/shards-dashboards.1.1.0.min.css";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { getTotProductionAgent } from 'redux/actions/apiActions'
import { connect } from 'react-redux'
import { reduxForm, change } from 'redux-form';
import DashbordPret from "./DashbordPret";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(theme => ({
  contain: {
    display: 'flex',
    flewWrap: 'wrap'
  },
  items: {
    marginBottom: 20,
    marginRight: 20
  }
}))
function Dashboard(props) {
  const { user: { credentials } } = props
  const classes = useStyles()
  const [smState, setSmState] = React.useState([])
  useEffect(() => {
    document.title = "ENOV - Tableau de bord"
  })
  const handleSmallState = () => {
    getTotProductionAgent(credentials.idmembre).then(resData => {
      if (resData) {
        const { general, lastWeek } = resData
        const datastate = [
          {
            label: "TOTAL PROPOSITION",
            value: (general && general.all.total) ? general.all.total : 0,
            percentage: (general && general.all.total && general.all.total) ? ((parseInt(general.all.total) / general.all.total * 100)).toFixed(2) + "%" : 0,
            increase: (general && (parseInt(general.all.total) / general.all.total * 100) >= 50) ? true : false,
            chartLabels: (lastWeek && lastWeek.allweek && lastWeek.allweek.length !== 0) ? lastWeek.allweek.map(elt => (elt.total)) : [null, null, null, null, null, null, null],
            attrs: { md: "6", sm: "6" },
            datasets: [
              {
                label: "Today",
                fill: "start",
                borderWidth: 1.5,
                backgroundColor: "rgba(0, 184, 216, 0.1)",
                borderColor: "rgb(0, 184, 216)",
                data: (lastWeek && lastWeek.allweek && lastWeek.allweek.length !== 0) ? lastWeek.allweek.map(elt => (elt.total)) : []
              }
            ]
          },
          {
            label: "EN ATTENTE DE TRANSMISSION",
            value: (general && general.saisies.nbSaisie) ? general.saisies.nbSaisie : 0,
            percentage: (general && general.saisies.nbSaisie && general.all.total) ? ((parseInt(general.saisies.nbSaisie) / general.all.total * 100)).toFixed(2) + "%" : 0,
            increase: (general && (parseInt(general.saisies.nbSaisie) / general.all.total * 100) >= 50) ? false : true,
            chartLabels: (lastWeek && lastWeek.saisiesweek && lastWeek.saisiesweek.length !== 0) ? lastWeek.saisiesweek.map(elt => (elt.nbSaisie)) : [null, null, null, null, null, null, null],
            attrs: { md: "4", sm: "6" },
            datasets: [
              {
                label: "Today",
                fill: "start",
                borderWidth: 1.5,
                backgroundColor: "rgba(255,65,105,0.1)",
                borderColor: (general && ((parseInt(general.saisies.nbSaisie) / general.all.total) * 100) < 50) ? "rgb(0,123,255)" : "rgb(0, 184, 216)",
                data: (lastWeek && lastWeek.saisiesweek && lastWeek.saisiesweek.length !== 0) ? lastWeek.saisiesweek.map(elt => (elt.nbSaisie)) : []
              }
            ]
          },
          {
            label: "PROPOSITIONS EN ATTENTE DE VALIDATION",
            value: (general && general.transmis.nbTransmis) ? general.transmis.nbTransmis : 0,
            percentage: (general && general.transmis.nbTransmis && general.all.total) ? ((parseInt(general.transmis.nbTransmis) / general.all.total * 100)).toFixed(2) + "%" : 0,
            increase: (general && (parseInt(general.transmis.nbTransmis) / general.all.total * 100) >= 50) ? false : true,
            chartLabels: (lastWeek && lastWeek.transmisweek && lastWeek.transmisweek.length !== 0) ? lastWeek.transmisweek.map(elt => (elt.nbTransmis)) : [null, null, null, null, null, null, null],
            attrs: { md: "4", sm: "6" },
            datasets: [
              {
                label: "Today",
                fill: "start",
                borderWidth: 1.5,
                backgroundColor: "rgba(255,180,0,0.1)",
                borderColor: "rgb(255,180,0)",
                data: (lastWeek && lastWeek.transmisweek && lastWeek.transmisweek.length !== 0) ? lastWeek.transmisweek.map(elt => (elt.nbTransmis)) : []
              }
            ]
          },
          {
            label: "PROPOSITIONS ACCEPTEES",
            value: (general && general.acceptes.nbAccepter) ? general.acceptes.nbAccepter : 0,
            percentage: (general && general.acceptes.nbAccepter && general.all.total) ? ((parseInt(general.acceptes.nbAccepter) / general.all.total * 100)).toFixed(2) + "%" : 0,
            increase: (general && (parseInt(general.acceptes.nbAccepter) / general.all.total * 100) >= 50) ? true : false,
            chartLabels: (lastWeek && lastWeek.acceptesweek && lastWeek.acceptesweek.length !== 0) ? lastWeek.acceptesweek.map(elt => (elt.nbAccepter)) : [null, null, null, null, null, null, null],
            attrs: { md: "4", sm: "6" },
            datasets: [
              {
                label: "Today",
                fill: "start",
                borderWidth: 1.5,
                backgroundColor: "rgb(0,123,255,0.1)",
                borderColor: "rgb(0,123,255)",
                data: (lastWeek && lastWeek.acceptesweek && lastWeek.acceptesweek.length !== 0) ? lastWeek.acceptesweek.map(elt => (elt.nbAccepter)) : []
              }
            ]
          },
          {
            label: "PROPOSITIONS REJETEES",
            value: (general && general.rejetes.nbrejeter) ? general.rejetes.nbrejeter : 0,
            percentage: (general && general.rejetes.nbrejeter && general.all.total) ? ((parseInt(general.rejetes.nbrejeter) / general.all.total * 100)).toFixed(2) + "%" : 0,
            increase: (general && (parseInt(general.rejetes.nbrejeter) / general.all.total * 100) >= 50) ? false : true,
            chartLabels: (lastWeek && lastWeek.rejetesweek && lastWeek.rejetesweek.length !== 0) ? lastWeek.rejetesweek.map(elt => (elt.nbrejeter)) : [null, null, null, null, null, null, null],
            attrs: { md: "4", sm: "6" },
            datasets: [
              {
                label: "Today",
                fill: "start",
                borderWidth: 1.5,
                backgroundColor: "rgba(255,65,105,0.1)",
                borderColor: "rgb(255,65,105)",
                data: (lastWeek && lastWeek.rejetesweek && lastWeek.rejetesweek.length !== 0) ? lastWeek.rejetesweek.map(elt => (elt.nbrejeter)) : []
              }
            ]
          },
          {
            label: "PROPOSITIONS ANNULEES",
            value: (general && general.annules.nbreannuler) ? general.annules.nbreannuler : 0,
            percentage: (general && general.annules.nbreannuler && general.all.total) ? ((parseInt(general.annules.nbreannuler) / general.all.total * 100)).toFixed(2) + "%" : 0,
            increase: (general && (parseInt(general.annules.nbreannuler) / general.all.total * 100) >= 50) ? false : true,
            chartLabels: (lastWeek && lastWeek.annulesweek && lastWeek.annulesweek.length !== 0) ? lastWeek.annuleesweek.map(elt => (elt.nbreannuler)) : [null, null, null, null, null, null, null],
            attrs: { md: "4", sm: "6" },
            datasets: [
              {
                label: "Today",
                fill: "start",
                borderWidth: 1.5,
                backgroundColor: "rgba(255,65,105,0.1)",
                borderColor: "rgb(255,65,105)",
                data: (lastWeek && lastWeek.annulesweek && lastWeek.annulesweek.length !== 0) ? lastWeek.annulesweek.map(elt => (elt.nbreannuler)) : []
              }
            ]
          }
        ]
        setSmState(datastate)

      }
    })
  }
  useEffect(() => {
    handleSmallState()
  }, [])
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Typography variant='h2'>Bienvenue</Typography>
        </GridItem>
        {!(['WF','PAMFCI'].includes(credentials.codepartenaire)) && (
          <GridItem xs={12} sm={12} md={12} lg={12}>
          <DashBordSlider />
        </GridItem>
        )}
        
      </GridContainer>
      {!(['WF','PAMFCI'].includes(credentials.codepartenaire)) && (<GridContainer>

        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Typography variant='h6'>Etat de la production</Typography>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <GridContainer className={classes.contain}>
            {smState.map((stats, idx) => (
              <GridItem xs={6} sm={4} md={3} lg={2} key={idx} {...stats.attrs}>
                <SmallStats
                  id={`small-stats-${idx}`}
                  variation="1"
                  chartData={stats.datasets}
                  chartLabels={stats.chartLabels}
                  label={stats.label}
                  value={stats.value}
                  percentage={stats.percentage}
                  increase={stats.increase}
                  decrease={stats.decrease}
                />
              </GridItem>
            ))}
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={8} lg={8}>
              <Card style={{ padding: 10 }}>
                <EvolutionProduction title="Evolution de la production" />
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={4} lg={4}>
              <Card style={{ padding: 10, marginRight: 20, marginLeft: 20 }} >
                <ProductionParproduit title="Production par produit" />
              </Card >
            </GridItem>

          </GridContainer>
        </GridItem>
      </GridContainer>)}

      {(['WF','PAMFCI'].includes(credentials.codepartenaire)) && <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <DashbordPret />
        </GridItem>
      </GridContainer>}
    </div>
  );
}

const updateField = (field, data) => (dispatch) => dispatch(change('adForm', field, data))
const mapPropsActions = {}
const mapStateToProps = (state) => ({
  api: state.api,
  user: state.user
});

export default connect(mapStateToProps, mapPropsActions)(reduxForm({ destroyOnUnmount: false, })(Dashboard))

