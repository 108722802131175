import React, { useState } from 'react'
import { Button, CircularProgress, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import CustumModal from 'components/CustumModal';
import LabelledOutline from 'components/LabelledOutline/LabelledOutline';
import dayjs from 'dayjs';
import { renitPassword } from 'redux/actions/userActions';
import swal from 'sweetalert';

function ChangerPassword({userData,open,handleClose}) {
    
    const [inputField,setInputField]=useState(
        {
            codeuser:userData.idmembre?userData.idmembre:'',
            password:"",
            passwordnew:'',
            passwordConfirm:'',
            showpassword: false,
            showpasswordNew:false,
            showpasswordConfirm:false
        }
    );
    const [loading,setLoading] = useState(false)
    const handleChange = (event)=>{   
        const {name,value} = event.target
        setInputField({...inputField,[name]:value})
    }
    const handleClickShowPassword = (name) => {
        setInputField({[`show${name}`]: !(eval(`${'inputField.show'+name}`)) });
      };
      const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };
    const [errors,setErrors] = useState(null)
    const [errorsAll, setErrorsAll]=useState(null)
    const changeMyPassword=(e)=>{
        e.preventDefault()
        setErrorsAll(null)
        if(inputField.passwordnew.trim()===''){
            setErrors({'passwordnew':"Champ obligatoire"}) 
            return 
        }
        if(inputField.passwordConfirm.trim()===''){
            setErrors({'passwordConfirm':"Champ obligatoire"}) 
            return 
        }

        if(inputField.passwordnew.trim()!==inputField.passwordConfirm.trim()){
            setErrors({'passwordnew':"Impossible de confirmer le mot de passe",'passwordConfirm':""}) 
          
            return
        }
        let mesDonnees =JSON.stringify(inputField,null,2)
        mesDonnees =JSON.parse(mesDonnees)
        renitPassword(mesDonnees).then(res=>{
            if(res){
                swal('Bien!',"Modification effectuée avec succès",'success')
                //startTime()
                handleClose()
            }else{
                swal('Ooops!',"Erreur lors de la modification",'error') 
            }
        })
    }

    return (
    <CustumModal title="Réinitialisation du mot de passe" open={open} handleClose ={handleClose} width='sm'>
        <form onSubmit={changeMyPassword}>
            {!loading?(
            <div className='flex flex-col'>
                        <LabelledOutline label="Informations personnelles" >
                            <div className='flex flex-row gap-2'>
                                <Typography variant='subtitle1'>Nom :</Typography><Typography variant='h6'>{userData.nom?userData.nom:""}</Typography>
                                <Typography variant='subtitle1'>Prenom :</Typography><Typography variant='h6'>{userData.prenom?userData.prenom:""}</Typography>
                            </div>
                            <div className='flex flex-row gap-2'>
                                <Typography variant='subtitle1'>Né(e) le :</Typography><Typography variant='h6'>{userData.datenaissance?dayjs(userData.datenaissance).format('DD/MM/YYYY'):""}</Typography>
                                <Typography variant='subtitle1'>Genre:</Typography><Typography variant='h6'>{userData.sexe?userData.sexe:""}</Typography>   
                            </div>
                        </LabelledOutline> 

                         <LabelledOutline label="Nouveau mot de passe" className="flex flex-col gap-3"> 
                            <FormControl fullWidth variant="outlined">
                                <InputLabel htmlFor="password">Nouveau mot de passe</InputLabel>
                                <OutlinedInput
                                    error={(errors && errors.passwordnew)?true:false}
                                    id="passwordnew"
                                    name="passwordnew"
                                    type={inputField.showpasswordNew ? 'text' : 'password'}
                                    onChange={handleChange}
                                    endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                        aria-label="toggle passwordNew visibility"
                                        onClick={(e)=>handleClickShowPassword('passwordNew')}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                        >
                                        {inputField.showpasswordNew ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                    }
                                    fullWidth
                                    labelWidth={200}
                                />
                                 {(errors && errors.passwordnew)&&( <FormHelperText error >{errors.passwordnew}</FormHelperText>)}
                            </FormControl>
                            <FormControl fullWidth  variant="outlined" className='my-3'>
                                <InputLabel htmlFor="password">Confirmation du mot de passe</InputLabel>
                                <OutlinedInput
                                    error={(errors && errors.passwordConfirm)?true:false}
                                    id="passwordConfirm"
                                    name="passwordConfirm"
                                    type={inputField.showpasswordConfirm ? 'text' : 'password'}
                                    onChange={handleChange}
                                    endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                        aria-label="toggle passwordConfirm visibility"
                                        onClick={(e)=>handleClickShowPassword('passwordConfirm')}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                        >
                                        {inputField.showpasswordConfirm ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                    }
                                    fullWidth
                                    labelWidth={200}
                                />
                                {(errors && errors.passwordConfirm)&&( <FormHelperText error >{errors.passwordConfirm}</FormHelperText>)}
                            </FormControl>
                         </LabelledOutline>      
                         <div className='flex flex-row justify-between items-center my-3'>
                            <Button onClick={handleClose} variant='outlined' className='py-2 px-4 bg-secondary-main text-white rounded-full border-0 shadow-md'>Annuler</Button>
                            <Button variant='outlined' type='submit'  className='py-2 px-4 bg-primary-main hover:bg-secondary-main text-white rounded-full border-0 shadow-md'>Enregistrer</Button>
                        </div>
            </div>):(<div className="flex flex-1 justify-center items-center"><CircularProgress color="primary" /></div>)}
        </form>
    </CustumModal>
  )
}

export default ChangerPassword