import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from 'clsx';
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { reduxForm,change,getFormValues,reset } from 'redux-form';
import {connect} from 'react-redux'
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { green, pink } from '@material-ui/core/colors';
import {Assignment,Add, Folder} from "@material-ui/icons/";
import FindInPageIcon from '@material-ui/icons/FindInPage';
import { useHistory } from "react-router-dom";
import TitleBox from "components/TitleBox";
const useStyles = makeStyles((theme) =>({
    root:{
        width:'100%',
        display:'flex',
        flexDirection:'column',
        flex:1
    },

    card: {
        flexGrow: 1,
        marginBottom: 20,
        borderRadius:10,
        marginRight:20,
        marginLeft:20,
        cursor:'pointer',
        '& :hover': {
            color:'white',
            border: 0,
            textDecoration: 'none',
            background: theme.palette.primary.main,
        }
    },
    media: {
      height: 140,
    },
    avatar: {
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        alignSelf:'center'
      },
      pink: {
        color:theme.palette.getContrastText(pink[500]),
        backgroundColor: pink[500],
      },
      green: {
        color: '#fff',
        backgroundColor: green[500],
      },
      large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
      },
      eheader:{
        display:'flex',
        width:'100%',
        paddingTop:20,
        paddingBottom:20,
        backgroundColor:theme.palette.primary.main
    },
    boxheader:{
        display:'flex',
        width:'60%',
        [theme.breakpoints.down('sm')]: {
            width:'100%',
        },
        [theme.breakpoints.up('md')]: {
            width:'60%',
        },
        justifyContent:'space-between',
        alignItems:'center',
        marginLeft:'auto',
        marginRight:'auto'
    },
    corps:{
        width:'60%',
        [theme.breakpoints.down('sm')]: {
            width:'100%',
        },
        [theme.breakpoints.up('md')]: {
            width:'75%',
            justifyContent:'center',
        },
        display:'flex',
        justifyContent:'space-between',
        flexWrap:'wrap',
        marginLeft:'auto',
        marginRight:'auto',
        marginTop:75
    },
  }));
function ProdFonctions(props){
    console.log(props);
    const classes = useStyles();
    const {handleCliqueFonction,user:{privileges}}=props
    let history = useHistory();
    const nouvelleproposition =()=>{
        props.dispatch(reset('adForm'))
        history.push(`/${props.ui.layoutRoot}/nouvelle-proposition`)
    }
    
    return(
        <div className={classes.root}>
             <TitleBox titre ="Gestion de la production" />
            <div className={classes.corps}>
                <Card className={classes.card} onClick={nouvelleproposition}>
                    <CardContent>
                        <div className={classes.avatar}><Avatar aria-label="recipe" className={clsx(classes.large)}><Add /></Avatar></div>
                        <Typography align='center' gutterBottom variant="h5" component="h5">
                            Nouvelle proposition
                        </Typography>
                        <Typography align='center' variant="caption" color="textSecondary" component="p">
                            Choix du produit - Adherent - Assuré(s)- bénéficaires ....
                        </Typography>
                    </CardContent>
                </Card>
                <Card className={classes.card} onClick={event => handleCliqueFonction('PROD')}>
                    <CardContent>
                        <div className={classes.avatar}><Avatar aria-label="recipe" className={clsx(classes.large)}><Folder /></Avatar></div>
                        <Typography align='center' gutterBottom variant="h5" component="h5">
                            Mes propositions
                        </Typography>
                        <Typography align='center' variant="caption" color="textSecondary" component="p">
                            Gestion des propositions (ajouter,transmettre,editer, ...)
                        </Typography>
                    </CardContent>
                </Card>
                <Card className={classes.card} onClick={event => handleCliqueFonction('CONTRAT')}>
                    <CardContent>
                        <div className={classes.avatar}><Avatar aria-label="recipe" className={clsx(classes.large)}><Assignment /></Avatar></div>
                        <Typography align='center' gutterBottom variant="h5" component="h5">
                            Mes contrats
                        </Typography>
                        <Typography align='center' variant="caption" color="textSecondary" component="p">
                            Gestion des contrats (liste, cotisation,...)
                        </Typography>
                    </CardContent>
                </Card>
                <Card className={classes.card} onClick={event => handleCliqueFonction('SEARCH')}>
                    <CardContent>
                        <div className={classes.avatar}><Avatar aria-label="recipe" className={clsx(classes.large)}><FindInPageIcon /></Avatar></div>
                        <Typography align='center' gutterBottom variant="h5" component="h5">
                            Rechercher un contrat
                        </Typography>
                        <Typography align='center' variant="caption" color="textSecondary" component="p">
                            Trouver des contrats,editer les états de cotisation
                        </Typography>
                    </CardContent>
                </Card>
            </div>
        </div>
    )
}


const updateField =(field, data)=>(dispatch)=>dispatch(change('adForm', field, data))
const mapPropsActions ={
    updateField,
}
const mapStateToProps = (state) => ({
    api: state.api,
    user:state.user,
    ui:state.UI,
    data:getFormValues('adForm')(state)
  });

  export default connect(mapStateToProps,mapPropsActions)(reduxForm({destroyOnUnmount: false,})(ProdFonctions))