import React,{useState,useEffect} from "react";
import {reduxForm,change,getFormValues } from 'redux-form';
import {connect,useSelector} from 'react-redux'
import AdminFonctions from 'pages/Banking/Administration/AdminFonctions'
import UtilisateurList from 'pages/Banking/Administration/Utilisateur/UtilisateurList'
import EquipeList from'pages/Banking/Administration/Equipe/EquipeList'
import ZoneList from'pages/Banking/Administration/Zone/ZoneList'
import ErrorPage from 'components/ErrorPage'
import {getPrivileges} from 'redux/actions/userActions'
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
function AdminHome(props){
   const location = useLocation()
   console.log(location);
    const [fonctionnalite,setFonctionalite]=useState('HOME')
    const handleCliqueFonction = element=>{
        setFonctionalite(element)
    }
    useEffect(() => {
        document.title ="ENOV - Administration"
      })
      const {privileges} = useSelector(state=>state.user)
      const getRule=(mod,rule)=>{
        
        if(privileges && privileges.length!==0){
          const ind=  privileges.findIndex(item=>(item.codemodule===mod && item.codeprivilege===rule && item.estactif==1));
          return ind===-1?false:true
        }else{
            let myres
          props.getPrivileges().then(res=>{
             
                if(res){
                    const ind=  res.findIndex(item=>(item.codemodule===mod && item.codeprivilege===rule && item.estactif==1));
                    myres=(ind===-1)?false:true 
                }else{
                    myres=false 
                }
            })
            return myres
        }
    }
    return(
        <div>
            {fonctionnalite==='HOME'&&  <AdminFonctions handleCliqueFonction={handleCliqueFonction}/>}
            {(fonctionnalite==='USER' && getRule('user','read'))? <UtilisateurList handleCliqueFonction={handleCliqueFonction}/>:((fonctionnalite==='USER' && !getRule('user','read'))&&<ErrorPage backAction={()=>handleCliqueFonction('HOME')} title="Oops !" message="Oops !!! Vous n'avez pas les autorisations nécécaires pour acceder à cette page !"/>)}
            {(fonctionnalite==='AGENCE' && getRule('agence','read'))?  <EquipeList handleCliqueFonction={handleCliqueFonction}/>:((fonctionnalite==='AGENCE' && !getRule('agence','read'))&&<ErrorPage backAction={()=>handleCliqueFonction('HOME')} title="Oops !" message="Oops !!! Vous n'avez pas les autorisations nécécaires pour acceder à cette page !"/>)}
            {(fonctionnalite==='ZONE' && getRule('zone','read'))?  <ZoneList handleCliqueFonction={handleCliqueFonction}/>:((fonctionnalite==='ZONE' && !getRule('zone','read'))&&<ErrorPage backAction={()=>handleCliqueFonction('HOME')} title="Oops !" message="Oops !!! Vous n'avez pas les autorisations nécécaires pour acceder à cette page !"/>)}
           
        </div>
    )
}


const updateField =(field, data)=>(dispatch)=>dispatch(change('adForm', field, data))
const mapPropsActions ={
    updateField,
    getPrivileges
}
const mapStateToProps = (state) => ({
    api: state.api,
    user:state.user,
    data:getFormValues('adForm')(state)
  });

  export default connect(mapStateToProps,mapPropsActions)(reduxForm({destroyOnUnmount: false,})(AdminHome))
  
