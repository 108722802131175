import React from 'react'
import { Paper,Typography,Divider, Button,Tooltip,IconButton } from '@material-ui/core'
import AssignmentIcon from '@material-ui/icons/Assignment';
import VisibilityIcon from '@material-ui/icons/Visibility';
import dayjs from 'dayjs';
const ConventionItem = ({convention}) => {
  return (
    <Paper className='px-2 mb-3 flex flex-row justify-between w-full'>
      <div className='flex flex-col justify-center'>
          <div><span>{convention?.type}</span>  <h3>{convention.description}</h3></div>
          <div className='flex flex-row gap-4'>
              <div className='flex flex-row gap-2'>
                <span className='font-bold italic text-gray-400'>Signé le :</span><span className='font-bold text-black'>{dayjs(convention.date).format('DD/MM/YYYY')}</span>
              </div>
              <div className='flex flex-row gap-2'>
                <span className='font-bold italic text-gray-400'>Echéance :</span><span className='font-bold text-black'>{dayjs(convention.datefin).format('DD/MM/YYYY')}</span>
              </div>
              <div className='flex flex-row gap-2'>
                <span className='font-bold italic text-gray-400'>Comissionné à :</span><span className='font-bold text-black'>{convention['commisions'] || ""}%</span>
              </div>
          </div>
       </div>
       <div className='flex flex-col'>
          <span className='font-bold italic text-gray-400'>Produits</span>
          <div className='flex items-center justify-center py-4'>
              <h3 className='font-bold'>{(convention.produit && convention.produit.length!==0) ? convention.produit.length : "00"}</h3>
          </div>
       </div>
       <div className='flex flex-row p-2'>
       <Divider orientation='vertical' className='mx-2' />
       <div className='flex flex-col justify-center gap-2'>
          <Tooltip title="Documents">
            <IconButton>
              <AssignmentIcon />
          </IconButton>
          </Tooltip>
          <Tooltip title="Détails">
            <IconButton>
              <VisibilityIcon />
          </IconButton>
          </Tooltip>
          </div>
       </div>
    </Paper>
  )
}

export default ConventionItem
