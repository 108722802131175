import React, { useEffect, useRef } from 'react'
import { makeStyles,withStyles } from '@material-ui/styles'
import { Paper,Typography,Divider, Button,Tooltip,IconButton } from '@material-ui/core'
import { red,blue } from '@material-ui/core/colors'
import AddIcon from '@material-ui/icons/Add';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import ListAltIcon from '@material-ui/icons/ListAlt';
import MaterialTable from 'material-table';
import {getProductionAgentParProduit,getTotProductionAgent} from 'redux/actions/apiActions'
import { useDispatch,useSelector } from 'react-redux';
import { connect } from 'react-redux';
import HomeIcon from '@material-ui/icons/Home';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import AirlineSeatIndividualSuiteIcon from '@material-ui/icons/AirlineSeatIndividualSuite';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import AssignmentIcon from '@material-ui/icons/Assignment';
import Badge from '@material-ui/core/Badge';
import Chart from 'util/chart'
import Header from 'pages/Commons/components/Header';
const useStyle = makeStyles(theme=>({
    root:{
        marginLeft:'auto',
        marginRight:'auto',
    },
    ppaper:{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        padding:20,
        marginBottom:25
    },
    ppaperItem:{
        display:'flex',
        flex:1,
        flexDirection:'column',
        alignItems:'center',
        margin:10,
    },
    textegreen:{
        color:theme.palette.primary.main,
    },
    texteblue:{
        color:"#00FF00",
    },
    textered:{
        color:red[400],
    },
    texteyel:{
        color:'#f9b233',
    },
    actionContainer:{
        display:'flex',
        justifyContent:'center',
        marginTop:20,
        flexWrap:'wrap'
    },
    btnadd:{
        display:'flex',
        flex:1,
        paddingLeft:20,
        paddingRight:20,
        paddingTop:10,
        paddingBottom:10,
        backgroundColor:'#fff',
        border:'1px solid #f6f6f6',
        margin:20,
        borderRadius:10,
        '&:hover':{
            backgroundColor:theme.palette.primary.main,
            color:'#fff',
        }

    },
    incorpContainer:{
        display:'flex',
        justifyContent:'center',
        marginTop:25,
        marginBottom:20,
        marginLeft:20,
        marginRight:20,
        padding:20
    },
    incorpTable:{
        display:'flex',
        flex:1,
        marginTop:20,
        justifyContent:'center',
       
    },
    incorppaper:{
        padding:20,
        borderRadius:10,
        marginRight:20 
    },
    tabTitle:{
        marginBottom:10
    },
    hPaper:{
      padding:10,
      marginBottom:20,
      borderLeftColor: theme.palette.primary.main,
      borderLeftWidth:8,
      display:'flex',
      justifyContent:'space-between'
    },
    hContainer:{
      display:'flex',
      justifyContent:'space-between',
      alignItems:'center',
      padding:20
    },
}))
const gerdata = (ref)=>{
    let dt=[]
    const min = Math.ceil(ref.min);
    const max = Math.floor(ref.max);

    for (let i=0; i<ref.count;i++){
        dt.push(Math.floor(Math.random() * (max - min) + min))
    }
    return dt
}

const ChiffreAffaire =(props)=>{
    const cacanvas = useRef()
    const DATA_COUNT = 12;
    const NUMBER_EM = {count: DATA_COUNT, min: 5000, max: 1000000};
    const NUMBER_CFG = {count: DATA_COUNT, min: 500, max: 1000000};
    const labels = ['Janvier','Février','Mars','Avril','Mai','Juin','Juillet','Aout','Septembre','Octobre','Novembre','Decembre'];
   
    const data = {
        labels: labels,
        datasets: [
            {
            label: 'Réglement',
            data: gerdata(NUMBER_CFG),
            borderColor: red[600],
            backgroundColor: red[200],
            stack: 'combined',
            type: 'bar'
            },
            {
            label: 'Emission',
            data: gerdata(NUMBER_EM),
            borderColor: blue[600],
            backgroundColor: blue[200],
            stack: 'combined'
            }
        ]
    };

    const config = {
        type: 'line',
        data: data,
        options: {
          plugins: {
            title: {
              display: true,
              text: 'Chart.js Stacked Line/Bar Chart'
            }
          },
          scales: {
            y: {
              stacked: true
            }
          }
        },
    };
    useEffect(()=>{
        new Chart(cacanvas.current, config);
    },[])
    return (
        <canvas
            ref={cacanvas}
            height={250}
            style={{width:'100%'}}
          />
    )
}
const ChiffreAffaireProd = (props)=>{
    const caprodcanvas = useRef()
    const DATA_COUNT = 5;
    const NUMBER_CFG = {count: DATA_COUNT, min: 5000, max: 1000000};
    
    
    const data = {
        labels: ['FRAIS FUNERAIRES', 'PENSION', 'CADENCE EDUCATION PLUS', 'DOIHOO', 'DECES EMPRUNTEUR'],
        datasets: [
            {
            label: 'Produits',
            data: gerdata(NUMBER_CFG),
            backgroundColor:["#9c27b0","#ff9800","#42a5f5","#2e7d32","#ffeb3b"]
            }
        ]
    };
    const config = {
        type: 'doughnut',
        data: data,
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: 'top',
            },
            title: {
              display: true,
              text: 'Chart.js Doughnut Chart'
            }
          }
        },
      };
      useEffect(()=>{
        new Chart(caprodcanvas.current, config);
    },[])
    return (
        <canvas
            ref={caprodcanvas}
            height={250}
            style={{width:'100%'}}
        />
    )
}

const FacturationAccueil = ({handleCliqueFonction}) => {
    const classes = useStyle()
  return (
    <div className='flex flex-col'>
        <Header left={()=>(<div>

        </div>)} title="Gestion des factures" />

        {/*<Paper className={classes.hPaper} variant="outlined">
            <div className={classes.hContainer}>
                <Typography variant='h5'>Gestion des factures</Typography>
            </div>
            <Button onClick={()=>handleCliqueFonction('ADD')} className='hover:bg-primary-main border-1 border-secondary-main group'>
                <AddIcon className='text-[40px]' />
            </Button>
        </Paper>*/}
        <div className='flex gap-4 flex-wrap items-center h-fit mx-4 mb-4'>
            <Paper variant='outlined'  className='flex flex-col justify-between items-center flex-1 p-2'>
                <h1>Total factures</h1>
                <h1>45</h1>
                <p>Toutes les factures emisent</p>
                <Button variant='outlined' className='border-orange-300 rounded-lg hover:bg-orange-400'>Voir le détails</Button>
            </Paper>
            <Paper variant='outlined'  className='flex flex-col justify-between items-center flex-1 p-2'>
                <h1>Factures en attente</h1>
                <h1>15</h1>
                <p>Nouvelles factures & factures non régles</p>
                <Button variant='outlined' className='border-orange-300 rounded-lg hover:bg-orange-400'>Voir le détails</Button>
            </Paper>
            <Paper variant='outlined' className='flex flex-col justify-between items-center flex-1 p-2'>
                <h1>Factures réglées</h1>
                <h1 className='text-green-600'>04</h1>
                <p>Factures réglées ou en instance de réglement</p>
                <Button variant='outlined' className='border-orange-300 rounded-lg hover:bg-orange-400'>Voir le détails</Button>
            </Paper>
            <Paper variant='outlined' className='flex flex-col justify-between items-center flex-1 p-2'>
                <h1>Contancieux</h1>
                <h1 className='text-orange-500'>01</h1>
                <p>Factures en contancieux</p>
                <Button variant='outlined' className='border-orange-300 rounded-lg hover:bg-orange-400'>Voir le détails</Button>
            </Paper>
            <Paper variant='outlined' className='flex flex-col justify-between items-center flex-1 p-2'>
                <h1>Annulées</h1>
                <h1 className='text-red-600'>00</h1>
                <p>Factures en annulée</p>
                <Button variant='outlined' className='border-orange-300 rounded-lg hover:bg-orange-400'>Voir le détails</Button>
            </Paper>
        </div>
        <div className='grid grid-cols-6 gap-4 mx-4 mb-4'>
            <Paper variant='outlined' className='flex flex-col justify-between items-start rounded-lg col-span-4'>
                <div className='p-2 flex items-center'>
                    <h5 className='text-slate-600'>Chiffre d'affaire</h5>
                </div>
                <Divider style={{marginBottom:10,marginTop:10,width:'100%'}} />
                <div className='m-2 w-full'>
                    <ChiffreAffaire />
                </div>
            </Paper>
            <Paper variant='outlined' className='flex flex-col items-start col-span-2 rounded-lg'>
                <div className='p-2 flex items-center'>
                    <h5 className='text-slate-600'>Chiffre d'affaire par produit</h5>
                </div>
                <Divider style={{marginBottom:10,marginTop:10,width:'100%'}} />
                <div className='m-2 flex justify-center items-center'>
                    <ChiffreAffaireProd />
                </div>
            </Paper>
        </div>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mx-4 mb-4'>
            <Paper variant='outlined' className='flex flex-col justify-between items-start col-span-1 rounded-lg'>
                <div className='p-2 flex items-center'>
                    <h5 className='text-slate-600'>Derniers paiements</h5>
                </div>
                <Divider style={{marginBottom:10,marginTop:10,width:'100%'}} />
            </Paper>
            <Paper variant='outlined' className='flex flex-col justify-between items-start col-span-1 rounded-lg'>
                <div className='p-2 m-2'>
                    <h5 className='text-slate-600'>Suivi des contancieux</h5>
                </div>
                <Divider />
            </Paper>
        </div>
    </div>
  )
}

export default FacturationAccueil