import React,{useState,useEffect} from 'react'
import CustomInput from 'components/CustomInput/CustomInput'
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { Paper, Typography,Divider} from '@material-ui/core';
import { getProduitFormules, getProduitAll } from 'redux/actions/apiActions'
import {
    addPartenaireconvention,
    getPartenaireconventions,
    deleteconvention
  } from 'redux/actions/settingsAction'
  import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete'
import swal from 'sweetalert';
import { makeStyles } from '@material-ui/styles'
import { IconButton } from '@material-ui/core';
import AttachmentIcon from '@material-ui/icons/Attachment';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { PhotoCamera } from "@material-ui/icons";
import { green } from '@material-ui/core/colors';
import AddIcon from '@material-ui/icons/Add'
import Tooltip from '@material-ui/core/Tooltip';
import clsx from 'clsx';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText  from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox'
import CustomRadio from 'components/CustomRadio/CustomRadio'
import ListAltIcon from '@material-ui/icons/ListAlt';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        padding:10,
    },
  },
};

const useStyle =makeStyles(theme=>({
    
    dialogHead:{
      display:'flex',
      justifyContent:'space-between',
      alignItems:'center'
    },
    hPaper:{
      padding:10,
      marginBottom:20,
      borderLeftColor: theme.palette.primary.main,
      borderLeftWidth:8,
      display:'flex'
    },
    bPaper:{
      padding:10,
      borderRadius:0,
      borderRadius:10
    },
    hContainer:{
      display:'flex',
      justifyContent:'space-between',
      alignItems:'center',
    },
    loaddingContainer:{
      display:'flex',
      justifyContent:'space-between',
      alignItems:'center'
    },
    bcontainer:{
      paddingTop:10,
      backgroundColor:'transparent',
      borderRadius:0
    },
    bhead:{
      padding:10
    },
    btContainer:{
      display:'flex',
      justifyContent:'space-between',
      padding:10
    },
    input: {
      display: "none",
    },
    faceImage: {
      color: theme.palette.primary.light,
    },
    logoContainer:{
      marginBottom:20,
      padding:10,
      display:'flex',
      justifyContent:'space-between'
    },
    logoview:{
      display:'flex',
      justifyContent:'flex-end'  
    },
    logo:{
      width:'30%'
    },
    panelContent:{
      marginTop:20
    },
    addbtn:{
      width:'75%',
      padding:10,
      display:'flex',
      justifyContent:'center',
      alignItems:'center',
      background:'transparent',
      borderRadius:15,
      border:'1px solid #f6f6f6',
      backgroundColor:theme.palette.primary.light2,
      color:'#ffffff',
      "&:hover":{
        backgroundColor:theme.palette.primary.main,
        border:'none'
      },
      '&<':{
        color:'#ffffff',
      }
    },
    pbPaper:{
      padding:10,
      borderRadius:0,
      display:'flex',
      flexDirection:'column',
      justifyContent:'center',
      borderRadius:10,
      marginBottom:20
    },
    convPaper:{
      backgroundColor:green[50]
    },
    prbtn:{
      color:green[400],
      '&:hover':{
        color:'#ffffff',
        fontSize:30
      }
    },
    table:{
  
    }
  }))
  const initailInput ={
    codeproduitformule:[],
    codeproduit:""
  }
const AddConventions = ({data,mode,...resProps}) => {
    const classes = useStyle()
    const [produits, setProduits] = React.useState([])
    const [formules, setFormules] = React.useState([])
    const [isadd,setIsadd] =  useState(false)
    useEffect(()=>{
      getProduitAll().then(data => { if (data) { setProduits(data); } })
    },[])
   
    const [inputFieldConv,setInputFieldConv] = React.useState({...initailInput,["type"]:mode==="UP"?"generale":"produit"})
    const handleChangeinputConv =(e)=>{
      const {name,value} = e.target
      setInputFieldConv({...inputFieldConv,[name]:value})
    }
    const handleFormuleProduit = () => {
        if (inputFieldConv['codeproduit'] !== "") {
            getProduitFormules(inputFieldConv.codeproduit).then(data => { if (data) { setFormules(data); } })
        }
    }
      useEffect(() => {
          handleFormuleProduit()
      }, [inputFieldConv.codeproduit])
  
    //attachercher fichier conventions
    const [selectedConvFile, setSelectedConvFile] = React.useState(null);
    const handleCapturefile = ({target}) => {
      setSelectedConvFile(target.files[0]);
      setInputFieldConv({...inputFieldConv,'documents':target.files[0]})
    };
    const [conventionList,setConventionList] = useState([])
   
    const handleSelectConv =()=>{
      if(mode ==='UP'){
        if(resProps.inputField.hasOwnProperty('conventions')){
          setConventionList(resProps.inputField.conventions)
        }
      }else{
        getPartenaireconventions(data['id']).then(res=>{
          if(res){
            setConventionList(res)
          }
        })
      }
    }
    useEffect(()=>{
      handleSelectConv()
    },[resProps.inputField])
    const handleOpenConv = (e)=>{
      e.preventDefault()
      let currentData ={...inputFieldConv,['documents']:selectedConvFile}
      if(mode==='UP'){
        if(currentData['description']){
          let dataConv
          if(resProps.inputField.hasOwnProperty('conventions')){
            dataConv = resProps.inputField['conventions']
          }else{
            dataConv=[]
          }
          currentData['id']=dataConv.length+1
          let newdataconv = [...dataConv,currentData]
          resProps.changedata({...resProps.inputField,['conventions']:newdataconv})
          setInputFieldConv({...initailInput})
          handleSelectConv()
          handleNew()
        }else{
          return false
        }
      }
      if(mode === 'ADD'){
        if(currentData.hasOwnProperty('description')){
            let formdata = new FormData()
            formdata.append('description',currentData['description'])
            formdata.append('codeproduit',currentData['codeproduit'])
            formdata.append('codeproduitformule',currentData['codeproduitformule'])
            formdata.append('tauxcom',currentData['tauxcom'])
            if(currentData.hasOwnProperty('description') && currentData['documents']!==null){
              formdata.append('documents',currentData['documents'])
            }
            addPartenaireconvention(data['id'],formdata).then(res=>{
              if(res){
                handleSelectConv()
                handleNew()
              }
            }).catch(err=>{
              console.log(err);
              swal("Ooops!","Erreur lors de l'enregistrement","error")
            })
        }else{
          swal("Ooops!","Veuillez renseigner le champ description","warning")
        }
      }
    }
    const handleShowDocumments =()=>{
      if(mode!=="UP"){
        
      }else{

      }
    }
    const handleDeleleConvention =(id)=>{
      deleteconvention(id).then(res=>{
        if(res){
          handleSelectConv()
        }else{
          swal("Warning","Suppression echouée",'warning')
        }
      })
    }

    const handleChangeFormule = (event) => {
        const {
          target: { value },
        } = event;
        setInputFieldConv(
          {...inputFieldConv, ['codeproduitformule']:typeof value === 'string' ? value.split(',') : value}
        );
    };
    const handleChangeProduit = (event) => {
      const {
        target: { value },
      } = event;
      setInputFieldConv(
        {...inputFieldConv, ['codeproduit']:typeof value === 'string' ? value.split(',') : value}
      );
  };
    const handleNew = ()=>{
        setInputFieldConv({...initailInput})
        setIsadd(!isadd)
    }

  return (
    <div className={classes.panelContent}>
        {isadd&&(
          <Paper className="flex flex-col max-w-screen-sm mx-auto p-3" variant="outlined">
              <div className="flex flex-col gap-3">
                        <div className='flex flex-row items-center'>
                            <Tooltip title="Editer">
                                <IconButton className='hover:bg-primary-main hover:text-white'onClick={()=>setIsadd(!isadd)}><ListAltIcon /></IconButton>
                            </Tooltip>
                            <h1 className='text-back font-semibold'>Ajouter d'une convention</h1>
                        </div>
                        <CustomRadio
                          required
                          label="Type "
                          id="type"
                          name="type"
                          options={[{ "label": "Convention générale", "value": "generale" }, { "label": "Convention produit", "value": "produit" }]}
                          row
                          value = {inputFieldConv.type}
                          formControlProps={{
                              fullWidth: true
                          }}
                          onChange={handleChangeinputConv}
                      />
                      <CustomInput
                          variant="outlined"
                          id="titre"
                          name='titre'
                          label="Titre"
                          type="text"
                          value = {inputFieldConv.titre}
                          formControlProps={{
                              fullWidth: true
                          }}
                          onChange={handleChangeinputConv}
                      />
                        <TextField
                          variant="outlined"
                          id="description"
                          name='description'
                          label="Description"
                          multiple
                          fullWidth
                          maxRows={4}
                          value = {inputFieldConv.description}
                          onChange={handleChangeinputConv}
                      />
                      <div>

                      </div>
                      <CustomInput
                          variant="outlined"
                          id="tauxcom"
                          name='tauxcom'
                          label="Taux commissions (%)"
                          type="number"
                          value = {inputFieldConv.tauxcom}
                          formControlProps={{
                              fullWidth: true
                          }}
                          onChange={handleChangeinputConv}
                      />
                      <div className='flex flex-col'>
                          <span>Attacher des doccuments</span>
                          <div>
                            <input
                                accept="image/jpeg"
                                className={classes.input}
                                id="faceConvImage"
                                type="file"
                                onChange={handleCapturefile}
                            />
                          <Tooltip title="Selectionnez un fichier">
                              <label htmlFor="faceConvImage">
                                  <IconButton
                                      className={classes.faceImage}
                                      color="primary"
                                      aria-label="upload picture"
                                      component="span"
                                  >
                                      <AttachmentIcon fontSize="large" />
                                  </IconButton>
                              </label>
                          </Tooltip>
                          <label>{selectedConvFile ? selectedConvFile.name : "Cliquez sur l'icon pour joindre un fichier"}</label>. . .
                          </div>
                      </div>
                  </div> 
            </Paper>)}

        {!isadd&&(
        <Paper className={classes.pbPaper} variant="outlined">
            <div className="flex flex-row justify-between">
                 

                  <h1 className='text-back font-semibold'>Liste des conventions / produits</h1>
                 
                    <Tooltip title="Editer">
                      <IconButton className='hover:bg-primary-main hover:text-white' onClick={handleNew} ><AddIcon /></IconButton>
                    </Tooltip>
             </div>
                {(conventionList && conventionList.length!==0)?(<TableContainer component={Paper}>
                  <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">Description</TableCell>
                        <TableCell align="center">Produit</TableCell>
                        <TableCell align="center">Formules</TableCell>
                        <TableCell align="center">Commission</TableCell>
                        <TableCell align="center">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {conventionList.map((conv) => (
                        <TableRow key={conv.id}>
                          <TableCell align="left">{conv.description}</TableCell>
                          <TableCell align="left">{conv.codeproduit&&produits.filter(item=>item.CodeProduit === conv.codeproduit)[0].MonLibelle}</TableCell>
                          <TableCell align="left">{conv.codeproduitformule&&(formules.filter(item=>conv.codeproduitformule.includes(item.CodeProduitFormule)).map(elt=>elt.MonLibelle).join(','))}</TableCell>
                          <TableCell align="left">{conv.tauxcom}</TableCell>
                          <TableCell align="center">
                          <Tooltip title="voir la convension" onClick={handleShowDocumments}> 
                                <IconButton>
                                    <AttachmentIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Supprimer" onClick={(e)=>handleDeleleConvention(conv.id)}> 
                                <IconButton>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>):(
                <Paper className="bg-slate-100  border-dashed border-slate-500 flex flex-col justify-center items-center py-6 m-4" variant="outlined">
                  <Typography>Aucune donnée</Typography>
                  {!isadd&&<Button  onClick={handleNew}>
                      <AddIcon /> Ajouter une convention /produit
                  </Button>}
                </Paper>)}
        </Paper>)}
    </div>
  )
}

export default AddConventions