// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import { Pagination, Navigation, Autoplay } from 'swiper/modules';
// import 'swiper/css';
// import 'swiper/css/pagination';
// import 'swiper/css/navigation';

// const CommercialActionPopup = () => {
//   const [showPopup, setShowPopup] = useState(false);
//   const [commercialActions, setCommercialActions] = useState(null);

//   localStorage.clear();
//   sessionStorage.clear();

//   useEffect(() => {
//     const actionSeen = localStorage.getItem('actionSeen');
//     if (!actionSeen) {
//       axios.get('https://admin.yakoafricassur.com/api/getCommercialActions')
//         .then(response => {
//           setCommercialActions(response.data);
//           setShowPopup(true);
//         })
//         .catch(error => {
//           console.error("Erreur lors de la récupération des actions commerciales:", error);
//         });
//     }
//   }, []);

//   const closePopup = () => {
//     localStorage.setItem('actionSeen', 'true');
//     setShowPopup(false);
//   };

//   if (!showPopup || !commercialActions) {
//     return null;
//   }

//   return (
//     <div style={styles.popup}>
//       <div style={styles.popupContent}>
//         {commercialActions.images && commercialActions.images.length > 0 && (
//           <Swiper
//             spaceBetween={50}
//             slidesPerView={1}
//             loop={true}
//             autoplay={{ delay: 2000 }}
//             pagination={{ clickable: true }}
//             navigation
//             modules={[Pagination, Navigation, Autoplay]}
//             style={styles.swiperContainer}
//           >
//             {commercialActions.images.map((image, index) => (
//               <SwiperSlide key={index}>
//                 <img
//                   src={`https://admin.yakoafricassur.com${image}`}
//                   alt={`Image ${index + 1}`}
//                   style={styles.image}
//                 />
//               </SwiperSlide>
//             ))}
//           </Swiper>
//         )}
//         <p style={styles.description}>{commercialActions.message}</p>
//         <button onClick={closePopup} style={styles.closeButton}>Fermer</button>
//       </div>
//     </div>
//   );
// };

// const styles = {
//   popup: {
//     position: 'fixed',
//     top: 0,
//     left: 0,
//     width: '100vw',
//     height: '100vh',
//     backgroundColor: 'rgba(0, 0, 0, 0.5)',
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     zIndex: 1000,
//   },
//   popupContent: {
//     backgroundColor: '#fff',
//     padding: '20px',
//     borderRadius: '10px',
//     maxWidth: '700px',
//     width: '100%',
//     textAlign: 'center',
//     position: 'relative',
//   },
//   image: {
//     maxWidth: '100%',
//     maxHeight: '60vh',
//     Height: '60vh',
//     borderRadius: '8px',
//   },
//   description: {
//     fontSize: '16px',
//     color: '#333',
//     marginBottom: '20px',
//   },
//   closeButton: {
//     padding: '10px 20px',
//     backgroundColor: '#f50057',
//     color: '#fff',
//     border: 'none',
//     borderRadius: '5px',
//     cursor: 'pointer',
//     fontSize: '16px',
//   },
//   swiperContainer: {
//     marginBottom: '20px',
//   },
// };

// export default CommercialActionPopup;


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const CommercialActionPopup = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [commercialActions, setCommercialActions] = useState(null);

  localStorage.clear();
  sessionStorage.clear();

  useEffect(() => {
    const actionSeen = localStorage.getItem('actionSeen');
    if (!actionSeen) {
      axios.get('https://admin.yakoafricassur.com/api/getCommercialActions')
        .then(response => {
          setCommercialActions(response.data);
          setShowPopup(true);
        })
        .catch(error => {
          console.error("Erreur lors de la récupération des actions commerciales:", error);
        });
    }
  }, []);

  const closePopup = () => {
    localStorage.setItem('actionSeen', 'true');
    setShowPopup(false);
  };

  if (!showPopup || !commercialActions) {
    return null;
  }

  return (
    <div style={styles.popup}>
      <div style={styles.popupContent}>
        {commercialActions.images && commercialActions.images.length > 0 && (
          <Swiper
            spaceBetween={50}
            slidesPerView={1}
            loop={true}
            autoplay={{ delay: 2000 }}
            pagination={{ clickable: true }}
            navigation
            modules={[Pagination, Navigation, Autoplay]}
            style={styles.swiperContainer}
          >
            {commercialActions.images.map((image, index) => (
              <SwiperSlide key={index}>
                <div style={styles.imageContainer}>
                  <img
                    src={`https://admin.yakoafricassur.com${image}`}
                    alt={`Image ${index + 1}`}
                    style={styles.image}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
        <p style={styles.description}>{commercialActions.message}</p>
        <button onClick={closePopup} style={styles.closeButton}>Fermer</button>
      </div>
    </div>
  );
};

const styles = {
  popup: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  popupContent: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '10px',
    maxWidth: '700px',
    width: '90%',
    textAlign: 'center',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  imageContainer: {
    width: '100%',
    height: '60vh',  // Hauteur fixe pour uniformiser les images
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    borderRadius: '8px',
    marginBottom: '20px',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  description: {
    fontSize: '16px',
    color: '#333',
    marginBottom: '20px',
  },
  closeButton: {
    padding: '10px 20px',
    backgroundColor: '#f50057',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '16px',
  },
  swiperContainer: {
    marginBottom: '20px',
  },
};

export default CommercialActionPopup;
