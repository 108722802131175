import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { reduxForm,change,getFormValues, } from 'redux-form';
import {connect} from 'react-redux'
import Typography from '@material-ui/core/Typography';
import { green, pink } from '@material-ui/core/colors';
import { Divider, Paper } from "@material-ui/core";
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import IconButton from '@material-ui/core/IconButton';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import BookmarksIcon from '@material-ui/icons/Bookmarks';
import MoneyIcon from '@material-ui/icons/Money';

const useStyles = makeStyles((theme) =>({
    root: {
      marginBottom:20,
      '& :hover':{
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        border: 0,
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        padding: '10 30px',
        textDecoration:'none'
      }
    },
    
    media: {
      height: 140,
    },
    iconContain: {
        display:'flex',
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        alignSelf:'center'
      },
      pink: {
        color:theme.palette.getContrastText(pink[500]),
        backgroundColor: pink[500],
      },
      green: {
        color: '#fff',
        backgroundColor: green[500],
      },
      large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
      },
      paper:{
          padding:10,
          marginBottom:20
      },
      line:{
          top:0,
        marginBottom:20
      
    },
    buttonDetails:{
        display:'flex',
        alignItems:'center',
        justifyContent:'flex-start',
        borderRadius:0,
        color:theme.palette.secondary.main,
        '&:hover':{
          borderColor:theme.palette.primary.main,
        }
      },
  }));
function FormationFonctions(props){
    const classes = useStyles();
    return(
        <div  style={{width:'75%', marginTop:75,marginLeft:'auto',marginRight:'auto'}}>
        <GridContainer spacing={3}>
            <GridItem xs={12} sm={12} md={6} lg={6}>
                <Paper className={classes.paper}>
                    <Typography varaiant='h1' component='h1'>PRODUIT YAKO</Typography>
                    <Divider light className={classes.line} />
                    <GridContainer>
                        <GridItem xs={6} sm={3} md={3} lg={3}>
                            <IconButton color='inherit' className={classes.buttonDetails} onClick={()=>props.handleDetails(questionReponseYAKO,'YKE',"DISCOV",0)}>
                                <AssignmentLateIcon color='primary' style={{ fontSize: 50 }} />
                                <Typography gutterBottom display='block' align='center' variant='caption' component='p'>Fiche produit</Typography>
                            </IconButton>
                        </GridItem>
                        <GridItem xs={1} sm={1} md={1} lg={1}> <Divider className={classes.line} light  orientation="vertical" /></GridItem>
                        <GridItem xs={6} sm={3} md={3} lg={3}>
                            <IconButton color='inherit' className={classes.buttonDetails} onClick={()=>props.handleDetails(questionReponseYAKO,'YKE',"DISCOV",1)}>
                                <LiveHelpIcon color='primary' style={{ fontSize: 50 }} />
                                <Typography gutterBottom display='block' align='center' variant='caption' component='p'>Q & R</Typography>
                            </IconButton>
                        </GridItem>
                        <GridItem xs={1} sm={1} md={1} lg={1}> <Divider className={classes.line} light  orientation="vertical" /></GridItem>
                        <GridItem xs={6} sm={4} md={4} lg={4}>
                            <IconButton color='inherit' className={classes.buttonDetails} onClick={()=>props.handleDetails(questionReponseYAKO,'YKE',"DISCOV",2)}>
                                <BookmarksIcon color='primary' style={{ fontSize: 50 }} />
                                <Typography gutterBottom display='block' align='center' variant='caption' component='p'>Support de formation</Typography>
                            </IconButton>
                        </GridItem>
                        <GridItem xs={6} sm={4} md={4} lg={4}>
                            <IconButton color='inherit' className={classes.buttonDetails} onClick={()=>props.handleDetails(questionReponseYAKO,'YKE',"DISCOV",3)}>
                                <MoneyIcon color='primary' style={{ fontSize: 50 }} />
                                <Typography gutterBottom display='block' align='center' variant='caption' component='p'>Guide tarifaire</Typography>
                            </IconButton>
                        </GridItem>
                    </GridContainer>
                </Paper>
            </GridItem>
            <GridItem xs={12} sm={12} md={6} lg={6}>
                <Paper className={classes.paper}>
                    <Typography varaiant='h1' component='h1'>PLAN VERT RETRAITE</Typography>
                    <Divider light className={classes.line} />
                    <GridContainer>
                        <GridItem xs={6} sm={3} md={3} lg={3} >
                            <IconButton color='inherit' className={classes.buttonDetails} onClick={()=>props.handleDetails(questionReponsePVR,'PVR',"DISCOV",0)}>
                                <AssignmentLateIcon color='primary' style={{ fontSize: 50 }} />
                                <Typography gutterBottom display='block' align='center' variant='caption' component='p'>Fiche produit</Typography>
                            </IconButton>
                        </GridItem>
                        <GridItem xs={1} sm={1} md={1} lg={1}> <Divider className={classes.line} light  orientation="vertical" /></GridItem>
                        <GridItem xs={6} sm={3} md={3} lg={3}>
                            <IconButton color='inherit' className={classes.buttonDetails} onClick={()=>props.handleDetails(questionReponsePVR,'PVR',"DISCOV",1)}>
                                <LiveHelpIcon color='primary' style={{ fontSize: 50 }} />
                                <Typography gutterBottom display='block' align='center' variant='caption' component='p'>Q & R</Typography>
                            </IconButton>
                        </GridItem>
                        <GridItem xs={1} sm={1} md={1} lg={1}> <Divider className={classes.line} light  orientation="vertical" /></GridItem>
                        <GridItem xs={6} sm={4} md={4} lg={4}>
                            <IconButton color='inherit' className={classes.buttonDetails} onClick={()=>props.handleDetails(questionReponsePVR,'PVR',"DISCOV",2)}>
                                <BookmarksIcon color='primary' style={{ fontSize: 50 }} />
                                <Typography gutterBottom display='block' align='center' variant='caption' component='p'>Support de formation</Typography>
                            </IconButton>
                        </GridItem>
                       
                    </GridContainer>
                </Paper>
            </GridItem>
        </GridContainer>
         
        </div>
    )
}


const updateField =(field, data)=>(dispatch)=>dispatch(change('adForm', field, data))
const mapPropsActions ={
    updateField,
}
const mapStateToProps = (state) => ({
    api: state.api,
    user:state.user,
    data:getFormValues('adForm')(state)
  });

  export default connect(mapStateToProps,mapPropsActions)(reduxForm({destroyOnUnmount: false,})(FormationFonctions))



  const questionReponseYAKO = [
      {"question":"Contexte","reponse":"L’importance accordée aux défunts est une réalité culturelle en Côte d’Ivoire. De ce fait, certaines personnes n’hésitent pas à s’endetter, à se ruiner ou à aliéner des projets importants afin de pouvoir organiser les obsèques de leurs proches et donc éviter le déshonneur et les railleries. Sur la base  de ces observations, LA LOYALE VIE a conçu le produit YAKO Bancassurance dans le but d’apporter une réponse efficace à cette préoccupation majeure."},
      {"question":"En quoi consiste le  produit YAKO Bancassurance ?","reponse":"YAKO Bancassurance est un contrat de prévoyance obsèques qui garantit la fourniture de prestations d’obsèques en nature et en numéraire en cas de décès de l’assuré."},
      {"question":"Qui peut souscrire à YAKO Bancassurance","reponse":"Toute personne physique domiciliée en Côte d’Ivoire titulaire d’un compte chèque à la BNI, âgée d’au moins 18 ans."},
      {"question":"Quelles sont les formules proposées ?","reponse":"Il existe trois (3) formules de YAKO Bancassurance notamment:\n - YAKO Avantage : Cette formule concerne uniquement  le client de la banque qui est à la fois le souscripteur et l’assuré. \n- YAKO Famille : Cette formule couvre l’adhérent c'est-à-dire le client de la banque et sa famille (conjoint et ses enfants)\n NB : L’adhérent peut assurer tous ses enfants à condition que ceux-ci remplissent le critère de l’âge. Cependant, il devra payer une prime supplémentaire par enfant  à partir du cinquième.\n - YAKO Alliance : Cette formule concerne les personnes physiques autres que l’adhérent, son conjoint et ses enfants."},
      {"question":"Quelles sont les conditions à remplir pour être assuré ?","reponse":"YAKO Avantage : Etre âgé de 18 à 59 ans révolus au moment de la souscription.\n YAKO Famille : les conjoints doivent être âgés de 18 à 59 ans révolus et les enfants de 3 à 21 ans inclus, au moment de la souscription.\nYAKO Alliance : les assurés doivent avoir entre 12 et 59 ans révolus au moment de la souscription. \n - La garantie optionnelle Remboursement\nLa garantie optionnelle  dite remboursement, lorsqu’elle est souscrite permet au souscripteur de greffer une épargne à son contrat de base\nL’avantage de l’option remboursement est qu’elle permet aux ayants-droit de bénéficier, en  cas de décès de l’assuré avant son soixante-dixième(70ième) anniversaire, de l’épargne constituée en plus des prestations prévues au contrat.\nLe choix de cette garantie optionnelle donne droit au paiement d’une prime supplémentaire. Cette prime est fonction du capital décès souhaité au terme du contrat (Il varie de 100 000 à 1 000 000 FCFA),  et de la durée du contrat (70  - âge de l’assuré au moment de la souscription par différence de millésime)."},
      {"question":"Quelle est la durée du contrat ?","reponse":"Le contrat YAKO Bancassurance est souscrit pour une durée de 1 an et se renouvelle par tacite reconduction jusqu’au soixante-dixième (70ième) anniversaire de l’assuré."},
      {"question":"Combien coûte le produit Yako Bancassurance ?","reponse":"Le montant de la prime est fonction de la formule souscrite et de l’option choisie (Honneur, Elite et Prestige). Il varie de 500 FCFA à 4707 FCFA par mois."},
      {"question":"Comment fonctionne les prestations ?","reponse":"Suite à la déclaration du décès de l’assuré,  LA LOYALE VIE par l’intermédiaire d’un opérateur des pompes funèbres (IVOSEP ou autre…), permet à la famille de bénéficier  de prestations en nature telles que:\n - 	Enlèvement et traitement du corps\n -	Conservation du corps (de 7 à 15 jours selon l’option choisie) \n -Levée de corps \n - 	Allocation Cercueil-Transport (100 000 FCFA, 300 000 FCFA ou 500 000 FCFA selon l’option choisie) \n et des prestations en numéraire  d’une valeur de 30 000 FCFA, 100 000 FCFA ou 500 000 FCFA selon l’option choisie."},
      {"question":"Comment souscrire au contrat et comment payer sa prime ?","reponse":"C’est simple, il suffit de : \n - •	Se rendre dans une agence BNI et Renseigner un  bulletin d’adhésion\n - •	Payer sa prime d’assurance par virement bancaire."},
      
    ]
    const questionReponsePVR =[
      {"question":"Contexte","reponse":"A la retraite, le revenu chute drastiquement compte tenu du faible niveau des pensions servies par nos caisses de retraite (CGRAE, CNPS). Cependant, le coût de la vie devient de plus en plus élevé et certaines dépenses apparaissent notamment celles liées à la santé due au vieillissement.\n\rLa retraite apparait ainsi comme  une étape importante de la vie du travailleur. Il est donc primordial de bien la préparer pendant la phase d’activité"},
      {"question":"En quoi consiste le produit Plan Vert Retraite BNI ?","reponse":"Conçu pour vous aider à mieux préparer votre retraite, Plan Vert Retraite vous permet de constituer en toute liberté une épargne de longue durée au terme de laquelle vous devenez titulaire d’une rente ou d’un capital."},
      {"question":"Qui peut souscrire à Plan Vert Retraite ?","reponse":"Toute personne physique domiciliée en Côte d’Ivoire titulaire d’un compte chèque à la BNI, âgée d’au moins 18 ans."},
      {"question":"Quelle est la nature des risques garantis ?","reponse":" - •	Garantie en cas de vie : En cas de vie de l’assuré  au terme du contrat, LA LOYALE VIE garantit le paiement d’une rente certaine ou d’un capital.\n -•	Garantie en cas de décès : En cas de décès ou d’invalidité absolue et définitive avant le terme du contrat, LA LOYALE VIE garantit le paiement de l’épargne constituée à la date du décès aux bénéficiaires désignés."},
      {"question":"Quelle est le montant de la rente, la durée de service de la rente et la durée du contrat ?","reponse":"Le montant de la rente mensuelle minimum est de  50 000 FCFA. Au-delà  de ce minimum, vous avez la possibilité de choisir jusqu’à 1 000 000 FCFA.\n\rVous avez la possibilité de percevoir votre rente au terme du contrat sur 12 mois, 24 mois et 36 mois.\n\rLa durée du contrat est  égale au nombre d’années résiduelles d’activités tout en tenant compte du minimum obligatoire qui est de 5 ans. "},
      {"question":"Combien je cotise ?","reponse":"Le montant de la cotisation est déterminé en fonction de la durée du contrat, du montant de la rente mensuelle et de la durée de service de la rente. Plan Vert Retraite est un contrat accessible à toutes les bourses avec des cotisations à partir de  1092 FCFA / mois. "},
      {"question":"Quelles sont les opérations prévues au contrat Plan Vert Retraite ?","reponse":"Lorsque deux primes annuelles ou 15% de l’ensemble des primes prévues au contrat ont été versées, vous  avez la possibilité d’effectuer des opérations de Rachat (Partiel ou Total)."},
      {"question":"Comment souscrire au contrat et comment payer sa prime ?","reponse":"Il suffit simplement de :\n\r -Se rendre dans une agence BNI  et Renseigner un  bulletin d’adhésion auprès de son conseiller\n -•	Payer sa prime d’assurance par virement bancaire."}
    ]