import React,{useState,useEffect, useCallback} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import Button from "components/CustomButtons/Button.js";
import { Field, reduxForm, change, getFormValues,registerField,reset} from 'redux-form';
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomSelect from 'components/CustomSelect/CustomSelect.js'
import { connect, useSelector } from 'react-redux'
import CustomDate from 'components/CustomDate/CustomDate'
import dayjs from 'dayjs'
import {getcampagneList,} from 'redux/actions/apiActions'
import CustomPhoneInput from 'components/CustomPhoneInput/CustomPhoneInput'
import CustomRadio from 'components/CustomRadio/CustomRadio'
import { 
  Select,
  OutlinedInput,
  InputLabel,
  FormControl,
  ListItemText,
  Checkbox,
  IconButton,
  TextField
 } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import TitleBox from 'components/TitleBox';
import RefreshIcon from '@material-ui/icons/Refresh';
import {saveProspect} from 'redux/actions/apiActions'
import swal from 'sweetalert';

const useStyle = makeStyles((theme) => ({
  mainBox:{
    display:'flex',
    flexDirection:'column',
    width:'75%',
    marginTop:35,
    [theme.breakpoints.down('sm')]:{
      width:'100%',
      marginTop:20,
    },
    marginLeft:'auto',
    marginRight:'auto',
    
  },
  fieldCard:{
    marginTop:20,
    marginBottom:20,
  },
  cardBox:{
    borderRadius:10,


  },
  btnback:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    backgroundColor:"#f9b233",
    paddingRight:20,
    paddingLeft:20,
    paddingTop:10,
    paddingBottom:10,
    color:'#ffffff',
    "&:hover":{
      backgroundColor:"#3c3c3b",
    },
    borderRadius:10,
  },
  btnnext:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    backgroundColor:theme.palette.primary.main,
    paddingRight:20,
    paddingLeft:20,
    paddingTop:10,
    paddingBottom:10,
    color:'#ffffff',
    "&:hover":{
      backgroundColor:"#f9b233",
    },
    borderRadius:10,
  },
  icons:{
    fontSize:30,
    color:theme.palette.primary.main,
  }
}))

const initalValue ={
  "produit":[],
} 
function AddProspect(props) {
  const [filterField,setFilterField] = useState(initalValue)
  const classes = useStyle()
  let mesVilles = useSelector(state => state.api.villes)
  let produits = useSelector(state => state.api.produits)
  const {handleSubmit,data,handleCliqueFonction} = props
  const [campagnes,setCampagnes] =useState(null)
  const handleCampagnes = useCallback(()=>{
    getcampagneList().then(res=>{
      if(res){
        setCampagnes(res)
      }
    })
  },[])
  useEffect(()=>{
    handleCampagnes()
  },[])

  
  const handleChange = (event) => {
    const {
      target: { value,name },
    } = event;
    setFilterField({...filterField,[name]:(typeof value === 'string' ? value.split(',') : value)});
    props.dispatch(registerField("adPros",name,'Field'));
    props.updateField(name,(typeof value === 'string' ? value.split(',') : value))
  };
 const handleResset =()=>{
  props.dispatch(reset('adPros'))
  setFilterField(initalValue)
 }

 const handlePropsSubmit = (e) =>{
  e.preventDefault()
  let currentproduit
  let allproduit=[]
  if(data.hasOwnProperty('produit')){
    if(data['produit'] && data['produit'].length!==0){
      data['produit'].forEach(el=>{
        if(el){
          const tabprod = el.split("-")
          currentproduit = produits.filter(item=>(item.MonLibelle.trim() == tabprod[0].trim() && item.Formule.trim() == tabprod[1].trim()))[0]
          currentproduit&&allproduit.push(currentproduit)
        }
      })
    }
  }
  
  data['produit']=allproduit
  saveProspect(data).then(res=>{
    if(res){
      swal("Succès","Prospect enregistré avec succès",'succes')
      handleResset()
    }else{
      swal("Erreur","Une erreur s'est produite lors de l'enregistrement",'error')
    } 
  }).catch(err=>{
    swal("ERREUR","Une erreur s'est produite lors de l'enregistrement",'error')
  })
}
  return (
    <div className={classes.mainBox}>
      
      <TitleBox titre ="Nouveau prospect" retourner={()=>handleCliqueFonction('LIST')} showHomeBtn={true} />

      <form onSubmit={handlePropsSubmit}>
      <div className={clsx([classes.mainBox,classes.fieldCard])}>
        <div style={{display:'flex',justifyContent:'space-between',alignItems:'flex-end'}}>
          <h1>Informations du prospect</h1> 
          <IconButton onClick={handleResset}><RefreshIcon className={classes.icons} /></IconButton>
        </div>
        
        <Paper elevation={0} className={classes.cardBox}>
          <div style={{display:'flex'}}>
          <Field
              required
              label="Nom"
              id="nom"
              name="nom"
              variant="outlined"
              formControlProps={{
                  fullWidth: true
              }}
              component={CustomInput}
              style={{marginRight:20}}
          />
            <Field
              required
              label="Prenom"
              id="prenom"
              name="prenom"
              variant="outlined"
              formControlProps={{
                  fullWidth: true
              }}
              component={CustomInput}
            />
          </div>
         
          <Divider />
          <div style={{display:'flex'}}>
          <Field
                  required
                  label="Mobile 1"
                  id="telephone"
                  name="telephone"
                  variant="outlined"
                  component={CustomPhoneInput}
                  formControlProps={{
                      fullWidth: true
                  }}
                  style={{marginRight:20}}
              />
              
              <Field
                label="Email"
                id="email"
                name="email"
                variant="outlined"
                formControlProps={{
                    fullWidth: true
                }}
                component={CustomInput}
              />
          </div>
          <div style={{display:'flex'}}>
          <Field
                required
                label="Lieu de résidence"
                id="lieuresidence"
                name="lieuresidence"
                variant="outlined"
                formControlProps={{
                    fullWidth: true
                }}
                component={CustomSelect}
                options={mesVilles}
                style={{marginRight:20}}
              />
          </div>
        </Paper>
        <h1>Prospection</h1> 
        <Paper elevation={0} className={classes.cardBox}>
          <div>
          
            <Field 
            label="Type / campagne"
            labelId="campagne"
            id="campagne"
            name="campagne"
            variant="outlined"
            select
            fullWidth
            style={{flex:1,marginRight:20,marginBottom:20,paddingLeft:10}}
            value={filterField['campagne']||""}
            onChange={handleChange}
            component={TextField}>
                {(campagnes && campagnes.length!==0)?campagnes.map(campagne=><MenuItem key={campagne['id']} value={`${campagne['id']}`}>
                  <ListItemText primary={`${campagne['libelle']}`} />
                </MenuItem>): <MenuItem key={1} value=''>
                    <ListItemText primary='Aucune donnée fournie' />
                </MenuItem>}
            </Field>
           
       
          </div>
          <div style={{display:'flex'}}>
            <Field
                label="Date prospection"
                id="dateprospection"
                name="dateprospection"
                variant="outlined"
                formControlProps={{
                    fullWidth: true
                }}
                defaultvalue ={dayjs().format('YYYY-MM-DD')}
                InputProps={{
                  type: 'date',
                  inputProps: { min: dayjs().format('YYYY-MM-DD') },
                }}
                component={CustomDate}
                style={{marginRight:20}}
            />
            <Field
                required
                label="Ville"
                id="ville"
                name="ville"
                variant="outlined"
                formControlProps={{
                    fullWidth: true
                }}
                component={CustomSelect}
                options={mesVilles}
              />
          </div>
          <div style={{display:'flex'}}>
            <Field
                required
                label="Lieu de l'événement"
                id="lieuevenement"
                name="lieuevenement"
                variant="outlined"
                formControlProps={{
                    fullWidth: true
                }}
                component={CustomInput}
              />
          </div>
          <Divider />
          <div>
          <Field
              label="Nature du prospecté "
              id="statut"
              name="statut"
              options={[{ "label": "Suspect", "value": "Suspect" }, { "label": "Prospect", "value": "Prospect" }, { "label": "Déjà client", "value": "Client" }]}
              formControlProps={{
                  fullWidth: true
              }}
              row
              component={CustomRadio}
          />
          </div>
        </Paper>
        <Typography variant='caption'>Le propsect est intéresé par ...</Typography> 
        <Paper elevation={0} className={classes.cardBox}>
        <FormControl sx={{ m: 1}} fullWidth>
            <InputLabel id="produit">Produit</InputLabel>
            <Select
              label="Produit"
              labelId="produit"
              id="produit"
              name="produit"
              variant="outlined"
              select
              fullWidth
              style={{flex:1,marginRight:20,marginBottom:20,paddingLeft:10}}
              multiple
              value={filterField['produit']||[]}
              onChange={handleChange}
              input={<OutlinedInput label="Produit" />}
              renderValue={(selected) => selected.join(', ')}
            >

                {(produits && produits.length!==0)?produits.map(prod=><MenuItem key={prod['CodeProduit']} value={`${prod['MonLibelle']} - ${prod['Formule']}`}>
                  <Checkbox checked={filterField['produit'].indexOf(`${prod['MonLibelle']} - ${prod['Formule']}`) > -1} />
                  <ListItemText primary={`${prod['MonLibelle']} - ${prod['Formule']}`} />
                </MenuItem>): <MenuItem key={1} value=''>
                    <ListItemText primary='Aucune donnée fournie' />
                </MenuItem>}
          </Select>
        </FormControl>
        
        
        </Paper>
        <Paper elevation={0} className={classes.cardBox}>
           <div style={{display:'flex',justifyContent:'space-between'}}>
           <Button className={classes.btnback}>Annuler</Button>
            <Button className={classes.btnnext} color='primary' type='submit'>Valider</Button></div>
        </Paper>
      </div>
      </form>
    </div>
  )
}

const updateField = (field, data) => (dispatch) => dispatch(change('adPros', field, data))
const mapPropsActions = {updateField}
const mapStateToProps = (state) => ({
    api: state.api,
    user: state.user,
    data: getFormValues('adPros')(state),
    ui: state.UI
});

export default connect(mapStateToProps, mapPropsActions)(reduxForm({
    form: 'adPros', // a unique identifier for this form
    destroyOnUnmount: false,
})(AddProspect))

