import React from 'react'
import { useRouteMatch,Switch,Route } from 'react-router-dom'
import AddPropositionBanking from 'pages/Banking/Propositions/AddProposition'
import AddPropositionCom from 'pages/Commercial/Propositions/AddProposition'
import Simulateur from 'pages/Banking/Propositions/Simulateur'
import SimulateurCom from 'pages/Commercial/Propositions/Simulateur'
import ProductionHome from 'pages/Banking/ProdHome'
import ProductionCom from 'pages/Commercial/Propositions/ProductionHome'
import {connect} from 'react-redux'
import PropositionList from 'pages/Banking/Propositions/PropositionList'
import ContratList from 'pages/Commons/Contrats/ContratList'
import RechercheContrat from 'pages/Commons/Contrats/RechercheContrat'

function Esouscription(props) {
    const {path} =useRouteMatch()
    const {ui: { layoutRoot } } = props

  return (
    <div>
        <Switch>
            {layoutRoot==='bankass'&&<Route path={`${path}/nouvelle-proposition`} component={AddPropositionBanking} name='Nouvelle proposition' />}
            {layoutRoot==='bankass'&&<Route path={`${path}/modification-proposition`} component={AddPropositionBanking} name='Modifier une proposition' />}

            {(layoutRoot==='diaspora' && layoutRoot==='com')&&<Route path={`${path}/nouvelle-proposition`} component={AddPropositionCom} name='Nouvelle proposition' />}
            {(layoutRoot==='diaspora' && layoutRoot==='com')&&<Route path={`${path}/modification-proposition`} component={AddPropositionCom} name='Modifier une proposition' />}
            
            {(layoutRoot==='diaspora')&&<Route path={`${path}/simulateurs`} component={SimulateurCom} name='Simulateur' />}
            {layoutRoot==='bankass'&&<Route path={`${path}/simulateurs`} component={Simulateur} name='Simulateur' />}
            <Route path={`${path}/production`} component={PropositionList} name='Liste des propositions' />
            <Route path={`${path}/contrats`} component={ContratList} name='Liste des contrats' />
            <Route path={`${path}/recherche-contrat`} component={RechercheContrat} name='Rechercher un contrat' />
            {layoutRoot==='bankass'&&<Route path={`${path}`} component={ProductionHome} name='production' />}
            {(layoutRoot==='diaspora' && layoutRoot==='com')&&<Route path={`${path}`} component={ProductionCom} name='production' />}
        </Switch>
    </div>
  )
}

const mapActionsToProps = {}
const mapStateProps =(state)=>({
    user:state.user,
    ui: state.UI,
 })
export default connect(mapStateProps,mapActionsToProps)(Esouscription)  
