import React,{useState} from "react";
import { MenuItem, Menu } from "@material-ui/core";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import Divider from '@material-ui/core/Divider';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import {connect,useDispatch} from 'react-redux'
import { getUtilisateur,deleteUtilisateur,debloquerUser,bloquerUser } from "redux/actions/settingsAction";
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Rotate90DegreesCcwIcon from '@material-ui/icons/Rotate90DegreesCcw';
import LockIcon from '@material-ui/icons/Lock';
import ChangerEquipeUser from './ChangerEquipeUser'
import ChangerProfilUser from './ChangerProfilUser'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import ChangerPassword from "./ChangerPassword";
function CustomMenu (props){
  const {data} = props;   
  const dispatch =useDispatch()
  const [anchorEl, setAnchorEl] = React.useState(null);
  //active modification
  const handleRopen =(e,reason)=>{
    props.history.push(`/settings/utilisateur/update/${data.idmembre}`,{data:data})
  }

  //active changement d'equipe
  const [eopen, setEopen] = useState(false)
  const handleEopen =(e,reason)=>{
    if (reason && reason == "backdropClick") {
      return;
    }
    setEopen(true)
    handleClose()
  }
  const handleCloseEopen =(e,reason)=>{
    if (reason && reason == "backdropClick") {
      return;
    }
    setEopen(false)
    handleClose()
  }

  //active changement de profil
  const [popen, setPopen] = useState(false)
  const handlePopen =()=>{
    setPopen(true)
    handleClose()
  }
  const handleClosePopen =()=>{
    setPopen(false)
    handleClose()
  }


  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  }
  const handleClose = () => {
    setAnchorEl(null);
  }
    
  const handleDelete=()=>{
    deleteUtilisateur(data.idmembre).then(mrx=>{
      if(mrx){
        dispatch(getUtilisateur())
      }
    })
  }
  const handlelocked =()=>{
    bloquerUser(data.idmembre).then(mrx=>{
      if(mrx){
        dispatch(getUtilisateur())
      }
    })
  }
  const handleUnLocked =()=>{
    debloquerUser(data.idmembre).then(mrx=>{
      if(mrx){
        dispatch(getUtilisateur())
      }
    })
  }
  const [renipass,setRenipass] = useState(false)
  const handleOpenReniPass = ()=>{
    setRenipass(true)
  }
  const handleCloseReniPass = ()=>{
    setRenipass(false)
  }
  
      return(
        <React.Fragment>
         {eopen&&<ChangerEquipeUser userData={data} mopen={eopen} handleCloseMod={handleCloseEopen}/>}
         {popen&&<ChangerProfilUser userData={data} mopen={popen} handleCloseMod={handleClosePopen}/>}
         {renipass&&<ChangerPassword userData={data} open={renipass} handleClose={handleCloseReniPass} />}
          <IconButton
            data-id={data.id}
            aria-label="actions"
            aria-controls='long-menu'
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="card-actions-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
          
              <MenuItem onClick={handleRopen}>
              <ListItemIcon>
                  <NoteAddIcon fontSize="small" color='primary' />
              </ListItemIcon>
              <Typography variant="inherit">Modifier</Typography>
            </MenuItem>   
            <MenuItem disabled>
                <Divider />
            </MenuItem>
            <MenuItem onClick={handleEopen}>
                <ListItemIcon>
                    <Rotate90DegreesCcwIcon fontSize="small" color='primary' />
                </ListItemIcon>
                <Typography variant="inherit">Changer d'agence</Typography>
            </MenuItem>
            <MenuItem onClick={handlePopen}>
                <ListItemIcon>
                    <SupervisorAccountIcon fontSize="small" color='primary' />
                </ListItemIcon>
                <Typography variant="inherit">Changer de profil</Typography>
            </MenuItem>
            <MenuItem onClick={handleOpenReniPass}>
                <ListItemIcon>
                    <VpnKeyIcon fontSize="small" color='primary' />
                </ListItemIcon>
                <Typography variant="inherit">Réinitialiser le mot de passe</Typography>
            </MenuItem>
            <MenuItem disabled>
                <Divider />
            </MenuItem>
            {(data && data.memberok===0)&& <MenuItem onClick={handlelocked}>
                <ListItemIcon>
                    <LockIcon fontSize="small" color='primary' />
                </ListItemIcon>
                <Typography variant="inherit">Bloquer</Typography>
            </MenuItem>}
           
            {(data && data.memberok===0)&&<MenuItem onClick={handleUnLocked}>
                <ListItemIcon>
                    <LockOpenIcon fontSize="small" color='primary' />
                </ListItemIcon>
                <Typography variant="inherit">Debloquer</Typography>
            </MenuItem>}
              
            <MenuItem disabled>
                <Divider />
            </MenuItem>
            <MenuItem onClick={handleDelete} >
                <ListItemIcon>
                      <DeleteIcon fontSize="small" color='primary' />
                </ListItemIcon>
                <Typography variant="inherit">Supprimer</Typography>
              </MenuItem>
          </Menu>
      </React.Fragment>
    );
      
  }
 
  const mapStateToProps = (state) => ({});
  const mapActionsToProps = {}
  export default connect(mapStateToProps,mapActionsToProps)(CustomMenu)
