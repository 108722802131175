import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import { updateOperation, uploadDocuments, getDocument, deleteDocument } from 'redux/actions/apiActions'

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { reduxForm, change, registerField, reset } from 'redux-form';
import { connect, useDispatch } from 'react-redux'
import AlertPage from 'components/AlertPage'
import CircularProgress from '@material-ui/core/CircularProgress';
import OperaTransmission from './components/OperaTransmission';
import EditIcon from '@material-ui/icons/Edit';
import { useHistory } from 'react-router-dom'
import Core from 'pages/components/core'
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import dayjs from 'dayjs';
import { Box } from '@material-ui/core'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { PDFReader } from 'react-read-pdf'
import SettingsOverscanIcon from '@material-ui/icons/SettingsOverscan';
import Popover from '@material-ui/core/Popover';
import config from 'util/config'
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import CloseIcon from '@material-ui/icons/Close';
import Button from "components/CustomButtons/Button.js";
import { Grid } from '@material-ui/core'
import ModalIdentification from "./components/modal/ModalIdentification"
import ModalInfosOperation from "./components/modal/ModalInfosOperation"
import ModalDetailOperation from "./components/modal/ModalDetailOperation"
import ModalInfoDeclarant from "./components/modal/ModalInfoDeclarant"
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
const customTyles = (theme, styles) => ({
  core: {
    display: 'flex',
    justifyContent: 'center'
  },
  paper: {
    marginBottom: 20,
    padding: 20
  },
  heading: {
    fontSize: 26,
    fontWeight: 'bold',
  },

  hearderContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  headerTitle: {
    display: 'flex',
    flex: 1,
    alignItems: 'center'
  },
  coreCustum: {
    borderRadius: 0,
    borderLeftWidth:'10px',
    borderLeftStyle:'solid',
    borderLeftColor:theme.palette.primary.main
  },
  itemTitle: {
    fontStyle: "italic"
  },
  assureInfoRow: {
    display: 'flex',
    flexDirection: 'row'
  },
})
const useStyles = makeStyles(customTyles)
function EditOperation(props) {
  const { mode, handleCliqueFonction, cdata, api: { produits },user:{credentials} } = props
  let containerref = useRef()
  const dispatch = useDispatch()
  const [copen, setCppen] = React.useState(false);
  const { url } = config
  const [currentData, setCurrentData] = React.useState(cdata)
  const [mesDocument, setMesDocuments] = useState(null)
  useEffect(() => {
    if (cdata) {
      setLoading(true)
      getDocument(cdata.id, 'OP').then(res => {
        if (res) {
          setMesDocuments(res.documents)
          setLoading(false)
        } else {
          setLoading(false)
        }
      }).catch(err => {
        console.log(err);
        setLoading(false)
      })
    }
  }, [cdata.id])

  const classes = useStyles();
  const handleOperationList = () => {
    if (!props.hasOwnProperty('groupeoperation')) {
      props.dispatch(registerField("addOper", 'groupeoperation', 'Field'));
    }
    props.updateField('groupeoperation', mode)
    const type = (mode === 'MOD' ? "AVT" : "TECH")
    const opeFilter = (mode === 'SIN') && "'26','34','SINSEN'"
  }

  useEffect(() => {
    handleOperationList()
  }, [mode])

  const [loading, setLoading] = useState(false)
  const [alertInfo, setAlertInfo] = useState({})
  const handleAlertClose = (action = null) => {
    setAlertInfo({ open: false, message: "", succes: false })
    action && action()
  }
  const handleAlertOpen = (message, succes = false, action) => {
    setAlertInfo({ open: true, message: message, succes: succes, action: action })
  }
  const [svData, setSvData] = useState(null)
  const [topen, setTopen] = useState(false)
  const handleTOpen = () => {
    setTopen(true)
  }

  const handleRedirectOperation = (data, destination = "HOME") => {
    dispatch(reset('addOper'))
    handleCliqueFonction(destination)
    // history.push('/bankass/suivi-operations', { operation: data });
  }
  const handleSaveOperation = (values) => {
    if (values) {
      let mesDonnees
      mesDonnees = JSON.stringify(values, null, 2)
      mesDonnees = JSON.parse(mesDonnees)
      setLoading(true)
      updateOperation(mesDonnees).then(async (res) => {
        if (res) {
          setCppen(false)
          setSvData(res)
          setCurrentData(res)
          setLoading(false)
          handleAlertOpen("Enregistrement effectué avec succès", true, handleTOpen)
        } else {
          setLoading(false)
          handleCclose()
          handleAlertOpen("Erreur lors de l'enregistrement", false, handleAlertClose)
        }
      }).catch(err => {
        console.log(err);
        setLoading(false)
        handleCclose()
        handleAlertOpen("Erreur lors de l'enregistrement", false, handleAlertClose)
      })
    }

  }

  const [type, setType] = useState(null)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handledClose = () => {
    setAnchorEl(null);
  };

  const handleDeletedoc = (id) => {
    deleteDocument(id).then(res => {
      if (res) {
        getDocument(cdata.id, 'OP').then(res => {
          if (res) {
            setMesDocuments(res.documents)
          }
        })
      }
    })
  }

  const [currentDoct, setCurrentDoc] = useState(null)
  const handleShowDoc = (event, doc) => {
    setAnchorEl(event.currentTarget);
    if (doc && doc.hasOwnProperty('filename')) {
      let ctype = doc.filename.split('.')[1]
      setType(ctype.toLowerCase())
      setCurrentDoc(doc)
    }
  }
  const dopen = Boolean(anchorEl);
  const id = dopen ? 'simple-popover' : undefined;

  const [currentPage, setCurrentPage] = useState(1)
  const [allPage, setAllPage] = useState(1)
  const [showAll, setShowAll] = useState(false)
  const handleDocInfo = (infoDoc) => {
    setAllPage(infoDoc)
    setShowAll(true)
  }
  const handleNextPage = () => {
    if (currentPage < allPage) {
      setCurrentPage(currentPage + 1)
    }
  }
  const handleBackPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  const guideLine = () => {
    return (
      <GridContainer direction="row" justify="center" alignItems="center">
        <GridItem xs={1} sm={1} md={1} lg={1} style={{ marginRight: 25 }}>
          <IconButton color='inherit' className={classes.buttonDetails} disabled={!(currentPage > 1)} onClick={() => setCurrentPage(1)}>
            <FirstPageIcon color={(currentPage > 1) ? 'primary' : "secondary"} style={{ fontSize: 40 }} />
          </IconButton>
        </GridItem>
        <GridItem xs={1} sm={1} md={1} lg={1} style={{ marginRight: 25 }} >
          <IconButton color='inherit' className={classes.buttonDetails} disabled={!(currentPage > 1)} onClick={handleBackPage}>
            <NavigateBeforeIcon color={(currentPage > 1) ? 'primary' : "secondary"} style={{ fontSize: 40 }} />
          </IconButton>
        </GridItem>
        <GridItem xs={1} sm={1} md={1} lg={1} style={{ marginRight: 25 }}>
          <IconButton color='inherit' className={classes.buttonDetails} disabled={!(currentPage < allPage)} onClick={handleNextPage}>
            <NavigateNextIcon color={(currentPage < allPage) ? 'primary' : "secondary"} style={{ fontSize: 40 }} />
          </IconButton>
        </GridItem>
      </GridContainer>)
  }

  //edition contrat

  const handleCclickOpen = (el) => {
    setCppen(el);
  };
  const handleCclose = () => {
    setCppen(false);
  };


  return <div ref={containerref} style={{marginTop:75,width:'75%',marginLeft:'auto',marginRight:'auto'}}>
    <AlertPage open={alertInfo['open'] || false} action={alertInfo['action'] || null} message={alertInfo['message'] || ""} succes={alertInfo['succes'] || false} handleAlertOpen={handleAlertOpen} handleAlertClose={handleAlertClose} />
    <ModalIdentification open={(copen && copen === "c") ? true : false} handleClickOpen={handleCclickOpen} handleClose={handleCclose} data={svData ? svData : currentData} produits={produits} handleSaveOperation={handleSaveOperation} handleAlertOpen={handleAlertOpen} handleAlertClose={handleAlertClose} />
    <ModalInfosOperation open={(copen && copen === "o") ? true : false} handleClickOpen={handleCclickOpen} handleClose={handleCclose} data={svData ? svData : currentData} handleSaveOperation={handleSaveOperation} handleAlertOpen={handleAlertOpen} handleAlertClose={handleAlertClose} />
    <ModalDetailOperation open={(copen && copen === "d") ? true : false} handleClickOpen={handleCclickOpen} handleClose={handleCclose} data={svData ? svData : currentData} handleSaveOperation={handleSaveOperation} handleAlertOpen={handleAlertOpen} handleAlertClose={handleAlertClose} />
    <ModalInfoDeclarant open={(copen && copen === "dc") ? true : false} handleClickOpen={handleCclickOpen} handleClose={handleCclose} data={svData ? svData : currentData} handleSaveOperation={handleSaveOperation} handleAlertOpen={handleAlertOpen} handleAlertClose={handleAlertClose} />

    <Paper elevation={0} className={classes.paper}>
      <div className={classes.headerTitle}>
        <IconButton color='inherit' className={classes.buttonDetails} onClick={() => handleRedirectOperation(currentData, "LISTOP")}>
          <ArrowBackIosIcon color='primary' style={{ fontSize: 40 }} />
        </IconButton>
        <Divider orientation='vertical' />
        <Typography className={classes.heading}>Détail opération N°# {`${currentData && currentData['codeoperation']} - ${currentData && currentData['id']}`}</Typography>
      </div>
      <Divider />
    </Paper>
    <GridContainer direction='column' spacing={1}>
      <GridItem xs={12} sm={12} md={12} lg={12}>
        <Typography varaiant='h2' component='h2'>Contrat</Typography>
        <Core className={classes.coreCustum}>
          <Core.Base>
            <Core.Inner salign='flex-start'>
              <Typography className={classes.itemTitle} varaiant='subtitle2' component='span'>#ID</Typography>
              <Core.Text>{currentData && (currentData['idproposition'] || "")}</Core.Text>
            </Core.Inner>
            <Core.Inner salign='flex-start'>
              <Typography className={classes.itemTitle} varaiant='subtitle2' component='span'>N° proposion</Typography>
              <Core.Text>{currentData && (currentData['codeproposition'] || "")}</Core.Text>
            </Core.Inner>
            <Core.Inner salign='flex-start'>
              <Typography className={classes.itemTitle} varaiant='subtitle2' component='span'>N° Police</Typography>
              <Core.Text>{currentData && (currentData['codepolice'] || "")}</Core.Text>
            </Core.Inner>
            <Core.Inner salign='flex-start'>
              <Typography className={classes.itemTitle} varaiant='subtitle2' component='span'>Adherent</Typography>
              <Core.Text>{currentData && (currentData['adherent'] || "")}</Core.Text>
            </Core.Inner>
            <Core.Inner salign='flex-start'>
              <Typography className={classes.itemTitle} varaiant='subtitle2' component='span'>Produit</Typography>
              <Core.Text>{currentData && (currentData['libelleproduit'] || "")}</Core.Text>
            </Core.Inner>
            <Core.Inner salign='flex-start'>
              <Typography className={classes.itemTitle} varaiant='subtitle2' component='span'>Date effet</Typography>
              <Core.Text>{currentData && (currentData['dateeffet'] || "")}</Core.Text>
            </Core.Inner>
            <Core.Inner>
              <Typography className={classes.itemTitle} varaiant='subtitle2' component='span'>Modifier</Typography>
              <Core.Group direction='row'>
                <Divider variant="middle" orientation="vertical" flexItem />
                <IconButton aria-label="Editer" onClick={() => handleCclickOpen('c')}>
                  <EditIcon fontSize='large' color='primary' />
                </IconButton>
              </Core.Group>
            </Core.Inner>
          </Core.Base>
        </Core>
      </GridItem>
      <GridItem xs={12} sm={12} md={12} lg={12}>
        <Typography varaiant='h2' component='h2'>Opération</Typography>
        <Core className={classes.coreCustum}>
          <Core.Base>
            <Core.Inner salign='flex-start'>
              <Typography className={classes.itemTitle} varaiant='subtitle2' component='span'>Type d'opération</Typography>
              <Core.Text>{currentData && (currentData['groupeoperation'] === 'SIN' ? "SINISTRE" : (currentData['groupeoperation'] === 'PRES' ? "PRESTATION" : "DEMANDE DE MODIFICATION"))} - {currentData['libelletypeoperation'] || ""}</Core.Text>
            </Core.Inner>
            <Core.Inner salign='flex-start'>
              <Typography className={classes.itemTitle} varaiant='subtitle2' component='span'>Date d'opération</Typography>
              <Core.Text>{currentData && (currentData['dateoperation'] ? dayjs(currentData['dateoperation']).format('DD/MM/YYYY') : "")}</Core.Text>
            </Core.Inner>
            <Core.Inner salign='flex-start'>
              <Typography className={classes.itemTitle} varaiant='subtitle2' component='span'>Délai de traitement</Typography>
              <Core.Text>{currentData && (currentData['delaitraitement'] || "")}</Core.Text>
            </Core.Inner>
            <Core.Inner salign='flex-start'>
              <Typography className={classes.itemTitle} varaiant='subtitle2' component='span'>Date fin traitement</Typography>
              <Core.Text>{currentData && (currentData['datefinprevu'] || "")}</Core.Text>
            </Core.Inner>
            <Core.Inner salign='flex-start'>
              <Typography className={classes.itemTitle} varaiant='subtitle2' component='span'>Etape</Typography>
            </Core.Inner>
            <Core.Inner>
              <Typography className={classes.itemTitle} varaiant='subtitle2' component='span'>Modifier</Typography>
              <Core.Group direction='row'>
                <Divider variant="middle" orientation="vertical" flexItem />
                <IconButton aria-label="Editer" onClick={() => handleCclickOpen('o')}>
                  <EditIcon fontSize='large' color='primary' />
                </IconButton>
              </Core.Group>
            </Core.Inner>
          </Core.Base>
        </Core>
      </GridItem>
      {(credentials && !['092'].includes(credentials.codepartenaire))&&<GridItem xs={12} sm={12} md={12} lg={12}>
        <Typography varaiant='h2' component='h2'>Détails opérations</Typography>
        <Core className={classes.coreCustum}>
          <Core.Base>
            <Core.Inner direction='column' salign='flex-start'>
              <Typography className={classes.itemTitle} varaiant='subtitle2' component='span'>Description</Typography>
              <Box m={1} className={classes.assureInfoRow}>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Nature du sinsitre:
                </Typography>
                <Typography variant="subtitle1">
                  {`\xa0 ${currentData['naturesinistre'] || ""}`}
                </Typography>
              </Box>
              <Box m={1} className={classes.assureInfoRow}>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Lieu du sinistres :
                </Typography>
                <Typography variant="subtitle1">
                  {`\xa0 ${currentData['lieusinistre'] || ""}`}
                </Typography>
              </Box>
              <Box m={1} className={classes.assureInfoRow}>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Cause du sinistres :
                </Typography>
                <Typography variant="subtitle1">
                  {`\xa0 ${currentData['causesinistre'] || ""}`}
                </Typography>
              </Box>
              <Box m={1} className={classes.assureInfoRow}>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Date du sinistres :
                </Typography>
                <Typography variant="subtitle1">
                  {`\xa0 ${currentData['datesinistre'] ? dayjs(currentData['datesinistre']).format("DD/MM/YYYY") : ""}`}
                </Typography>
              </Box>
              <Box m={1} className={classes.assureInfoRow}>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Lieu de concervation :
                </Typography>
                <Typography variant="subtitle1">
                  {`\xa0 ${currentData['lieuconservcorps'] || ""}`}
                </Typography>
              </Box>

            </Core.Inner>
            <Core.Inner direction='column' salign='flex-start'>
              <Box m={1} className={classes.assureInfoRow}>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Date d'hunimation :
                </Typography>
                <Typography variant="subtitle1">
                  {`\xa0 ${currentData['dateinhumation'] ? dayjs(currentData['dateinhumation']).format("DD/MM/YYYY") : ""}`}
                </Typography>
              </Box>
              <Box m={1} className={classes.assureInfoRow}>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Lieu de d'hunimation :
                </Typography>
                <Typography variant="subtitle1">
                  {`\xa0 ${currentData['lieuinhumation'] || ""}`}
                </Typography>
              </Box>
            </Core.Inner>
            <Core.Inner direction='column' salign='flex-start'>
              <Typography className={classes.itemTitle} varaiant='subtitle2' component='span'>Défunt(e)</Typography>
              <Box m={1} className={classes.assureInfoRow}>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Nom du défunt :
                </Typography>
                <Typography variant="subtitle1">
                  {`\xa0 ${currentData['nomdefunt'] || ""} ${currentData['prenomdefunt'] || ""}`}
                </Typography>
              </Box>
              <Box m={1} className={classes.assureInfoRow}>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Date de naissance :
                </Typography>
                <Typography variant="subtitle1">
                  {`\xa0 ${currentData['datenaissancedefunt'] ? dayjs(currentData['datenaissancedefunt']).format("DD/MM/YYYY") : ""}`}
                </Typography>
              </Box>
              <Box m={1} className={classes.assureInfoRow}>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Lieu de naissance :
                </Typography>
                <Typography variant="subtitle1">
                  {`\xa0 ${currentData['lieunaissancedefunt'] || ""}}`}
                </Typography>
              </Box>
              <Box m={1} className={classes.assureInfoRow}>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Lieu de résidence :
                </Typography>
                <Typography variant="subtitle1">
                  {`\xa0 ${currentData['lieuresidencedefunt'] || ""}}`}
                </Typography>
              </Box>
              <Box m={1} className={classes.assureInfoRow}>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Professions :
                </Typography>
                <Typography variant="subtitle1">
                  {`\xa0 ${currentData['professiondefunt'] || ""}}`}
                </Typography>
              </Box>
              <Box m={1} className={classes.assureInfoRow}>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Secteur d'activité :
                </Typography>
                <Typography variant="subtitle1">
                  {`\xa0 ${currentData['secteuractivitedefunt'] || ""}`}
                </Typography>
              </Box>
            </Core.Inner>
            <Core.Inner>
              <Typography className={classes.itemTitle} varaiant='subtitle2' component='span'>Modifier</Typography>
              <Core.Group direction='row'>
                <Divider variant="middle" orientation="vertical" flexItem />
                <IconButton aria-label="Editer" onClick={() => handleCclickOpen('d')}>
                  <EditIcon fontSize='large' color='primary' />
                </IconButton>
              </Core.Group>
            </Core.Inner>
          </Core.Base>
        </Core>
      </GridItem>}
      {(credentials && !['092'].includes(credentials.codepartenaire))&&<GridItem xs={12} sm={12} md={12} lg={12}>
        <Typography varaiant='h2' component='h2'>Informations sur le déclarant</Typography>
        <Core className={classes.coreCustum}>
          <Core.Base>
            <Core.Inner direction='column' salign='flex-start'>
              <Box m={1} className={classes.assureInfoRow}>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Nom & prénom :
                </Typography>
                <Typography variant="subtitle1">
                  {`\xa0 ${currentData['nomdeclarant'] || ""} ${currentData['prenomdeclarant'] || ""}`}
                </Typography>
              </Box>
              <Box m={1} className={classes.assureInfoRow}>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Lieu de naissance :
                </Typography>
                <Typography variant="subtitle1">
                  {`\xa0 ${currentData['lieunaissancedeclarant'] || ""}`}
                </Typography>
              </Box>
              <Box m={1} className={classes.assureInfoRow}>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Filiation :
                </Typography>
                <Typography variant="subtitle1">
                  {`\xa0 ${currentData['filiationdeclarant'] || ""}`}
                </Typography>
              </Box>
            </Core.Inner>
            <Core.Inner direction='column' salign='flex-start'>
              <Box m={1} className={classes.assureInfoRow}>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Mobile :
                </Typography>
                <Typography variant="subtitle1">
                  {`\xa0 ${currentData['mobiledeclarant'] || ""} / ${currentData['mobile1declarant'] || ""}`}
                </Typography>
              </Box>
              <Box m={1} className={classes.assureInfoRow}>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Adresse email :
                </Typography>
                <Typography variant="subtitle1">
                  {`\xa0 ${currentData['emaildeclarant'] || ""}`}
                </Typography>
              </Box>
            </Core.Inner>
            <Core.Inner>
              <Typography className={classes.itemTitle} varaiant='subtitle2' component='span'>Modifier</Typography>
              <Core.Group direction='row'>
                <Divider variant="middle" orientation="vertical" flexItem />
                <IconButton aria-label="Editer" onClick={() => handleCclickOpen('dc')}>
                  <EditIcon fontSize='large' color='primary' />
                </IconButton>
              </Core.Group>
            </Core.Inner>
          </Core.Base>
        </Core>
      </GridItem>}
      
      <GridItem xs={12} sm={12} md={12} lg={12}>
        <Typography varaiant='h2' component='h2'>Documents attachés</Typography>
        <Core className={classes.coreCustum}>
          <Core.Inner>
            {(mesDocument && mesDocument.length !== 0) && (
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell align="right">Libellé</TableCell>
                      <TableCell align="right">Document</TableCell>
                      <TableCell align="right">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {mesDocument.map(doc => (
                      <TableRow key={doc.id}>
                        <TableCell component="th" scope="row">
                          {doc.id}
                        </TableCell>
                        <TableCell align="right">{doc.libelle}</TableCell>
                        <TableCell align="right">{doc.filename}</TableCell>
                        <TableCell align="right">
                          <Grid style={{ display: 'flex', flexDirection: 'row' }}>
                            <IconButton edge="start" color="inherit" onClick={(e) => handleShowDoc(e, doc)} aria-label="close">
                              <VisibilityIcon fontSize="large" />
                            </IconButton>
                            <IconButton edge="start" color="inherit" onClick={() => handleDeletedoc(doc.id)} aria-label="close">
                              <DeleteIcon fontSize="large" />
                            </IconButton>
                          </Grid>
                        </TableCell>
                      </TableRow>))}
                  </TableBody>
                </Table>
              </TableContainer>)}
          </Core.Inner>
        </Core>
        {currentDoct && <Popover
          id={id}
          open={dopen}
          anchorPosition={{ top: 200, left: 400 }}
          onClose={handledClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {(type == 'png' || type == 'jpg' || type == 'jpeg' || type == 'gif') ? (
            <Card>

              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 10 }}>
                <Typography variant="h6" className={classes.title}>
                  {currentDoct.libelle ? currentDoct.libelle : "# Aucun titre"}
                </Typography>
                <IconButton edge="start" color="inherit" onClick={handledClose} aria-label="close">
                  <CloseIcon color='primary' fontSize="large" />
                </IconButton>
              </div>


              <CardMedia
                component="img"
                alt={currentDoct.libelle}
                image={`${url + currentDoct.filename}`}
                title={currentDoct.libelle}
              />
              <CardActions>
                <Button aria-describedby={id} size="large" color="primary" ><SettingsOverscanIcon /></Button>
              </CardActions>
            </Card>
          ) : (
            <Card>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 10 }}>
                <Typography variant="h6" compoenent='h6'>
                  {currentDoct.libelle ? currentDoct.libelle : "# Aucun titre"}
                </Typography>
                <IconButton edge="start" color="inherit" onClick={handledClose} aria-label="close">
                  <CloseIcon color='primary' fontSize="large" />
                </IconButton>
              </div>
              <CardContent>
                <div style={{ overflow: 'scroll', height: 600 }}>
                  <PDFReader page={currentPage} url={`${url + currentDoct.filename}`} onDocumentComplete={handleDocInfo} />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>{guideLine()}<Typography color='primary' variant='h4' component='h4' >{`${currentPage}/${allPage} Page(s)`}</Typography></div>
              </CardContent>
            </Card>
          )}
        </Popover>}
      </GridItem>
    </GridContainer>

  </div>;
}

const updateField = (field, data) => (dispatch) => dispatch(change('addOper', field, data))
const mapPropsActions = {
  updateField,
}
const mapStateToProps = (state) => ({
  user: state.user,
  api: state.api,
});
export default connect(mapStateToProps, mapPropsActions)(reduxForm({ form: 'addOper', destroyOnUnmount: false, })(EditOperation))


