import React, { useEffect, useState, useReducer } from 'react'
import { Field, reduxForm, change, registerField, getFormValues } from 'redux-form';
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel';
import { Grid } from '@material-ui/core'
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from "components/CustomButtons/Button.js";
import NavigateNextOutlinedIcon from '@material-ui/icons/NavigateNextOutlined';
import Divider from '@material-ui/core/Divider';
import { getPrimesCapital, getBanqueAssuranceAgences, getPrimePrincipales } from 'redux/actions/apiActions'
import moment from "moment";
import CustomInput from 'components/CustomInput/CustomInput'
import CustomRadio from 'components/CustomRadio/CustomRadio'
import CustomSelect from 'components/CustomSelect/CustomSelect'
import CustomDate from 'components/CustomDate/CustomDate'
import CustomSimpleSelect from 'components/CustomSimpleSelect/CustomSimpleSelect'
import CustomCapitalSelect from 'components/CustomCapitalSelect/CustomCapitalSelect'
import CustomAgenceSelectBankass from 'components/CustomAgenceSelect/CustomAgenceSelectBankass'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import CircularProgress from '@material-ui/core/CircularProgress';
import dayjs from 'dayjs'
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';


const useStyles = makeStyles(theme => ({
  contacts: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
  btnNext: {
    backgroundColor: theme.palette.primary.main,
    height: 40,
    width: 150,
    borderRadius: 20,
    color: theme.palette.primary.contrastText
  },
  btnGroupe: {
    justify: 'flex-end'
  },
  rootAcc: {
    width: '100%',
  },
  headingAcc: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  assureInfoRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-between'
  },
  btnback:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    backgroundColor:"#f9b233",
    paddingRight:20,
    paddingLeft:20,
    paddingTop:10,
    paddingBottom:10,
    color:'#ffffff',
    "&:hover":{
      backgroundColor:"#3c3c3b",
    },
    borderRadius:10,
  },
  btnnext:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    backgroundColor:theme.palette.primary.main,
    paddingRight:20,
    paddingLeft:20,
    paddingTop:10,
    paddingBottom:10,
    color:'#ffffff',
    "&:hover":{
      backgroundColor:"#f9b233",
    },
    borderRadius:10,
  },
  hTrait:{
    marginTop:20,
    marginBottom:20
  },
}));
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const ProfilSouscipteurInfo = props => {
  const classes = useStyles();

  const { handleSubmit, pristine, submitting, api: { societes }, data } = props
  const { branche, partenaire } = props.user.credentials
  const { credentials } = props.user
  let [cusRender, setCusRender] = useState(null)
  let [cusCodeSoc, setCusCodeSoc] = useState(null)//contient le code l'organisme selectionné

  const [banqueAgences, setBanqueAgences] = useState(null)
  const [loadingAgence, setLoadingAgence] = useState(false)
  const [durees, setDurees] = useReducer((state, newSate) => ({ ...state, ...newSate }), {
    durecontrat: [],
    durecotisation: []
  })
  const [primeDetermine, setPrimeDetermine] = useState(false)
  const [capitaux, setCapitaux] = useState([])
  const [capitauxOp, setCapitauxOp] = useState([])

  const [myErrors, setMyErrors] = useState(null)
  const [openAlert, setOpenAlert] = React.useState(false);
  //selection des primes par defaut

  const [primesProduit, setPrimesProduit] = useState([])
  useEffect(() => {
    if (data.produit.CodeProduit === 'CADENCE') {
      let primes = []
      getPrimePrincipales(data.produit.CodeProduit).then((resp) => {

        if (resp && resp.length !== 0) {
          resp.forEach(elt => {
            primes.push(elt.prime)
          })
        }
        setPrimesProduit(primes)
      })
    }
  }, [data])

  useEffect(() => {

    if (!data.hasOwnProperty('dateEffet')) {
      props.dispatch(registerField("adForm", 'dateEffet', 'Field'));
      props.updateField('dateEffet', dayjs().set('date', 1).add(1, 'month').format("YYYY-MM-DD"))
    }
    if (branche && branche === 'BANKASS') {

      if (!data.hasOwnProperty('modePaiement')) {
        props.dispatch(registerField("adForm", 'modePaiement', 'Field'));
        props.updateField('modePaiement', 'VIR')
      }

      if (!data.hasOwnProperty('organismePayeur')) {
        props.dispatch(registerField("adForm", 'organismePayeur', 'Field'));
        props.updateField('organismePayeur', partenaire)
        setCusRender(true)
      }

      if (!data.hasOwnProperty('periodicite')) {
        props.dispatch(registerField("adForm", 'periodicite', 'Field'));
        if (props.user.credentials.codepartenaire === 'AFC') {
          props.updateField('periodicite', 'A')
        } else {
          props.updateField('periodicite', 'M')
        }

      }

      if (!data.hasOwnProperty('periodiciterente')) {
        props.dispatch(registerField("adForm", 'periodiciterente', 'Field'));
        props.updateField('periodiciterente', 'M')
      }

    }
  });


  const handleOpenAlert = () => {
    setOpenAlert(true);
  };
  const handleCloseAlert = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
    setMyErrors(null)
  };


  const handleDuree = () => {

    if (data.hasOwnProperty('dureecontratmin') && data.hasOwnProperty('dureecontratmax')) {
      const { dureecontratmin, dureecontratmax } = data
      let mesDureesContrat = []
      for (let i = dureecontratmin; i <= dureecontratmax; i++) {
        if (i >= 90) {
          mesDureesContrat.push('Vie entière')
          break;
        } else {
          mesDureesContrat.push(i)
        }
      }
      setDurees({ ['durecontrat']: mesDureesContrat })
    }

    if (data.hasOwnProperty('dureecotisationmin') && data.hasOwnProperty('dureecotisationmax')) {
      const { dureecotisationmin, dureecotisationmax } = data
      let mesDureesCotisation = []
      for (let i = dureecotisationmin; i <= dureecotisationmax; i++) {
        if (i >= 90) {
          mesDureesCotisation.push('Vie entière')
          break;
        } else {
          mesDureesCotisation.push(i)
        }
      }
      setDurees({ ['durecotisation']: mesDureesCotisation })
    }
  }


  const renderCapitalGar = (garantie, index, fields) => {
    let mesCapitaux = garantie.capitaux
    let capitalShow = []
    if (mesCapitaux.length !== 0) {
      mesCapitaux.forEach(elt => {
        capitalShow.push(elt.capital)
      })
    }
    return (
      <>{(garantie.type !== 'Deces') ? (
        <GridItem key={index} xs={12} sm={12} md={12} lg={12}>
          {
            (garantie.CodeProduitGarantie === 'SENIOR') ? (
              <Field
                id={`prime-${garantie.CodeProduitGarantie}`}
                name={`prime-${garantie.CodeProduitGarantie}`}
                label={`Prime de la garantie ${garantie.MonLibelle.toUpperCase()}`}
                component={CustomInput}
                formControlProps={{
                  fullWidth: true,
                }}
                variant="outlined"
                type='number'
              />
            ) : (
              (capitalShow.length !== 0) ? (
                <Field
                  id={`capital-${garantie.CodeProduitGarantie}`}
                  name={`capital-${garantie.CodeProduitGarantie}`}
                  label={`${data.produit.CodeProduit ==='YKE_2018' ? "Capital de référence":"Capital à réversé pour la garantie" +garantie.MonLibelle.toUpperCase() }`}
                  component={CustomSimpleSelect}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  variant="outlined"
                  options={capitalShow}
                />
              ) : (
                <Field
                  id={`capital-${garantie.CodeProduitGarantie}`}
                  name={`capital-${garantie.CodeProduitGarantie}`}
                  label={`Capital à réversé pour la garantie ${garantie.MonLibelle.toUpperCase()}`}
                  component={CustomInput}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  variant="outlined"
                  type='number'
                />
              )
            )
          }
        </GridItem>
      ) : (
        (garantie.CodeProduitGarantie === 'SUR' && capitalShow.length !== 0) && (
          <GridItem key={index} xs={12} sm={12} md={12} lg={12}>
            <Field
              id={`capital-${garantie.CodeProduitGarantie}`}
              name={`capital-${garantie.CodeProduitGarantie}`}
              label={`Capital à réversé pour la garantie ${garantie.MonLibelle.toUpperCase()}`}
              component={CustomSimpleSelect}
              formControlProps={{
                fullWidth: true,
              }}
              variant="outlined"
              options={capitalShow}
            />
          </GridItem>
        )
      )}

      </>

    )
  }

  //mise à jour capitaux
  useEffect(() => {
    getPrimesCapital(data.produit.CodeProduit).then((capital) => {
      if (capital) {
        if (['YKL_2004', 'YKV_2004', 'YKF_2004'].includes(data.produit.CodeProduit)) {
          const currentCapitaux = []
          capital.map(cp => {
            if (cp.capital == 430000) {
              cp.Libelle = "Option Honneur"
            }
            if (cp.capital == 750000) {
              cp.Libelle = "Option Elite"
            }
            if (cp.capital == 1400000) {
              cp.Libelle = "Option Prestige"
            }
            currentCapitaux.push(cp)
          })
          setCapitaux(currentCapitaux);
        } else {
          setCapitaux(capital);
        }

      }
    })
  }, [])
  useEffect(() => {
    if (['YKE_2008', 'YKS_2008', 'YKF_2008', 'YKL_2004', 'YKV_2004', 'YKF_2004','YKE_2018','PVRPRE'].includes(data.produit.CodeProduit)) {
      setPrimeDetermine(true)
    } else {
      setPrimeDetermine(false)
    }

  }, [])

  //mise à jour agence
  useEffect(() => {
    setLoadingAgence(true)
    getBanqueAssuranceAgences(credentials.codereseau, [41, 42, 47, 48, 49, 50]).then((agences) => {
      setBanqueAgences(agences);
      setLoadingAgence(false)
    }).catch((err) => {
      console.log(err);
      setLoadingAgence(false)
    })
  }, [data])

  useEffect(() => {
    let gar = []
    if (!data.hasOwnProperty('mySelected')) {
      props.dispatch(registerField("adForm", 'mySelected', 'Field'));
    }
    handleDuree()
    const { assures } = data
    assures.forEach(elt => {
      if (elt.garantiesFacultatives) {
        gar = gar.concat(elt.garantiesFacultatives)
      }
    })

    if (gar.length !== 0) {
      setCapitauxOp(gar.filter(item => item.checked === true))
    }
  }, [])

  return (
    <>
      {myErrors && (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={openAlert}
          autoHideDuration={6000}
          onClose={handleCloseAlert}

        >
          <Alert onClose={handleCloseAlert} severity={myErrors.type}>
            {myErrors.message}
          </Alert>
        </Snackbar>
      )}
      <form onSubmit={handleSubmit}>
        <GridContainer>
          <GridItem xs={12} sm={6} md={6} lg={6}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <FormControl component="fieldset" direction='row'>
                  <FormLabel component="legend">Je souhaite payer mes primes par</FormLabel>
                  <Field
                    required
                    name="modePaiement"
                    component={CustomRadio}
                    label="Je souhaite payer mes primes par"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    row
                    options={[
                      { "label": "Virement bancaire", "value": "VIR" },
                      { "label": "Espèce", "value": "ESP" },
                      { "label": "Chèque", "value": "CHQ" },
                      { "label": "Mobile money", "value": "EBANK" },
                      { "label": "Prélèvement à la source", "value": "SOURCE" }
                    ]}
                    disabled={branche === 'BANKASS'}

                  />
                </FormControl>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <Field
                  label="Ma banque ou organisme de prélèvement"
                  id="organismePayeur"
                  name="organismePayeur"
                  formControlProps={{
                    fullWidth: true
                  }}
                  options={societes}
                  component={CustomSelect}
                  disabled={(branche === 'BANKASS')}
                  setCusCodeSoc={setCusCodeSoc}
                />
              </GridItem>
            </GridContainer>
            {(!loadingAgence || cusCodeSoc) ? (<GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <Field
                  id='agence'
                  name="agence"
                  label="Agence"
                  options={banqueAgences}
                  component={CustomAgenceSelectBankass}
                  fullWidth
                  formControlProps={{
                    fullWidth: true,
                  }}
                  disabled={!branche === 'BANKASS'}
                />
              </GridItem>
            </GridContainer>) : (
              <CircularProgress color="primary" size={20} />
            )}

            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <Field
                  id='numCompte'
                  name="numCompte"
                  label="Mon N° de compte (Matricule)"
                  component={CustomInput}
                  formControlProps={{
                    fullWidth: true,

                  }}
                  InputProps={{
                    inputProps: { max: 24, min: 7 },
                  }}
                  type='texte'
                  variant="outlined"
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <FormControl component="fieldset" direction='row'>
                  <FormLabel component="legend">Votre conseiller client</FormLabel>
                </FormControl>
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={4} md={4} lg={4}>
                <Field
                  id='codeagent'
                  name="codeagent"
                  label="Code conseiller"
                  component={CustomInput}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  InputProps={{
                    inputProps: { max: 24, min: 7 },
                  }}
                  type='texte'
                  variant="outlined"
                />
              </GridItem>
              <GridItem xs={12} sm={8} md={8} lg={8}>
                <Field
                  id='nomagent'
                  name="nomagent"
                  label="Nom & prénom du conseiller"
                  component={CustomInput}
                  formControlProps={{
                    fullWidth: true,

                  }}
                  InputProps={{
                    inputProps: { max: 24, min: 7 },
                  }}
                  type='texte'
                  variant="outlined"
                />
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={6}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <Field
                  required
                  name="periodicite"
                  id='periodicite'
                  component={CustomRadio}
                  label="Je souhaite payer mes primes chaque"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  row
                  options={[
                    { "label": "Mois", "value": "M" },
                    { "label": "Trimestre", "value": "T" },
                    { "label": "Semestre", "value": "S" },
                    { "label": "Année", "value": "A" },
                    { "label": "Versement unique", "value": "U" }
                  ]}
                  disabled={branche === 'BANKASS'}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <Field
                  required
                  id="dateEffet"
                  name="dateEffet"
                  component={CustomDate}
                  label="Mon contrat prendra effet le :"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  InputProps={{
                    type: 'date',
                    inputProps: { min: moment().format('YYYY-MM-DD') },
                  }}
                  autoFocus
                />
              </GridItem>

            </GridContainer>
            <GridContainer>
              {primeDetermine ? (['PVRPRE'].includes(data.produit.CodeProduit) ?(<GridItem xs={12} sm={12} md={12} lg={12}>
                  <Field
                    required
                    id="capital-deces"
                    name="capital-deces"
                    disabled
                    component={CustomInput}
                    label="Capital de décès"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    variant="outlined"
                  />
                </GridItem>):(<GridItem xs={12} sm={12} md={12} lg={12}>
                  <Field
                    required
                    id="capitalSouscrit"
                    name="capitalSouscrit"
                    component={CustomCapitalSelect}
                    label={['YKL_2004', 'YKV_2004', 'YKF_2004','YKE_2018'].includes(data.produit.CodeProduit) ? "Capital de référence" : "Garanties souscrites et primes"}
                    formControlProps={{
                      fullWidth: true,
                    }}
                    options={capitaux}
                  />
                </GridItem>)
                
              ) : (((data.produit && !['PVRPRE','PVRBNI'].includes(data.produit.CodeProduit))) && (
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  {(primesProduit.length !== 0) ? (
                    <Field
                      id='primePrincipale'
                      name="primePrincipale"
                      component={CustomSimpleSelect}
                      label="je souhaite payer une prime de"
                      type='number'
                      formControlProps={{
                        fullWidth: true,
                      }}
                      variant="outlined"
                      options={primesProduit}
                    />
                  ) : (
                    <Field
                      id='primePrincipale'
                      name="primePrincipale"
                      component={CustomInput}
                      label="je souhaite payer une prime de"
                      type='number'
                      formControlProps={{
                        fullWidth: true,
                      }}
                      variant="outlined"
                    />)}

                </GridItem>
              ))}
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <Field
                  required
                  id='duree'
                  name="duree"
                  component={CustomSimpleSelect}
                  label="Durée de mes cotisations (anneé)"
                  formControlProps={{
                    fullWidth: true,

                  }}
                  type='number'
                  variant="outlined"
                  options={durees.durecotisation}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              {(capitauxOp.length !== 0) ? (capitauxOp.map(renderCapitalGar)) : null}
            </GridContainer>
            {(data.produit.CodeProduit === 'PVRBNI') && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <FormControl component="fieldset" direction='row'>
                    <FormLabel component="legend">Rente</FormLabel>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <Field
                    required
                    id="montantrente"
                    name="montantrente"
                    label="Montant de la rente"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    component={CustomSimpleSelect}
                    variant="outlined"
                    options={[50000, 75000, 100000, 150000, 200000, 250000, 500000, 1000000]}

                  />

                </GridItem>

                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <Field
                    required
                    id="dureerente"
                    name="dureerente"
                    component={CustomSimpleSelect}
                    label="Durée du service de la rente (mois)"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    options={[12, 24, 36]}
                  />
                </GridItem>
              </GridContainer>
            )}
          </GridItem>
        </GridContainer>

        <Divider component="hr" className={classes.hTrait} />

        <Grid container direction="row" justify="space-between" alignItems="center">
          <Button type="button" className={classes.btnback} onClick={props.handleBack}>
            Retour
          </Button>


          <Button className={classes.btnnext} type="submit" disabled={pristine || submitting}>
            Continuer
            <NavigateNextOutlinedIcon />
          </Button>

        </Grid>



      </form>
    </>

  )
}

const mapStateToProps = (state) => ({
  api: state.api,
  user: state.user,
  data: getFormValues('adForm')(state),
});

const updateField = (field, data) => (dispatch) => dispatch(change('adForm', field, data))
const mapActionsToProps = {
  updateField
}


export default connect(
  mapStateToProps,
  mapActionsToProps
)(reduxForm({
  form: 'adForm', // a unique identifier for this form
  destroyOnUnmount: false,
})(ProfilSouscipteurInfo))
