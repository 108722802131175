import React from 'react';
import { jsPDF } from "jspdf";
import 'jspdf-autotable'
import { ageAssure, ageBorne } from 'redux/actions/userActions'
import dayjs from 'dayjs'
import { selectGarantiesContrat, selectAssuresContrat, selectBeneficairesContrat } from 'redux/actions/apiActions'
import { renderToString } from 'react-dom/server';
const formatNumber = (num) => {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
}
const styles = {
  table: {
    width: '100%',
    color: '#717375',
    backgroundColor: '#fff',
    fontFamily: 'helvetica'
  },
  td: {
    textAlign: "center"
  }
  
}
const Prints = () => (
  <div>
    <table style={styles.table}>
      <tr><td style={styles.td}><h1>RESUME CONDITIONS GENERALES</h1></td></tr>

    </table>
  </div>
)

export const generateBulletinSouscription = async (data, credentials) => {

  const headRows1 = () => ([{ libelle: 'Libelle', valeur: 'Valeur' }])
  
  let primeIvestie=0
  let doc = new jsPDF();
  doc.setProperties({
    title: 'Bulletin de souscription',
    author: 'Benoit',
    creator: 'YAKO AFRICA'
  });
  let pageHeight = doc.internal.pageSize.getHeight();
  let pageWidth = doc.internal.pageSize.getWidth();
  let img = new Image(35, 23)
  img.src = require('assets/images/logo_yako.jpg')
  doc.addImage(img, "JPEG", 10, 5, 30, 20);
  let signature = new Image(40, 10)
  signature.src = require('assets/images/Signature_Dta.jpg')

  const { branche, partenaire, codepartenaire } = credentials

  if (branche !== 'BANKASS') {
    let logoEnov = new Image(35, 13)
    logoEnov.src = require('assets/images/logoYnovGreen_b.png')
    doc.addImage(logoEnov, "PNG", 170, 5, 35, 13);
  } else {
    let logoPart = new Image(25, 10)
    if (codepartenaire === 'AFC') {
      logoPart.src = require(`assets/images/${codepartenaire}.jpg`)
    } else {
      logoPart.src = require('assets/images/BNI.jpeg')
    }

    doc.addImage(logoPart, "JPEG", 180, 5, 20, 20);
  }

  let codeRef = data.codeproduit.substring(0, 3)
  if (partenaire && partenaire.trim() !== '') {
    codeRef = partenaire.toUpperCase() + '-' + codeRef
  }

  doc.text(80, 20, 'BULLETIN DE SOUSCRIPTION');
  doc.setLineWidth(0.1);
  doc.line(15, 25, 195, 25);
  //doc.addFont('ArialMS', 'Arial', 'bold ');
  //doc.setFont('Arial');
  //doc.setFontType('bold')
  doc.setFontSize(14)
  doc.text(80, 30, 'N° : ' + codeRef + '-' + dayjs().format('MMYYYY') + data.id)
  doc.autoTable({
    startY: 32,
    head: [{
      libelle: 'Libelle', valeur: 'Valeur',
      libelle: 'Libelle', valeur: 'Valeur',
      libelle: 'Libelle', valeur: 'Valeur'
    }],
    body: [
      ['Produit :',(credentials.codepartenaire=='092' && data.codeproduit==='YKE_2018')?"YAKO Eternité BNI": (data.libelleproduit ? data.libelleproduit: "") , 'Conseiller :', `${data.codeConseiller ? data.codeConseiller : ""} ${(data.nomagent) ? ('/' + data.nomagent) : ''}`, 'Agence :', credentials.agence ? credentials.agence : ""],
      [],
    ],
    styles: { overflow: 'ellipsize', cellWidth: 'auto', fontSize: 9, valign: 'center' },
    showHead: false,
    columnStyles: {
      libelle: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255], },
      valeur: { textColor: 0, fontStyle: 'normal', halign: 'left', fillColor: [255, 255, 255] },
    },
    columns: [
      { header: 'Libelle', dataKey: 'libelle' },
      { header: 'Valeur', dataKey: 'valeur' },
      { header: 'Libelle', dataKey: 'libelle' },
      { header: 'Valeur', dataKey: 'valeur' },
      { header: 'Libelle', dataKey: 'libelle' },
      { header: 'Valeur', dataKey: 'valeur' },
    ],

  });


  let finalY = doc.previousAutoTable.finalY

  doc.autoTable({
    startY: finalY,
    head: [{ libelle: 'Libelle' }],
    body: [
      [{ content: 'ADHERENT' }],
    ],
    showHead: false,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', lineWidth: 0.1, lineColor: 0, fontSize: 9 },
    columnStyles: {
      libelle: { textColor: 0, fontStyle: 'bold', fillColor: [230, 230, 230], },
    },

    columns: [
      { header: 'Libelle', dataKey: 'libelle' },
    ],
    margin: { top: 0 },
  })

  //["Secteur d'activité : ", `${data.employeur ? data.employeur : ""}`],
  //["Email : ", `${(data.email && data.email !== 'null') ? data.email : ""}`],
  //["Téléphone : ", `${(data.telephone && data.telephone !== 'null') ? data.telephone : ""}`],
    //["Lieu de résidence : ", data.lieuresidence],
  finalY = doc.previousAutoTable.finalY
  doc.autoTable({
    startY: finalY + 1,
    head: headRows1(),
    body: [
      [`N° ${data.naturepiece ? data.naturepiece : " CNI"} : ${data.numeropiece ? data.numeropiece : ""}`],
      [`Nom : `, data.nom],
      ["Prénom : ", data.prenom],
      ["Né(e) le : ", dayjs(data.datenaissance).format('DD/MM/YYYY')],
    ],
    showHead: false,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', fontSize: 9, halign: 'left', valign: 'center', cellPadding: 1 },
    columnStyles: {
      libelle: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255], },
      valeur: { textColor: 0, fontStyle: 'normal', halign: 'left', fillColor: [255, 255, 255] },
    },
    columns: [
      { header: 'Libelle', dataKey: 'libelle' },
      { header: 'valeur', dataKey: 'valeur' },
    ],
    tableWidth: 90,
  })
  doc.autoTable({
    startY: finalY + 3,
    head: headRows1(),
    body: [
      [],
      ["Profession :", `${(data.profession && data.profession !== 'null') ? data.profession : ""}`],
      ["Mobile : ", `${(data.mobile && data.mobile !== 'null') ? data.mobile : ""} ${(data.mobile1 && data.mobile1 !== 'null') ? "/" + data.mobile1 : ""}`],
      ["Né(e) à : ", data.lieunaissance],
    ],
    showHead: false,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', fontSize: 9, cellPadding: 1 },
    columnStyles: {
      libelle: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255], },
      valeur: { textColor: 0, fontStyle: 'normal', halign: 'left', fillColor: [255, 255, 255] },
    },
    columns: [
      { header: 'Libelle', dataKey: 'libelle' },
      { header: 'Valeur', dataKey: 'valeur' },
    ],
    margin: { left: 105 },
    tableWidth: 105,
  })

  finalY = doc.previousAutoTable.finalY

  //Affectation des assurées
  doc.autoTable({
    startY: finalY + 3,
    head: [{ libelle: 'Libelle' }],
    body: [
      [{ content: 'ASSURES' },],
    ],
    showHead: false,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', lineWidth: 0.1, lineColor: 0, fontSize: 9 },
    columnStyles: {
      libelle: { textColor: 0, fontStyle: 'bold', fillColor: [230, 230, 230], },
    },
    columns: [
      { header: 'Libelle', dataKey: 'libelle' }
    ],
  })
  finalY = doc.previousAutoTable.finalY


  const headAssure = () => ([
    { nomAssure: 'Nom', filiationAssure: 'Filiation', dateNaissanceAssure: 'Né(e) le', lieuNaissanceAssure: 'Lieu naissance', lieuResidenceAssure: 'Lieu Résidence' }
  ]);
  let body = []
  const bodyAssure = async () => {

    const assures = await selectAssuresContrat(data.id)

    if (assures) {
      assures.map((assure) => {
        body.push({
          nomAssure: assure.nom + ' ' + assure.prenom,
          filiationAssure: assure.filiation,
          dateNaissanceAssure: dayjs(assure.datenaissance).format('DD/MM/YYYY'),
          lieuNaissanceAssure: assure.lieunaissance,
          lieuResidenceAssure: assure.lieuresidence
        })
      })
    }
    return body
  }

  doc.autoTable({
    startY: finalY + 3,
    head: headAssure(),
    body: await bodyAssure(),
    showHead: true,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', fontSize: 9, cellPadding: 1 },
    bodyStyles: { fillColor: [255, 255, 255] },
    headStyles: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255] },
  });
  finalY = doc.previousAutoTable.finalY

  //Affectation des bénéficiaire
  doc.autoTable({
    startY: finalY + 3,
    head: [{ libelle: 'Libelle' }],
    body: [
      [{ content: 'BENEFICIAIRES / REPRESENTANTS DESIGNES' },],
    ],
    showHead: false,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', lineWidth: 0.1, lineColor: 0, fontSize: 9 },
    columnStyles: {
      libelle: { textColor: 0, fontStyle: 'bold', fillColor: [230, 230, 230], },
    },
    columns: [
      { header: 'Libelle', dataKey: 'libelle' },
    ],
  })

  finalY = doc.previousAutoTable.finalY
  const auTermeBody = async () => {

    let dataAuTerme = []

    if (data.hasOwnProperty('beneficiaireauterme') && data.beneficiaireauterme) {

      const benefAuTerme = data.beneficiaireauterme.split(';');
      if (benefAuTerme) {
        benefAuTerme.map(auterme => {
          if (auterme === 'adherent') {
            dataAuTerme.push({ "libelle": "- L'adherent" })
          }
          if (auterme === 'conjoint') {
            dataAuTerme.push({ "libelle": "- Le conjoint non divorcé, ni séparé de corps" })
          }
          if (auterme === 'enfants') {
            dataAuTerme.push({ "libelle": "- Les enfants nés et à naître" })
          }
          if (auterme === 'autre') {
            dataAuTerme.push({ "libelle": "- Autre" })
          }
        })
      }
    }
    return dataAuTerme
  }
  if (data.hasOwnProperty('beneficiaireauterme')) {

    doc.autoTable({
      startY: finalY + 3,
      head: [{ libelle: 'Au terme du contrat' }],
      body: await auTermeBody(),
      styles: { overflow: 'ellipsize', cellWidth: 'auto', fontSize: 9, valign: 'center', cellPadding: 1 },
      columnStyles: {
        libelle: { textColor: 0, fontStyle: 'normal', fillColor: [245, 245, 245], },
      },
      headStyles: { fillColor: [255, 255, 255], textColor: 0, fontStyle: 'bold', valign: 'center' },
      columns: [
        { header: 'Libelle', dataKey: 'libelle' },
      ],
      tableWidth: 75,
    })
  }

  const auDecesbody = async () => {

    let dataAuDeces = []

    if (data.hasOwnProperty('beneficiaireaudeces') && data.beneficiaireaudeces) {
      const benefAuDeces = data.beneficiaireaudeces.split(';');
      if (benefAuDeces) {
        benefAuDeces.map(audeces => {
          if (audeces === 'conjoint') {
            dataAuDeces.push({ "libelle": "- Le conjoint non divorcé, ni séparé de corps" })
          }
          if (audeces === 'enfants') {
            dataAuDeces.push({ "libelle": "- Les enfants nés et à naître" })
          }
          if (audeces === 'autre') {
            dataAuDeces.push({ "libelle": "- Autre" })
          }
        })

      }
    }
    return dataAuDeces
  }
  if (data.hasOwnProperty('beneficiaireaudeces')) {

    doc.autoTable({
      startY: finalY + 3,
      head: [{ libelle: 'En cas de décès avant le terme' }],
      body: await auDecesbody(),
      styles: { overflow: 'ellipsize', cellWidth: 'auto', fontSize: 9, valign: 'center', cellPadding: 1 },
      columnStyles: {
        libelle: { textColor: 0, fontStyle: 'normal', fillColor: [255, 255, 255], }
      },
      headStyles: { fillColor: [255, 255, 255], textColor: 0, fontStyle: 'bold', valign: 'center' },
      columns: [
        { header: 'Libelle', dataKey: 'libelle' },
      ],
      margin: { left: 105 },
      tableWidth: 75,
    })
  }

  finalY = doc.previousAutoTable.finalY
  const headBenficaire = () => ([
    { nomBeneficiaire: 'Nom', filiationBeneficiaire: 'Filiation', dateNaissanceBeneficiaire: 'Né(e) le', lieuNaissanceBeneficiaire: 'Lieu naissance', telephoneBeneficiaire: 'Téléphone', condition: 'Conditions'}
  ]);
  const bodyBeneficaire = async () => {
    let body = []
    const beneficiaires = await selectBeneficairesContrat(data.id)

    if (beneficiaires) {
      beneficiaires.map((beneficiaire) => {
        body.push({
          nomBeneficiaire: beneficiaire.nom + ' ' + beneficiaire.prenom,
          filiationBeneficiaire: beneficiaire.filiation,
          dateNaissanceBeneficiaire: dayjs(beneficiaire.datenaissance).format('DD/MM/YYYY'),
          lieuNaissanceBeneficiaire: beneficiaire.lieunaissance,
          telephoneBeneficiaire: beneficiaire.telephone,
          condition: beneficiaire.type==='AUTERME'?"Au terme":"En cas de décès"
        })
      })
    }
    return body
  }
  doc.autoTable({
    startY: finalY + 3,
    head: headBenficaire(),
    body: await bodyBeneficaire(),
    showHead: true,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', fontSize: 9, cellPadding: 1 },
    bodyStyles: { fillColor: [255, 255, 255] },
    headStyles: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255] },
  });

  //Assuré garantie
  finalY = doc.previousAutoTable.finalY
  doc.autoTable({
    startY: finalY + 3,
    head: [{ libelle: 'Libelle' }],
    body: [
      [{ content: 'GARANTIES & PRIMES' },],
    ],
    showHead: false,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', lineWidth: 0.1, lineColor: 0, fontSize: 9 },
    columnStyles: {
      libelle: { textColor: 0, fontStyle: 'bold', fillColor: [230, 230, 230], },
    },
    columns: [
      { header: 'Libelle', dataKey: 'libelle' }
    ],
  })
  finalY = doc.previousAutoTable.finalY

  let headGaranties = () => ([
    { nomAssure: 'Nom', garantie: 'Garantie', capitalGarantie: 'Capital', primeGarantie: 'Prime', primeAccesoire: 'Accéssoire', totalprime: 'Total prime' }
  ]);

  let bodyGaranties = async () => {
    let body = []
    let garanties = await selectGarantiesContrat(data.id)
   
    if (garanties) {
      garanties.map((garantie) => {
        body.push({
          nomAssure: garantie.nom + ' ' + garantie.prenom,
          garantie: garantie.monlibelle,
          capitalGarantie: garantie.capitalgarantie ? formatNumber(parseInt(garantie.capitalgarantie) || "") : '',
          primeGarantie: garantie.prime ? formatNumber(parseInt(garantie.prime) || "") : '',
          primeAccesoire: garantie.primeaccesoire ? formatNumber(garantie.primeaccesoire || "") : '',
          totalprime: garantie.primetotal ? formatNumber(garantie.primetotal || "") : ''
        })
      })
    }



    return body
  }

  if (data.codeproduit !== 'PVRBNI') {
    headGaranties = () => ([
      { garantie: 'Garantie', option: 'Option/Capital', primeGarantie: 'Prime', periodicite: 'Périodicité', totalprime: 'Total prime' }
    ])

    bodyGaranties = async () => {
      let body = []
      let garanties = await selectGarantiesContrat(data.id)

      //choix de l'option
      let option = ""
      let maPeriodicite = ''
      let cumulprime = 0
      let cumulprimeTotal = 0
      if (garanties) {
        if(data.codeproduit === 'YKE_2018'){
          primeIvestie =  garanties.filter(item=>item.codeproduitgarantie ==='HOMMAGE')[0]?.prime
        }
        if(data.codeproduit === 'PVRPRE'){
          primeIvestie =  garanties.filter(item=>item.codeproduitgarantie ==='EPGPLUS')[0]?.prime
        }
        garanties.map((garantie,index,dataAll) => {

          if (data.codeproduit === 'YKV_2004' || data.codeproduit === 'YKL_2004' || data.codeproduit === 'YKF_2004') {
            if (garantie.codeproduitgarantie !== 'REMB') {
              if (parseInt(garantie.capitalgarantie) === 430000) {
                option = "HONNEUR"
              } else if (parseInt(garantie.capitalgarantie) === 750000) {
                option = "ELITE"
              } else if (parseInt(garantie.capitalgarantie) === 1400000) {
                option = "PRESTIGE"
              } else {
                option = formatNumber(parseInt(garantie.capitalgarantie) || "")
              }
            } else {
              option = garantie.capitalgarantie ? formatNumber(parseInt(garantie.capitalgarantie) || "") : ''
            }
          } else {
            option = garantie.capitalgarantie ? formatNumber(parseInt(garantie.capitalgarantie) || "") : ''
          }

          if (data.periodicite === 'M') {
            maPeriodicite = 'Mensuelle'
          } else if (data.periodicite === 'T') {
            maPeriodicite = 'Trimestrielle'
          } else if (data.periodicite === 'S') {
            maPeriodicite = 'Semestrielle'
          } else if (data.periodicite === 'A') {
            maPeriodicite = 'Annuelle'
          } else {
            maPeriodicite = 'Non defini'
          }

          if(garantie.codeproduitgarantie === 'PTYS'){
            const garPers =dataAll.filter(item=>item.codeproduitgarantie ==='PERSIT')[0]
            cumulprimeTotal =garPers ? (parseInt(garPers.primetotal) + parseInt(garantie.primetotal)) : parseInt(garantie.primetotal)
            cumulprime =garPers ? (parseInt(garPers.prime) + parseInt(garantie.prime)) : parseInt(garantie.prime)
            
          }else{
            cumulprimeTotal =parseInt(garantie.primetotal)
            cumulprime = parseInt(garantie.prime)
          }

          if(garantie.codeproduitgarantie !== 'PERSIT'){
            body.push({
              garantie: garantie.monlibelle,
              option: option,
              primeGarantie: garantie.prime ? formatNumber(parseInt(cumulprime) || "") : '',
              periodicite: maPeriodicite,
              totalprime: garantie.primetotal ? formatNumber(cumulprimeTotal || "") : ''
            })
          }
          
        })
      }

      return body
    }
  }

  doc.autoTable({
    startY: finalY + 3,
    head: headGaranties(),
    body: await bodyGaranties(),
    styles: { overflow: 'ellipsize', cellWidth: 'auto', fontSize: 9, cellPadding: 1 },
    bodyStyles: { fillColor: [255, 255, 255] },
    headStyles: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255] },
    columnStyles: {
      totalprime: { textColor: 0, fontStyle: 'bold', halign: 'center', fontSize: '10' },
      periodicite: { textColor: 0, halign: 'left' },
      option: { textColor: 0, halign: 'left' },
    },
  });
  finalY = doc.previousAutoTable.finalY

  doc.setFontSize(12)
  //doc.addFont('ArialMS', 'Arial', 'bold ');
  //doc.setFontType('bold')

  if(data && data.fraisadhesion){
    doc.text('FRAIS D\'ADHESION :'  + formatNumber(data.fraisadhesion || ""), 35, finalY + 5)
  }
  doc.text('PRIME TOTALE : ' + formatNumber(data.prime || ""), 135, finalY + 5)


  doc.setLineWidth(0.1);
  doc.line(60, finalY + 8, 150, finalY + 8);
  if (data.codeproduit === 'PVRBNI') {
    doc.autoTable({
      startY: finalY + 10,
      head: [{ capitalSous: 'Montant de la rente', dureerente: 'Durée de service de la rente(mois)', dateeffet: "Date d'effet", dure: 'Durée du contrat', echeance: 'Echéance' }],
      body: [[`${data.montantrente ? formatNumber(parseInt(data.montantrente) || "") : ""}`, `${data.dureerente ? data.dureerente : ""}`, `${data.dateeffet ? dayjs(data.dateeffet).format('DD/MM/YYYY') : ""}`, `${data.duree ? data.duree : ""}`, `${(data.duree && data.duree) ? dayjs(data.dateeffet).add(data.duree, 'year').format('DD/MM/YYYY') : ""}`]],
      showHead: true,
      styles: { overflow: 'ellipsize', cellWidth: 'auto', fontSize: 9 },
      bodyStyles: { fillColor: [255, 255, 255] },
      headStyles: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255] },
      columnStyles: {
        capitalSous: { halign: 'center', valign: 'center' },
        dureerente: { halign: 'center', valign: 'center' },
        dateeffet: { halign: 'center', valign: 'center' },
        dure: { halign: 'center', valign: 'center' },
        echeance: { halign: 'center', valign: 'center' },
      },
    });
  } else if (data.codeproduit === 'YKV_2004' || data.codeproduit === 'YKL_2004' || data.codeproduit === 'YKF_2004') {
    let moption = ""
    let detailOption = ''
    switch (data.codeproduit) {
      case 'YKV_2004': case 'YKL_2004':
        if (parseInt(data.capital) === 430000) {
          moption = "HONNEUR"
          detailOption = "Service de base : \n    Enlèvement - Traitement,Conservation sur 7 jours, levée de corps \nAllocation Cercueil-Transport: \n    100 000 FCFA \nForfait Cash:\n    30 000 FCFA"
        }
        if (parseInt(data.capital) === 750000) {
          moption = "ELITE"
          detailOption = "Service de base : \n    Enlèvement - Traitement,Conservation sur 15 jours, levée de corps \nAllocation Cercueil-Transport: \n    300 000 FCFA \nForfait Cash:\n    100 000 FCFA"
        }
        if (parseInt(data.capital) === 1400000) {
          moption = "PRESTIGE"
          detailOption = "Service de base : \n    Enlèvement - Traitement,Conservation sur 15 jours, levée de corps \nAllocation Cercueil-Transport: \n    500 000 FCFA \nForfait Cash:\n    500 000 FCFA"
        }
        break;

      default:
        if (parseInt(data.capital) === 430000) {
          moption = "HONNEUR"
          detailOption = "Service de base : \n    Adulte : Enlèvement - Traitement,Conservation sur 7 jours, levée de corps \n    Enfant: Enlèvement - Traitement,Conservation sur 7 jours, levée de corps \nAllocation Cercueil-Transport: \n    Adulte : 100 000 FCFA / Enfant : 75 000 FCFA\nForfait Cash:\n    Adulte : 30 000 FCFA / Enfant : 25 000 FCFA"
        }
        if (parseInt(data.capital) === 750000) {
          moption = "ELITE"
          detailOption = "Service de base : \n    Adulte : Enlèvement - Traitement,Conservation sur 15 jours, levée de corps \n   Enfant: Enlèvement - Traitement,Conservation sur 7 jours, levée de corps \nAllocation Cercueil-Transport: \n    Adulte : 300 000 FCFA / Enfant: 150 000 FCFA \nForfait Cash:\n    Adulte : 100 000 FCFA / Enfant : 50 000 FCFA"
        }
        if (parseInt(data.capital) === 1400000) {
          moption = "PRESTIGE"
          detailOption = "Service de base : \n    Adulte : Enlèvement - Traitement,Conservation sur 15 jours, levée de corps \n    Enfant: Enlèvement - Traitement,Conservation sur 7 jours, levée de corps \nAllocation Cercueil-Transport: \n    Adulte : 500 000 FCFA / Enfant: 250 000 FCFA \nForfait Cash:\n    Adulte : 500 000 FCFA / Enfant : 250 000 FCFA"
        }
        break;
    }

    doc.autoTable({
      startY: finalY + 10,
      head: [{ option: `Option ${moption}`, dateeffet: 'Date effet', dure: 'Durée (année)', echeance: 'Echéance' }],
      body: [[`${detailOption}`, `${data.dateeffet ? dayjs(data.dateeffet).format('DD/MM/YYYY') : ""}`, `${data.duree ? data.duree : ""}`, `${(data.duree && data.duree) ? dayjs(data.dateeffet).add(data.duree, 'year').format('DD/MM/YYYY') : ""}`]],
      showHead: true,
      styles: { overflow: 'ellipsize', cellWidth: 'auto', fontSize: 9 },
      bodyStyles: { fillColor: [255, 255, 255] },
      headStyles: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255] },
      columnStyles: {
        dateeffet: { halign: 'center', valign: 'center' },
        dure: { halign: 'center', valign: 'center' },
        echeance: { halign: 'center', valign: 'center' },
      },
    });
  } else {
    doc.autoTable({
      startY: finalY + 10,
      head: [{ capitalSous:['PVRPRE','YKE_2018','YKL_2004','YKF_2004','YKL_2004'].includes(data.codeproduit) ?  "Capital décès souscrit":'Capital souscrit', dateeffet: 'Date effet', dure: 'Durée', echeance: 'Echéance' }],
      body: [[`${data.capital ? formatNumber(parseInt(data.capital) || "") : ""}`, `${data.dateeffet ? dayjs(data.dateeffet).format('DD/MM/YYYY') : ""}`, `${data.duree ? data.duree : ""}`, `${(data.duree && data.duree) ? dayjs(data.dateeffet).add(data.duree, 'year').format('DD/MM/YYYY') : ""}`]],
      showHead: true,
      styles: { overflow: 'ellipsize', cellWidth: 'auto', fontSize: 9,halign:'center',valign:'center' },
      bodyStyles: { fillColor: [255, 255, 255],halign:'center' },
      headStyles: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255] },
      columnStyles: {
        capitalSous: { halign: 'center', valign: 'center' },
        dateeffet: { halign: 'center', valign: 'center' },
        dure: { halign: 'center', valign: 'center' },
        echeance: { halign: 'center', valign: 'center' },
      },
    });
  }

if(data.codeproduit ==='PVRPRE'){
  finalY = doc.previousAutoTable.finalY
  doc.autoTable({
    startY: finalY + 3,
    head: headRows1(),
    body: [
      [{ content: 'EVOLUTION DU CONTRAT' },],
    ],
    showHead: false,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', lineWidth: 0.1, lineColor: 0, fontSize: 9 },
    columnStyles: {
      libelle: { textColor: 0, fontStyle: 'bold', fillColor: [230, 230, 230], },
    },
    columns: [
      { header: 'Libelle', dataKey: 'libelle' },
    ],
  })

  finalY = doc.previousAutoTable.finalY
  //TO DO SIMULATION
  const evol = await evolutionContrat(primeIvestie,data.codeproduit,10,data,'horizontal')
  const headEvo = evol.annee
  const cumul = evol.cumul
  const valeur = evol.pm
  headEvo.unshift('Année')
  cumul.unshift('Cumul Prime')
  valeur.unshift("Valeur")
  doc.autoTable({
    startY: finalY + 3,
    head: [headEvo],
    body: [
      cumul,
      valeur
    ],  
    styles: { overflow: 'ellipsize', cellWidth: 'auto', lineWidth: 0.1, lineColor: 0, fontSize: 8,fillColor: [255, 255, 255] },
    
    headerStyles: {
      fillColor: [255, 255, 255],
      textColor: [0, 0, 0],
      fontSize: 8,
      padding: 0,
  },
    bodyStyles:{
      fillColor: [255, 255, 255],
    },
  columnStyles: {
    0: {textColor: [0, 0, 0],fontStyle: 'bold',fillColor: [255, 255, 255]},
    5: {textColor: [0, 0, 0],fontStyle: 'bold',fillColor: [255, 255, 255]},
  },
  alternateRowStyles: {
    fillColor: [255, 255, 255],
  },
  })
}

if(data.codeproduit ==='YKE_2018'){
  finalY = doc.previousAutoTable.finalY
  doc.autoTable({
    startY: finalY + 3,
    head: headRows1(),
    body: [
      [{ content: 'EVOLUTION DU CONTRAT' },],
    ],
    showHead: false,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', lineWidth: 0.1, lineColor: 0, fontSize: 9 },
    columnStyles: {
      libelle: { textColor: 0, fontStyle: 'bold', fillColor: [230, 230, 230], },
    },
    columns: [
      { header: 'Libelle', dataKey: 'libelle' },
    ],
  })
  finalY = doc.previousAutoTable.finalY
  //TO DO SIMULATION
  const evol = await evolutionContrat(primeIvestie,data.codeproduit,10,data,'horizontal')
  const headEvo = evol.annee
  const cumul = evol.cumul
  const valeur = evol.pm
  headEvo.unshift('Année')
  cumul.unshift('Cumul Prime')
  valeur.unshift("Valeur")
  doc.autoTable({
    startY: finalY + 3,
    head: [headEvo],
    body: [
      cumul,
      valeur
    ],  
    styles: { overflow: 'ellipsize', cellWidth: 'auto', lineWidth: 0.1, lineColor: 0, fontSize: 8,fillColor: [255, 255, 255] },
    
    headerStyles: {
      fillColor: [255, 255, 255],
      textColor: [0, 0, 0],
      fontSize: 8,
      padding: 0,
  },
    bodyStyles:{
      fillColor: [255, 255, 255],
    },
  columnStyles: {
    0: {textColor: [0, 0, 0],fontStyle: 'bold',fillColor: [255, 255, 255]},
    5: {textColor: [0, 0, 0],fontStyle: 'bold',fillColor: [255, 255, 255]},
  },
  alternateRowStyles: {
    fillColor: [255, 255, 255],
  },
  })
}
if(!['YKE_2018','PVRPRE'].includes(data.codeproduit)){
  
  finalY = doc.previousAutoTable.finalY
  doc.autoTable({
    startY: finalY + 3,
    head: headRows1(),
    body: [
      [{ content: 'PAIEMENT DES PRIMES' },],
    ],
    showHead: false,
    styles: { overflow: 'ellipsize', cellWidth: 'auto', lineWidth: 0.1, lineColor: 0, fontSize: 9 },
    columnStyles: {
      libelle: { textColor: 0, fontStyle: 'bold', fillColor: [230, 230, 230], },
    },
    columns: [
      { header: 'Libelle', dataKey: 'libelle' },
    ],
  })
  finalY = doc.previousAutoTable.finalY
  doc.autoTable({
    startY: finalY + 3,
    head: [{ libelle: 'Libelle', valeur: 'Valeur', libelle: 'Libelle', valeur: 'Valeur' }],
    body: [
      ['Mode de paiement: ', data.modepaiement, 'Organisme :', data.organisme,'Agence:', data.agence, 'N° Compte:', data.numerocompte],
    ],
    styles: { overflow: 'ellipsize', cellWidth: 'auto', fontSize: 9, valign: 'center', halign: 'left' },
    showHead: false,
    columnStyles: {
      libelle: { textColor: 0, fontStyle: 'bold', fillColor: [255, 255, 255], },
      valeur: { textColor: 0, fontStyle: 'normal', halign: 'left', fillColor: [255, 255, 255], cellPadding: 0.5 },
    },
    columns: [
      { header: 'Libelle', dataKey: 'libelle' },
      { header: 'Valeur', dataKey: 'valeur' },

      { header: 'Libelle', dataKey: 'libelle' },
      { header: 'Valeur', dataKey: 'valeur' },

      { header: 'Libelle', dataKey: 'libelle' },
      { header: 'Valeur', dataKey: 'valeur' },

      { header: 'Libelle', dataKey: 'libelle' },
      { header: 'Valeur', dataKey: 'valeur' },
    ],
  })
}
  


  finalY = doc.previousAutoTable.finalY
  doc.setFontSize(9)
  //doc.addFont('ArialMS', 'Arial', 'bold ');
  //doc.setFontType('bold')
  doc.text(15, finalY + 10, 'Fait à .................................................... le ' + new Date().toLocaleDateString())

  doc.text(20, finalY + 13, ` YAKO AFRICA                                               ADHERENT                                                                 ${(branche === 'BANKASS') ? partenaire.toUpperCase() : 'L\'ASSURE(E)'}`)
  doc.addImage(signature, "PNG", 10, finalY + 14, 40, 20)
  doc.addPage();
 
  let cgu = new Image()
  if (data.codeproduit === "YKV_2004") {
    cgu.src = require('assets/images/cg_ykv.png')
    doc.addImage(cgu, "PNG", 0, 0, 220, 310)
  }

  if (data.codeproduit === "YKL_2004") {
    cgu.src = require('assets/images/cg_ykl.png')
    doc.addImage(cgu, "PNG", 0, 0, 220, 310)
  }

  if (data.codeproduit === "YKF_2004") {
    cgu.src = require('assets/images/cg_ykf2004.png')
    doc.addImage(cgu, "PNG", 0, 0, 220, 310)
  }

  if (data.codeproduit === "PFA_BNI") {
    cgu.src = require('assets/images/cgu_pfa_bni.png')
    doc.addImage(cgu, "PNG", 10, 10)
  }

  if (data.codeproduit === "PVRBNI") {
    cgu.src = require('assets/images/cg_pvr.png')
    doc.addImage(cgu, "PNG", 0, 0, 220, 330)
  }


  if(data.codeproduit === 'PVRPRE'){
    doc.setFontSize(7)
    let pageHeight = doc.internal.pageSize.getHeight();
    let pageWidth = doc.internal.pageSize.getWidth();
     var htmlElement =  `<div id="biapremium" style="margin:0px;padding:0px;width:${pageWidth}px;">
     <div style='text-align:center;margin:0px;padding:0px;'>
      <span style="margin:0px;padding:0px;text-decoration:underline;text-decoration-thickness:1px;text-align:center;color:black;font-weight:bold;font-size:5px;">EXTRAIT DES CONDITIONS GENERALES PLAN VERT RETRAITE PREMIUM</span>
     </div><div style="margin:0; padding:0;display:flex;flex-direction:row;width:100%">
         <div style="margin:0px;padding:0px;width:50%;margin-right:5px">
           <span style="margin:0px;padding:0px;text-align:center;color:black;font-weight:bold;font-size:4px;">OBJET DE L’ASSURANCE</span>
           <p style="margin:0px;padding:0px;font-family:Arial;color:black;font-weight:normal;font-size:3px; text-align:justify;"><strong>Plan Vert Retraite PREMIUM BNI</strong> est une convention
              d’assurance groupe souscrite par la BNI en faveur des
              personnes physiques titulaires de comptes chèques
              ouverts dans ses livres et des personnes morales titulaires
              de compte chèques ouverts dans ses livres, pour le
              compte de leurs salariés.
              Il a pour objet la constitution d’un capital à terme en vue
              de :<br /> <span style="margin-left:10px"> - Préparer sa retraite pendant la vie professionnelle;</span><br />
              <span style="margin-left:10px"> -Disposer d’un complément de revenus une fois à
              la retraite, versé sous forme de capital ou de
              rente;</span><br /><span style="margin-left:10px"> -Protéger financièrement sa famille en cas de décès<br />
              de l’assuré avant le terme du contrat</span>.
              </p><span style="text-align:center;color:black;font-weight:bold;font-size:4px;">QUALITE DE L’ASSURE</span>
              <p style="margin:0; padding:0;font-family:Arial;color:black;font-weight:normal;font-size:3px; text-align:justify;">
              Toute personne physique, titulaire d’un compte chèque à
              la BNI ou dont l’entreprise dispose d’un compte à la BNI
              dans le cadre d’un contrat collectif, âgée de <strong>DIX-HUIT
              (18) à CINQUANTE CINQ (55) ANS</strong> au moment de la
              souscription.</p>
              <span style="text-align:center;color:black;font-weight:bold;font-size:4px;">NATURE DES GARANTIES</span>
              <p style="margin:0;padding:0;font-family:Arial;color:black;font-weight:normal;font-size:3px; text-align:justify;">
              <strong>Plan Vert Retraite PREMIUM BNI</strong> offre deux (2)
              garanties dénommées : <strong>EPARGNE PLUS</strong> et
              <strong>PROTECTYS</strong>.<br />
              <span style="margin-left:10px;">
              <strong>&#8226; Garantie « EPARGNE PLUS</strong></span><br />
              Elle permet la constitution progressive d’un capital payé
              aux bénéficiaires en cas de survie de l’Assuré au terme du
              contrat.
              En cas de décès ou d’invalidité totale et définitive de
              l’Assuré avant le terme, il est payé la valeur du contrat à
              la date du décès ou de l’invalidité absolue et définitive,
              au(x) bénéficiaire(s).<br />
              <span style="margin-left:10px;"><strong>&#8226; Garantie PROTECTYS</strong></span><br />
              En cas de décès ou d’invalidité absolue et définitive six
              (6) mois fermes après la date de prise d’effet du contrat,
              sauf s’il est de nature accidentelle, elle engage <strong></strong>YAKO
              AFRICA</strong> à payer une prestation égale au capital décès
              souscrit.
              </p><span style="text-align:center;color:black;font-weight:bold;font-size:4px;">PRISE D’EFFET ET DUREE</span>
              <p style="margin:0; padding:0;font-family:Arial;color:black;font-weight:normal;font-size:3px; text-align:justify;">
              Le contrat prend effet le premier jour du mois suivant la
              date de réception au siège de <strong>YAKO AFRICA </strong>du
              paiement de la première prime.
              La durée minimale est de 5 ans.</p>
              <span style="text-align:center;color:black;font-weight:bold;font-size:4px;">RACHAT PARTIEL / RACHAT TOTAL</span>
              <p style="margin:0; padding:0;font-family:Arial;color:black;font-weight:normal;font-size:3px; text-align:justify;">
              <strong>Plan Vert Retraite PREMIUM BNI</strong> peut être racheté totalement ou
              partiellement lorsqu’au moins 15% des primes prévues au contrat ou
              deux primes annuelles ont été effectivement versées.
              La valeur de rachat est égale à l’épargne constituée minorée d’une
              pénalité de 5% si le rachat intervient dans les DIX (10) premières
              années du contrat.
              L’adhérent peut faire un seul rachat partiel par année.
              Le rachat total de Plan Vert Retraite PREMIUM BNI entraine la
              cessation de toutes les garanties et la résiliation du contrat.
            </p>
         </div>
         <div style="width:50%;margin:0px;padding:0px">
            <span style="text-align:center;color:black;font-weight:bold;font-size:4px;">PRÊT PERSONNEL ZO’</span>
            <p style="margin:0; padding:0;font-family:Arial;color:black;font-weight:normal;font-size:3px; text-align:justify;">
            A la demande de l’adhérent, si celui-ci remplit toutes les conditions,
            la <strong>BNI</strong> s’engage à lui accorder un prêt dit <strong>« Prêt personnel ZO’ »</strong>,
            garanti par le nantissement d’une partie de l’épargne constituée.
            Ces conditions sont les suivantes :<br />
            <span style="margin-left:10px">- L’adhérent doit être à jour de ses cotisations ;</span><br />
            <span style="margin-left:10px">- L’adhérent doit avoir effectivement cotisé un
            montant équivalent à au moins <strong>UN (1) AN de
            prime</strong> ;</span><br />
            <span style="margin-left:10px">- L’adhérent ne doit avoir aucun encours rattaché
            au contrat concerné.</span><br />
            Le montant du prêt octroyé par la <strong>BNI</strong> est limité à <strong>75,00%</strong> de la
            valeur du contrat.
            L’intervalle de temps entre les demandes de prêt pour un contrat
            donné est de <strong>12 mois</strong>.
            </p>
            <span style="text-align:center;color:black;font-weight:bold;font-size:4px;">BONUS PERSISTANCE</span>
            <p style="margin:0; padding:0;font-family:Arial;color:black;font-weight:normal;font-size:3px; text-align:justify;">Il permet de bonifier l’épargne de l’adhérent d’un montant équivalent
à 4% du capital décès <strong>PROTECTYS souscrit.
Pour en bénéficier, l’adhérent, ne devra effectuer aucune opération
(hormis le prêt personnel ZO’) sur son contrat jusqu’au dixième
anniversaire de celui-ci.</p>
<span style="text-align:center;color:black;font-weight:bold;font-size:4px;">PIECES A FOURNIR</span>
<p style="margin:0; padding:0;font-family:Arial;color:black;font-weight:normal;font-size:3px; text-align:justify;">
<strong>Au terme du contrat</strong><br />
<span style="margin-left:10px">
1-Un formulaire de demande de prestation disponible sur <strong>«
Ynov (fonctionnalité e. Prestations »</strong> dûment renseigné ;</span>
<span style="margin-left:10px">2-L’original de la police d’assurance et de ses avenants, à défaut
une déclaration de perte délivrée par un commissariat de
police ;</span>
<span style="margin-left:10px">3-Une copie de la carte nationale d’identité, de la carte
consulaire ou de la carte de séjour de l’Assuré, à défaut la
copie de l’acte de naissance ou du jugement supplétif de
l’assuré ;</span><br/>
<strong>En cas de décès toutes causes</strong><br />
<span style="margin-left:10px">1-Un formulaire de déclaration de sinistre disponible sur <strong>«
Ynov (fonctionnalité e. Prestations »</strong> dûment renseigné ;
  <span style="margin-left:10px">2-L’original de la police d’assurance et de ses avenants, à défaut
une déclaration de perte délivrée par un commissariat de
police ;</span>
<span style="margin-left:10px">3-Une copie de la carte nationale d’identité, de la carte
consulaire ou de la carte de séjour de l’Assuré, à défaut la
copie de l’acte de naissance ou du jugement supplétif de
l’assuré ;</span>
<span style="margin-left:10px">4-Les pièces d’identification du bénéficiaire consistant en l’un
des documents suivants :
(Copie de la carte nationale d’identité ; Copie de la carte
consulaire ; Acte de naissance, jugement supplétif.) ;</span>
<span style="margin-left:10px">5-Un acte de décès de l’Adhérent ;</span>
<span style="margin-left:10px">6-Un certificat de décès de l’Adhérent.</span>
<br />
<strong>En cas d’invalidité absolue et définitive</strong><br />
<span style="margin-left:10px">1-Un formulaire de demande de prestation disponible sur «
Y.nov (fonctionnalité e. Prestations » dûment renseigné ;</span>
  <span style="margin-left:10px">2-L’original de la police d’assurance et de ses avenants, à défaut
une déclaration de perte délivrée par un commissariat de
police ;</span>
<span style="margin-left:10px">3-Une copie de la carte nationale d’identité, de la carte
consulaire ou de la carte de séjour de l’Assuré, à défaut la
copie de l’acte de naissance ou du jugement supplétif de
l’assuré ;</span>
<span style="margin-left:10px">4-Un certificat médical attestant l’état d’invalidité ;</span>
<span style="margin-left:10px">5-Un rapport médical complet du médecin traitant.</span>
</p>
         </div>
       
     </div>
   </div>`;

   
      const opt = {
        callback: function (bia) {
          //bia.addPage('a4','p')
          bia.setFontSize(7)
          return bia;
        },
        margin: [5, 5, 5, 5],
        html2canvas: {
          allowTaint: true,
          dpi: 300,
          letterRendering: true,
          logging: false,
          scale: .9
      },
      width:pageWidth,
      y: (pageHeight-20),
      autoPaging: 'text'
    };
    await doc.html(htmlElement, opt);
  }
  if(data.codeproduit === 'YKE_2018'){
    doc.setFontSize(6)
    const tableLigne = await evolutionContrat(primeIvestie,data.codeproduit,10,data,'vertical')
     var htmlElement =  `
     <div id="biapremium" style="margin:0px;padding:0px;width:${pageWidth}px;">
        <div style='display:flex;flex-direction:row;justify-content:center;align-items:center; text-align:center;margin:0px;padding:0px; border:1px solid black;border-radius:5px;'>
          <span style="margin:0px;padding:0px;text-align:center;color:black;font-weight:bold;font-size:5px;">RESUME DES CONDITIONS GENERALES YAKO ETERNITE</span>
        </div>
     <div style="margin:0; padding:0;display:flex;flex-direction:row;width:100%">
         <div style="margin:0px;padding:0px;width:50%;margin-right:5px">
           <span style="margin:0px;padding:0px;text-align:center;color:black;font-weight:bold;font-size:4px;text-decoration:underline;">OBJET DU CONTRAT - NATURE DES GARANTIES</span><br />
           <p style="margin-top:0; margin-bottom:0;padding:0px;font-family:Arial;color:black;font-weight:normal;font-size:3px; text-align:justify;"><br />
              Le Contrat d’Assurance YAKO ETERNITE se compose de deux garanties de base <strong>HOMMAGE</strong> et <strong>SURETE</strong> et d’une garantie accessoire <strong>SENIOR</strong> 
              <br /><span style="text-decoration:underline;"><strong>Garantie HOMMAGE</strong></span><br />
La garantie <strong>HOMMAGE</strong> donne droit à une prestation de montant égal à la provision mathématique.
<br /><br />
            <span style="text-decoration:underline;"><strong>Garantie SURETE</strong></span><br /><br />
En cas de décès de l’assuré avant le 1er  anniversaire du contrat (à partir de la date d’effet) sauf s’il est de nature accidentelle, il est servi une prestation en numéraire égale au cumul des primes payées diminué du coût de police au titre de la garantie HOMMAGE en remplacement de la provision mathématique.
           <br />Si le décès de l’assuré est de nature accidentelle ou intervient après le 1er anniversaire du contrat (12 mois fermes après la date de prise d’effet, la garantie SURETE  donne droit à une prestation supplémentaire à la provision mathématique de la garantie HOMMAGE.
          <br />
Elle est égale au cumul des primes au titre de la garantie HOMMAGE restant à échoir entre la date de décès et le 5ème anniversaire de la souscription.
           
<br /><br /><span style="text-decoration:underline;"><strong>Garantie SENIOR</strong></span><br />
La garantie accessoire SENIOR, au choix du souscripteur permet de couvrir un second assuré. 
Elle engage l’assureur, en cas de décès au paiement d’une prestation égale à la provision mathématique en couverture de la garantie.
<br /><br />
<span style="text-align:center;color:black;font-weight:bold;font-size:4px;text-decoration:underline">EXCLUSIONS A LA GARANTIE SURETE</span><br />
            
Si le décès de l’assuré intervient dans les cinq(5) premières années à la suite de l’un des évènements ci-dessous identifiés, l’engagement de l’assureur se limitera au paiement de la provision mathématique.<br />
<span style="margin-left:10px">-suicide conscient et volontaire de l’assuré avant deux années effectives d’assurances ;</span><br />
<span style="margin-left:10px">-participation à une émeute ou une rixe ;</span><br />
<span style="margin-left:10px">-acte intentionnel du (des) bénéficiaires ;</span><br />
<span style="margin-left:10px">-conséquences d’épidémies et autres catastrophes reconnues comme telles par les autorités ;</span><br />
<span style="margin-left:10px">-en cas de guerre civile ou étrangère, les conditions de couverture seront déterminées par la législation en vigueur ;</span><br />
<span style="margin-left:10px">- conséquences de la pratique des sports et jeux dangereux ainsi que les sauts en parachute non justifiés par une situation critique de l’appareil.</span><br />           
</p>
</div>
<div style="width:50%;margin:0px;padding:0px">
<span style="text-align:center;color:black;font-weight:bold;font-size:4px;text-decoration:underline">PROVISION MATHEMATIQUE</span><br />
<p style="margin-top:0;margin-bottom:0;padding:0;font-family:Arial;color:black;font-weight:normal;font-size:3px; text-align:justify;">
La provision mathématique en couverture des garanties <strong>HOMMAGE</strong> et <strong>SENIOR</strong> est égale au cumul des primes nettes versées capitalisées au taux d’intérêt garanti de 3,5% l’an et, augmenté des participations aux bénéfices  financiers et techniques.
<br />Au titre de la garantie HOMMAGE :
<span style="margin-left:10px">-	La prime nette sur la 1ère année s’élève à <strong>53,00%</strong> de la prime brute hors accessoires et taxes</span>
<span style="margin-left:10px">-	A partir de la 2ème année, la prime nette est égale à <strong>92,00%</strong> de la prime brute hors accessoires et taxes</span><br />
La prime nette de la garantie SENIOR  est égale à <strong>94,50%</strong> de la prime brute hors frais accessoires et taxes.<br /><br />
<span style="text-align:center;color:black;font-weight:bold;font-size:4px;text-decoration:underline;">QUALITE DE L’ASSURE</span><br />

Les garanties HOMMAGE et SURETE sont souscrites sur la tête d’une personne âgée d’au moins <strong>12 ans</strong> et d’au plus <strong>74 ans</strong> révolus à la date d’effet du contrat. L’Assuré au titre de la garantie accessoire SENIOR doit être âgé d’au moins <strong>75 ans</strong> révolus à la date d’effet du contrat.
<br /><br /><span style="text-align:center;color:black;font-weight:bold;font-size:4px;text-decoration:underline;">PRISE D’EFFET ET DUREE</span><br />
Le contrat prend effet le premier jour du mois suivant la date de paiement de la première prime. Il est établi pour une durée viagère
<br /><br /><span style="text-align:center;color:black;font-weight:bold;font-size:4px;text-decoration:underline;">RENONCIATION</span><br />
Comme l’indique l’article 65 du code CIMA, le souscripteur peut renoncer au contrat dans le délai de 30 jours, à compter de la date de paiement de la 1ère prime .La renonciation entraine la restitution des primes payées, déduction faite du coût de police.
<br /><br /><span style="text-align:center;color:black;font-weight:bold;font-size:4px;text-decoration:underline;">RACHAT TOTAL</span><br />

Le souscripteur peut mettre fin au contrat YAKO ETERNITE à tout moment.

<br />
Si la résiliation intervient avant le paiement effectif d’une prime annuelle ou de 15,00% de l’ensemble des primes prévues au contrat, la valeur de rachat est égale à la provision mathématique de la garantie SENIOR.
<br />

En cas de paiement effectif de 15,00% de l’ensemble des primes prévues au contrat ou une (1) prime annuelle ,la valeur brute de rachat est égale à la somme des montants ci-après :<br />
<span style="margin-left:10px">-la provision mathématique de la garantie SENIOR</span><br/>
<span style="margin-left:10px">-la provision mathématique de la garantie HOMMAGE diminuée d’une pénalité  de 5,00% .La pénalité est nulle si la demande de résiliation intervient après la  5ème année d’assurance.</span><br/>
</p>
</div>
     </div>
   </div>`;

   
      const opt = {
        callback: function (bia) {
          //bia.addPage('a4','p')
          bia.setFontSize(7)
          return bia;
        },
        margin: [5, 5, 5, 5],
        html2canvas: {
          allowTaint: true,
          dpi: 300,
          letterRendering: true,
          logging: false,
          scale: .9
      },
      width:pageWidth,
      y: (pageHeight-10),
      x:5,
      autoPaging: 'text'
    };
    await doc.html(htmlElement, opt);
  }
  doc.output('dataurlnewwindow');
}  


async function  evolutionContrat (prime,produit,nombreannee,data,orientation='vertical'){
  if(prime && produit && nombreannee){
    const lignes =orientation==='vertical' ? []:{'annee':[],'cumul':[],'pm':[]}
    const taux_technique = 0.035
    let cumulPrime =0
    let valeurRachat = 0
    let pm = 0
    let TOTEnc
    let v_nbencrec =0
    //détermination de la périodicite
    switch (data['periodicite']){
      case "M":
        TOTEnc = 12
        break;
      case "T":
        TOTEnc = 4
        break;
      case "S":
        TOTEnc = 2
        break;
      case "A":
        TOTEnc = 1
        break;
      default:
        TOTEnc = 12
        break;
      
    }

    switch (produit) {
      case "YKE_2018":
        const taux_gestion_epargne =0.01
        let v_cotis_investie
        cumulPrime = 0
     
        for(let i=1; i<=nombreannee; i++){

            for(let j=1; j<=TOTEnc; j++){
              cumulPrime += prime
              v_nbencrec++
              v_cotis_investie = await primeinvestieYke(prime,0,TOTEnc,v_nbencrec)
              valeurRachat = (valeurRachat + v_cotis_investie) * (((1+taux_technique) * (1 - taux_gestion_epargne))**(1/TOTEnc))
            }
        
            if(i==5){
              if(valeurRachat>200000 && valeurRachat<=300000){
                pm = 300000
              }else if(valeurRachat>300000 && valeurRachat<=500000){
                pm = 500000
              }else if(valeurRachat>500000 && valeurRachat<=750000){
                pm = 750000
              }else if(valeurRachat>750000 && valeurRachat<=1000000){
                pm = 1000000
              }else if(valeurRachat>1000000 && valeurRachat<=1250000){
                pm = 1250000
              }else if(valeurRachat>1250000 && valeurRachat<=2000000){
                pm = 2000000
              }else if(valeurRachat>2000000 && valeurRachat<=3000000){
                pm = 3000000
              }else if(valeurRachat>3000000 && valeurRachat<=4000000){
                pm = 4000000
              }else if(valeurRachat>4000000 && valeurRachat<=5000000){
                pm = 5000000
              }else{
                valeurRachat=  Math.ceil(valeurRachat/100)*100
              }
            }else{
              pm = valeurRachat
            }
              if(orientation==='vertical'){
                lignes.push(`<tr>
                <td style="font-size:3px;text-align:center;color:black;">${i}</td>
                <td style="font-size:3px;text-align:center;color:black;">${formatNumber(cumulPrime || "")}</td>
                <td style="font-size:3px;text-align:center;color:black;">${formatNumber(parseInt(pm) || "")}</td>
                </tr>`)
              }else{
                lignes['annee'].push(`Année ${i}`)
                lignes['cumul'].push(`${formatNumber(cumulPrime || "")}`)
                lignes['pm'].push(`${formatNumber(parseInt(pm) || "")}`)
              }
             
              
        }
        return lignes
      case 'PVRPRE':
        const tx_g_epargne_plus = 0.04
        const tx_a_epargne_plus = 0.05
        cumulPrime = 0
        const v = 1/(1+taux_technique)
        for(let i=1; i<=nombreannee; i++){
          for(let j=1; j<=TOTEnc; j++){
            cumulPrime += prime
          }
          valeurRachat = ((Math.pow(1+taux_technique,i)-1)/(1-Math.pow(v,1/TOTEnc)))*(prime*(1-tx_a_epargne_plus-tx_g_epargne_plus))
          pm = valeurRachat
          if(orientation==='vertical'){
            lignes.push(`<tr>
            <td style="font-size:3px;text-align:center;color:black;">${i}</td>
            <td style="font-size:3px;text-align:center;color:black;">${formatNumber(cumulPrime || "")}</td>
            <td style="font-size:3px;text-align:center;color:black;">${formatNumber(parseInt(pm) || "")}</td>
            </tr>`)
          }else{
            lignes['annee'].push(`Année ${i}`)
            lignes['cumul'].push(`${formatNumber(cumulPrime || "")}`)
            lignes['pm'].push(`${formatNumber(parseInt(pm) || "")}`)
          }
        }
         return lignes
        
      default:
        break;
    }
  }
}

//determination de la prime investie YAKO
const primeinvestieYke=async (p_cotisation,p_prime_com_surete,p_fract,p_nbencais)=>{
  const fa = 0.40
  const fg = 0.07
  let w_coeff
  let w_limite
  if(p_nbencais <= p_fract){
    w_coeff = 1-fg-fa
  }else{
    w_coeff = 1-fg
  }
  w_limite = 5*p_fract

  if(p_nbencais <= w_limite){
	  return  (w_coeff*(p_cotisation - p_prime_com_surete))
  }else{ 
	  return  w_coeff*p_cotisation 
  }
}